import store from '../../config/configureStore';
import {
  upsertIncident,
  upsertAddress,
  upsertPartyPlace,
  upsertParty,
  upsertProperties,
  addIncArrestees,
  addIncProperties,
  addIncSubjects,
  addIncVictims,
  addIncDVVictim,
  addVictimRelationships,
  addIncDVAdditional,
  addIncSuspects,
  updateIncPartiesPersonForm,
  updateIncPartiesPlaceForm,
  getEntitiesByIncidentId,
  SET_INCIDENT,
  GET_PARTIES,
  GET_ADDRESSESS,
  SET_NIBRS_ERRORS,
  setCurrentOffense,
  updateIncOffensesOffenseForm,
  updateIncOffenses,
  setIncSubjectsDetails,
  setIncVictimsDetails,
  GET_OFFENSES,
  setIsFormDirty,
  SET_INC_RELATIONSHIPS,
  SET_INC_ARRESTEES,
  updateIncEvidenceItemForm,
  GET_EVIDENCES,
  GET_NARRATIVES,
  GET_TAGS,
  GET_DISPOSITIONS,
  GET_ZONES,
  updateIncNarrativeForm,
  GET_PROPERTIES,
  updateIncEvidenceDispositionForm,
  upsertEvidenceParty,
  setSelectedIncidentEvidence,
  initFormModel,
  updateIncAfterActionPartyForm,
  setSelectedActionReport,
  updateIncAfterActionReviewForm,
  GET_AFTER_ACTION_REPORT,
  updateIncAfterActionDetailsForm,
} from '../IncReducer';

import {
  addNewOffense,
  updateOffense,
  addNewSubject,
  updateSubject,
  addNewVictim,
  updateVictim,
  addNewArrestee,
  updateArrestee,
  doesRelationshipExist,
  updateRelationship,
  addNewRelationship,
  updateDVVictim,
  addNewDVVictim,
  updateDVAdditional,
  addNewDVAdditional,
  updateSuspect,
  addNewSuspect,
  addNewProperty,
  updateProperty,
  addNewNarratives,
  updateEvidenceItem,
  addNewEvidenceCustody,
  addNewEvidenceItem,
  updateEvidenceCustody,
  addNewEvidenceStorage,
  updateEvidenceStorage,
  updateEvidenceDisposition,
  addNewEvidenceDisposition,
  updateNarratives,
  addZone,
  addDisposition,
  addTags,
  prepareOffenseErrors,
  preparePartiesErrors,
  prepareArresteesErrors,
  prepareIncPropertiesErrors,
  prepareIncRelationshipErrors,
  prepareIncVictimErrors,
  prepareIncIncidentErrors,
  preparePropertiesErrors,
  addValidationErrors,
  prepareValidationErrors,
  updateJuvenileStatusIncident,
  updateSameParties,
  prepareSingleAfterActionReport,
  updateAfterActionDetails,
  createAfterActionDetails,
  createAfterActionReview,
  updateAfterActionReview,
  updateAfterActionParty,
  createAfterActionParty,
  updateEvidenceCurrentLocation,
  prepareActionReportPartyDetailsData,
  prepareActionReportDetailsData,
  prepareActionReportReviewDetailsData,
} from '../helpers/incHelpers';
import { getEntitiesByIncidentIdV2 } from '../IncReducer';
import { updateSelectedEvidence } from 'reducers/EvidenceReducer';

import {
  validateOffenses,
  validateParties,
  validateArrestees,
  validateIncProperties,
  validateIncRelationships,
  validateIncVictims,
  validateProperties,
  validateIncIncident,
} from 'simpl-schema-validation/validate';

import { upsertEvidence } from 'reducers/RecordsReducer';
import { changeIBRSSubmittedState } from 'reducers/IBRSReducer';
import { notify } from 'reducers/NotifierReducer';
import { getAttachmentsRMS } from 'reducers/helpers/fileHelpers';
import { setListBodyCells } from './UiListReducer';

import { getCodeofCategory, getJuvenileReportValues } from 'utils/functions';

import { prepareEvidenceItemRecordDetailData } from 'reducers/helpers/uiMenuHelpers';
import AfterActionParties from 'pages/PageRMSAfterActionReport/AfterActionParties';
import AfterActionLocations from 'pages/PageRMSAfterActionReport/AfterActionLocations';

import { displayDateTime } from 'reducers/TimeReducer';

const SET_MENU_TYPE = 'SET_MENU_TYPE';
const SET_HANDLE_CLOSE = 'SET_HANDLE_CLOSE';
const SET_HANDLE_INC_FORM_SAVE = 'SET_HANDLE_INC_FORM_SAVE';
const SET_OFFENSES_REDIRECT_FORM_CLOSE = 'SET_OFFENSES_REDIRECT_FORM_CLOSE';
const SET_HANDLE_ADD_INC_SUBJECT = 'SET_HANDLE_ADD_INC_SUBJECT';
const SET_HANDLE_ADD_INC_VICTIM = 'SET_HANDLE_ADD_INC_VICTIM';
const SET_HANDLE_ADD_INC_PROPERTY = 'SET_HANDLE_ADD_INC_PROPERTY';
const SET_HANDLE_ADD_INC_RELATIONSHIP = 'SET_HANDLE_ADD_INC_RELATIONSHIP';
const SET_HANDLE_ADD_INC_ARRESTEE = 'SET_HANDLE_ADD_INC_ARRESTEE';
const SET_HANDLE_ADD_INC_DV_VICTIM = 'SET_HANDLE_ADD_INC_DV_VICTIM';
const SET_HANDLE_ADD_INC_DV_SUSPECT = 'SET_HANDLE_ADD_INC_DV_SUSPECT';
const SET_HANDLE_ADD_INC_DV_ADDITIONAL = 'SET_HANDLE_ADD_INC_DV_ADDITIONAL';
const SET_CIRCULAR_LOADING = 'SET_CIRCULAR_LOADING';
const SET_SHOW_INCIDENT_STEPPER = 'SET_SHOW_INCIDENT_STEPPER';
const SET_SAVING_DISABLED = 'SET_SAVING_DISABLED';

/*** Start: Form Save Region */
async function saveSubjects(incidentID, offenseID, dispatch) {
  const { incSubjects, parties, offenses } = store.store.getState().incident;

  for (let i = 0; i < incSubjects.length; i++) {
    if (
      incSubjects[i].incSubjectDetails.changes === true &&
      incSubjects[i].incSubjectDetails.ptsIncPersonId
    ) {
      incSubjects[i].incSubjectDetails.ptsOffenseId = offenseID;
      if (incSubjects[i].incSubjectDetails.ptsSubjectId) {
        updateSubject(incSubjects[i].incSubjectDetails, dispatch);
      } else {
        let savedParty = parties.find(
          (x) => x.ptsIncPersonId === incSubjects[i].incSubjectDetails.ptsIncPersonId
        );
        savedParty &&
          (await addNewSubject(incSubjects[i].incSubjectDetails, savedParty, incidentID, dispatch)
            // eslint-disable-next-line no-loop-func
            .then((res) => {
              incSubjects[i].incSubjectDetails.ptsSubjectId = res.ptsSubjectID;
            })
            .catch((err) => {
              console.log(err);
            }));
      }
    }
    incSubjects[i].incSubjectDetails.changes = false;
  }
  const index = offenses.findIndex((offense) => offense.offenseDetails.ptsOffenseId === offenseID);
  const offensesIncSubjects = incSubjects.filter(
    (el) => el.incSubjectDetails.ptsOffenseId === offenseID
  );
  offenses[index].offenseDetails.values.subjectIds = offensesIncSubjects.map(
    (el) => el.incSubjectDetails.ptsSubjectId
  );
  dispatch({ type: GET_OFFENSES, payload: [...offenses] });
  dispatch(setIncSubjectsDetails([...incSubjects]));
}

async function saveVictims(incidentID, offenseID, dispatch) {
  const { incVictims, parties, addresses, offenses } = store.store.getState().incident;

  for (let i = 0; i < incVictims.length; i++) {
    if (
      incVictims[i].incVictimDetails.changes === true &&
      (incVictims[i].incVictimDetails.ptsIncPersonId ||
        incVictims[i].incVictimDetails.ptsIncPlaceId)
    ) {
      incVictims[i].incVictimDetails.ptsOffenseId = offenseID;
      if (incVictims[i].incVictimDetails.ptsVictimId) {
        let sameVictimsList = [];
        /* Finding out the same person victims */
        sameVictimsList = incVictims.filter(
          (v) =>
            (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) ===
            (incVictims[i].incVictimDetails.ptsIncPersonId ||
              incVictims[i].incVictimDetails.ptsIncPlaceId)
        );

        updateVictim(incVictims[i].incVictimDetails, sameVictimsList, dispatch);

        /* Updating the values for the same victim person */
        incVictims.map((v, x) => {
          if (
            (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) ===
              (incVictims[i].incVictimDetails.ptsIncPersonId ||
                incVictims[i].incVictimDetails.ptsIncPlaceId) &&
            v.incVictimDetails.ptsOffenseId !== incVictims[i].incVictimDetails.ptsOffenseId
          )
            incVictims[x].incVictimDetails.values = incVictims[i].incVictimDetails.values;
        });
      } else {
        let savedParty = parties.find(
          (x) => x.ptsIncPersonId === incVictims[i].incVictimDetails.ptsIncPersonId
        );
        let savedPlace = addresses.find(
          (x) =>
            x?.addressDetails?.values?.ptsIncPlaceId ===
            incVictims[i].incVictimDetails.ptsIncPlaceId
        );
        (savedParty || savedPlace) &&
          (await addNewVictim(
            incVictims[i].incVictimDetails,
            savedParty || savedPlace,
            incidentID,
            dispatch
          )
            // eslint-disable-next-line no-loop-func
            .then((res) => {
              incVictims[i].incVictimDetails.ptsVictimId = res.ptsVictimID;
            })
            .catch((err) => {
              console.log(err);
            }));
      }
    }
    incVictims[i].incVictimDetails.changes = false;
  }
  const index = offenses.findIndex((offense) => offense.offenseDetails.ptsOffenseId === offenseID);
  const offensesIncVictims = incVictims.filter(
    (el) => el.incVictimDetails.ptsOffenseId === offenseID
  );
  offenses[index].offenseDetails.values.victimIds = offensesIncVictims.map(
    (el) => el.incVictimDetails.ptsVictimId
  );
  dispatch({ type: GET_OFFENSES, payload: [...offenses] });
  dispatch(setIncVictimsDetails([...incVictims]));
}

async function saveDVVictim(currentState, dispatch) {
  if (
    currentState.offensesDVVictimForm.changes === true &&
    currentState.selectedVictim !== undefined &&
    currentState.selectedVictim !== ''
  ) {
    addIncDVVictim();
    if (Object.keys(currentState.offensesDVVictimForm.values).length !== 0) {
      if (currentState.offensesDVVictimForm.ptsDVVictimId) {
        await updateDVVictim(currentState.offensesDVVictimForm);
      } else {
        await addNewDVVictim(currentState.offensesDVVictimForm, dispatch);
      }
    }
    currentState.offensesDVVictimForm.changes = false;
  }
}

async function saveDVSuspects(currentState, incidentID, dispatch) {
  for (let i = 0; i < currentState.dvSuspects.length; i++) {
    if (
      currentState.dvSuspects[i].incSuspectDetails.changes === true &&
      currentState.dvSuspects[i].incSuspectDetails.ptsIncPersonId
    ) {
      if (currentState.dvSuspects[i].incSuspectDetails.ptsDVSuspectId) {
        await updateSuspect(currentState.dvSuspects[i].incSuspectDetails, dispatch);
      } else {
        let savedParty = currentState.parties.find(
          (x) => x.ptsIncPersonId === currentState.dvSuspects[i].incSuspectDetails.ptsIncPersonId
        );
        savedParty &&
          (await addNewSuspect(
            currentState.dvSuspects[i].incSuspectDetails,
            savedParty,
            incidentID,
            dispatch
          )
            .then((res) => {
              currentState.dvSuspects[i].incSuspectDetails.ptsDVSuspectId = res.ptsDVSuspectID;
            })
            .catch((err) => {
              console.log(err);
            }));
      }
    }
    currentState.dvSuspects[i].incSuspectDetails.changes = false;
  }
}

async function saveDVAdditional(currentState, dispatch) {
  if (
    currentState.offensesDVAdditionalForm.changes === true &&
    currentState.selectedVictim !== undefined &&
    currentState.selectedVictim !== ''
  ) {
    addIncDVAdditional();
    if (Object.keys(currentState.offensesDVAdditionalForm.values).length !== 0) {
      if (currentState.offensesDVAdditionalForm.ptsDVAdditionalId) {
        await updateDVAdditional(currentState.offensesDVAdditionalForm);
      } else {
        await addNewDVAdditional(currentState.offensesDVAdditionalForm, dispatch);
      }
    }
    currentState.offensesDVAdditionalForm.changes = false;
  }
}

async function updateSubstanceProperty(updatedProperty) {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;

  if (!client) return;
  try {
    const service = client.service('ptssubstance');
    service.timeout = 20000;

    if (updatedProperty.propertyDetails.values.ptsSubstanceID) {
      await service.patch(updatedProperty.propertyDetails.values.ptsSubstanceID, {
        Quantity: updatedProperty.propertyDetails.values.quantity || null,
        QuantityMeasure: updatedProperty.propertyDetails.values.quantityMeasure || null,
        UpdatedBy: user,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// update incident properties
async function updateProperties(incIncidentPropertyId, offenceProperty, properties, dispatch) {
  let updatedProperty;

  properties.map((prop) => {
    if (prop.propertyDetails.incIncidentPropertyId === incIncidentPropertyId) {
      prop.propertyDetails.values.quantity = offenceProperty.quantity;

      prop.propertyDetails.values.quantityMeasure = offenceProperty.quantityMeasure;

      updatedProperty = prop;

      return prop;
    } else {
      return prop;
    }
  });

  // update incident property store
  dispatch({ type: GET_PROPERTIES, payload: properties });

  // update incident property database
  await updateSubstanceProperty(updatedProperty);
}

async function saveProperties(currentState, incidentID, offenseID, dispatch) {
  for (let i = 0; i < currentState.incProperties.length; i++) {
    if (
      currentState.incProperties[i].incPropertyDetails.changes === true &&
      currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId
    ) {
      currentState.incProperties[i].incPropertyDetails.ptsOffenseId = offenseID;

      if (
        currentState.incProperties[i].incPropertyDetails.values.parentType.toUpperCase() ===
        'SUBSTANCE'
      ) {
        // update incident property store and databse
        await updateProperties(
          currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId,
          currentState.incProperties[i].incPropertyDetails.values,
          currentState.properties,
          dispatch
        );
      }

      if (currentState.incProperties[i].incPropertyDetails.ptsPropertyId) {
        // update Offence Property
        await updateProperty(currentState.incProperties[i].incPropertyDetails, dispatch);
      } else {
        let savedProperty = currentState.properties.find(
          (x) =>
            x.propertyDetails.incIncidentPropertyId ===
            currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId
        );
        // add new Offence Property
        savedProperty &&
          (await addNewProperty(
            currentState.incProperties[i].incPropertyDetails,
            savedProperty,
            dispatch
          )
            .then((res) => {
              currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId =
                res.incIncidentPropertyID;
              currentState.incProperties[i].incPropertyDetails.ptsPropertyId = res.ptsPropertyID;
            })
            .catch((err) => {
              console.log(err);
            }));
      }
    }
    currentState.incProperties[i].incPropertyDetails.changes = false;
  }
}

async function saveArrestees(currentState, incidentID, offenseID, dispatch) {
  const { incArrestees, parties, offenses } = store.store.getState().incident;

  for (let i = 0; i < incArrestees.length; i++) {
    if (
      incArrestees[i].incArresteeDetails.changes === true &&
      incArrestees[i].incArresteeDetails.ptsIncPersonId
    ) {
      incArrestees[i].incArresteeDetails.ptsOffenseId = offenseID;
      if (incArrestees[i].incArresteeDetails.ptsArresteeId) {
        await updateArrestee(incArrestees[i].incArresteeDetails, dispatch);
      } else {
        let savedParty = parties.find(
          (x) => x.ptsIncPersonId === incArrestees[i].incArresteeDetails.ptsIncPersonId
        );
        savedParty &&
          (await addNewArrestee(
            incArrestees[i].incArresteeDetails,
            savedParty,
            incidentID,
            dispatch
          )
            // eslint-disable-next-line no-loop-func
            .then((res) => {
              console.log('Arrestee Created');
              incArrestees[i].incArresteeDetails.ptsArresteeId = res.ptsArresteeID;
            })
            .catch((err) => {
              console.log(err);
            }));
      }
    }
    incArrestees[i].incArresteeDetails.changes = false;
  }
  const index = offenses.findIndex((offense) => offense.offenseDetails.ptsOffenseId === offenseID);
  const offensesIncArrestees = incArrestees.filter(
    (el) => el.incArresteeDetails.ptsOffenseId === offenseID
  );
  offenses[index].offenseDetails.values.arresteeIds = offensesIncArrestees.map(
    (el) => el.incArresteeDetails.ptsArresteeId
  );

  dispatch({ type: SET_INC_ARRESTEES, payload: [...incArrestees] });
  dispatch({ type: GET_OFFENSES, payload: [...offenses] });
}

export async function saveRelationships(incidentID, offenseID, dispatch) {
  const { offenses, incRelationships, incVictims, incSubjects } = store.store.getState().incident;

  for (let i = 0; i < incRelationships.length; i++) {
    // if (incRelationships[i].incRelationshipDetails.changes === true) {
    // incRelationships[i].incRelationshipDetails.ptsOffenseId = offenseID;
    let savedVictim = incVictims.find(
      (x) =>
        x.incVictimDetails.ptsIncPersonId ===
        incRelationships[i].incRelationshipDetails.values.relationship.victimId
      //    &&
      // x.incVictimDetails.ptsOffenseId === offenseID
    );
    let savedSubject = incSubjects.find(
      (x) =>
        x.incSubjectDetails.ptsIncPersonId ===
        incRelationships[i].incRelationshipDetails.values.relationship.offenderId
      //    &&
      // x.incSubjectDetails.ptsOffenseId === offenseID
    );
    if (!savedVictim || !savedSubject) continue;
    await doesRelationshipExist(savedVictim, savedSubject, offenseID, incidentID)
      // eslint-disable-next-line no-loop-func
      .then(async (res) => {
        if (res.total > 0) {
          await updateRelationship(
            savedVictim,
            savedSubject,
            incRelationships[i].incRelationshipDetails,
            dispatch
          );
        } else {
          await addNewRelationship(
            savedVictim,
            savedSubject,
            incRelationships[i].incRelationshipDetails,
            dispatch
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
    incRelationships[i].incRelationshipDetails.changes = false;
  }

  // const index = offenses.findIndex((offense) => offense.offenseDetails.ptsOffenseId === offenseID);

  // const offensesIncRelationships = incRelationships.filter(
  //   (incRelationship) => incRelationship.incRelationshipDetails.ptsOffenseId === offenseID
  // );

  // offenses[index].offenseDetails.values.relations = offensesIncRelationships.map((el) => {
  //   const relation = el.incRelationshipDetails.values.relationship;
  //   const ptsSubjectID = incSubjects.find(
  //     (x) =>
  //       x.incSubjectDetails.ptsIncPersonId === relation.offenderId &&
  //       x.incSubjectDetails.ptsOffenseId === offenseID
  //   ).incSubjectDetails.ptsSubjectId;
  //   const ptsVictimID = incVictims.find(
  //     (v) =>
  //       v.incVictimDetails.ptsIncPersonId === relation.victimId &&
  //       v.incVictimDetails.ptsOffenseId === offenseID
  //   ).incVictimDetails.ptsVictimId;

  //   return {
  //     ptsVictimID,
  //     ptsSubjectID,
  //     ptsOffenseID: offenseID,
  //     Relationship: relation.relationship,
  //   };
  // });

  dispatch({ type: SET_INC_RELATIONSHIPS, payload: [...incRelationships] });
  // dispatch({ type: GET_OFFENSES, payload: [...offenses] });
}

const nibrsErrorCheck = async () => {
  const partiesErrors = validateParties();
  preparePartiesErrors(partiesErrors);

  const propertiesErrors = validateProperties();
  preparePropertiesErrors(propertiesErrors);

  let offenseErrors = validateOffenses();
  prepareOffenseErrors(offenseErrors);

  let arresteeErrors = validateArrestees();
  prepareArresteesErrors(arresteeErrors);

  let propertyErrors = validateIncProperties();
  prepareIncPropertiesErrors(propertyErrors);

  let incRelationshipErrors = validateIncRelationships();
  prepareIncRelationshipErrors(incRelationshipErrors);

  let incVictimErrors = validateIncVictims();
  prepareIncVictimErrors(incVictimErrors);

  let incIncidentErrors = validateIncIncident();
  prepareIncIncidentErrors(incIncidentErrors);
};

export const nibrsRulesValidation = () => {
  return async (dispatch) => {
    await nibrsErrorCheck()
      .then(async () => {
        const currentState = store.store.getState().incident;
        let validationErrors = prepareValidationErrors(currentState.validationErrors);

        let uniqueIdentifiers = [];
        let errorsToCheck = [];
        let updatedNibrsErrors = [];

        /*Getting the nibrsErrors */
        let { nibrsErrors } = currentState;

        if (nibrsErrors) {
          let allErrors = [...nibrsErrors];

          /*Getting the identifiers for nibrsErrors & validationErrors */
          const nibrsErrorsIdentifiers = nibrsErrors.map((error) => error.ErrorIdentifier);
          const validationErrorsIdentifiers = validationErrors.map(
            (error) => error.ErrorIdentifier
          );

          for (let error of validationErrors) {
            if (!nibrsErrorsIdentifiers.includes(error.ErrorIdentifier)) allErrors.push(error);
          }

          /*Getting the unique identifiers */
          if (nibrsErrorsIdentifiers.length > 0) {
            nibrsErrorsIdentifiers.map((identifier) =>
              !validationErrorsIdentifiers.includes(identifier)
                ? uniqueIdentifiers.push(identifier)
                : ''
            );
          }

          if (validationErrorsIdentifiers.length > 0) {
            validationErrorsIdentifiers.map((identifier) =>
              !nibrsErrorsIdentifiers.includes(identifier) ? uniqueIdentifiers.push(identifier) : ''
            );
          }

          /*Collecting the errors to check */
          if (nibrsErrors.length > 0) {
            nibrsErrors.map((error) =>
              uniqueIdentifiers.includes(error.ErrorIdentifier) ? errorsToCheck.push(error) : ''
            );
          }

          if (validationErrors.length > 0) {
            validationErrors.map((error) =>
              uniqueIdentifiers.includes(error.ErrorIdentifier) ? errorsToCheck.push(error) : ''
            );
          }

          const toDeleteErrorIdentifier = errorsToCheck.map((error) => error.ErrorIdentifier);

          for (let error of allErrors) {
            if (!toDeleteErrorIdentifier.includes(error.ErrorIdentifier))
              updatedNibrsErrors.push(error);
          }

          for (let error of errorsToCheck) {
            if (!nibrsErrorsIdentifiers.includes(error.ErrorIdentifier))
              updatedNibrsErrors.push(error);
          }

          dispatch({ type: SET_NIBRS_ERRORS, payload: updatedNibrsErrors });

          if (errorsToCheck) {
            for (let error of errorsToCheck) {
              await addValidationErrors(error);
              console.log('Nibrs Error Saved');
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setHandleIncFormSave = (entity) => {
  return async (dispatch) => {
    const menuState = store.store.getState().uiMenu;
    dispatch({ type: SET_CIRCULAR_LOADING, payload: true });

    try {
      const uiMenu = store.store.getState().uiMenu;
      const currentState = store.store.getState().incident;
      const evidenceCurrentState = store.store.getState().evidence;
      const recordsState = store.store.getState().records;
      const modalState = store.store.getState().uiModal;
      const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;

      // For Records
      if (recordsState.recordActive) {
        await upsertProperties(currentState, true, dispatch);
        currentState.propertyItemForm.changes = false;
        currentState.propertyFirearmForm.changes = false;
        currentState.propertySubstanceForm.changes = false;
        currentState.propertyStructureForm.changes = false;
        currentState.propertyVehicleDetailsForm.changes = false;
        currentState.propertyJewelryForm.changes = false;

        console.log('RECORD PROPERTY DONE');

        await upsertEvidence(currentState, dispatch);

        const updatedEvidenceParty = await upsertEvidenceParty(evidenceCurrentState, dispatch);

        let selectedEvidence;
        if (evidenceCurrentState?.selectedEvidence?.ptsEvidenceID) {
          selectedEvidence = evidenceCurrentState?.selectedEvidence;

          let found = false;

          const updatedEvidenceParties = selectedEvidence.evidenceParties.map((party) => {
            if (party?.ptsEvidencePersonId == updatedEvidenceParty?.ptsEvidencePersonId) {
              found = true;
              return updatedEvidenceParty;
            } else {
              return party;
            }
          });

          if (!found && updatedEvidenceParty) updatedEvidenceParties.push(updatedEvidenceParty);

          selectedEvidence.evidenceParties = updatedEvidenceParties?.length
            ? updatedEvidenceParties
            : [];

          dispatch(updateSelectedEvidence(selectedEvidence));
        }

        if (currentState.evidenceItemForm.values.ptsEvidenceId) {
          const attachments = currentState.evidenceItemForm.values.attachments;
          let attachmentDetails = await getAttachmentsRMS(
            currentState.evidenceItemForm.ptsEvidenceId,
            'EVIDENCE'
          );

          while (attachments && attachments.length !== attachmentDetails.length)
            attachmentDetails = await getAttachmentsRMS(
              currentState.evidenceItemForm.ptsEvidenceId,
              'EVIDENCE'
            );

          dispatch(
            updateIncEvidenceItemForm({
              ...currentState.evidenceItemForm,
              values: {
                ...currentState.evidenceItemForm.values,
                attachments: attachmentDetails,
              },
            })
          );
        }
        console.log('RECORD EVIDENCE DONE');

        dispatch(setIsFormDirty(false));
        dispatch(notify('Record is saved.', 'success'));
        dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
      } else {
        // For Incident

        if (!entity) {
          const incident = await upsertIncident(currentState.incident);

          currentState.ptsIncidentId = incident.ptsIncidentId;
          currentState.incident.CustomId = incident.CustomId;
          currentState.incident.ptsLocationId = incident.ptsLocationId;
          currentState.incident.changes = false;
          dispatch({ type: SET_INCIDENT, payload: incident });

          console.log('INC DONE', incident);
        } else if (entity === 'address') {
          const address = await upsertAddress(currentState, false, dispatch);
          const { addresses } = currentState;

          if (address) {
            if (address.ptsIncPlaceId)
              currentState.addressForm.values.ptsIncPlaceId = address.ptsIncPlaceId;
            currentState.addressForm.ptsAddressId = address.ptsAddressId;
            currentState.addressIdForm.ptsAddressId = address.ptsAddressId;
            currentState.coordinatesForm.ptsAddressId = address.ptsAddressId;
            currentState.coordinatesForm.ptsCoordinateId = address.ptsCoordinateId;
          }
          let foundAddress = false;
          addresses.forEach((a, i) => {
            if (a.addressDetails.ptsAddressId === address.addressDetails.ptsAddressId) {
              foundAddress = true;
              addresses[i] = address;
            }
          });
          if (foundAddress === false) addresses.push(address);

          dispatch({ type: GET_ADDRESSESS, payload: addresses });

          dispatch(setListBodyCells('addresses'));
          console.log('LOCATION DONE');
        } else if (entity === 'partyPlace') {
          const { addresses } = currentState;
          const place = await upsertPartyPlace(currentState);

          if (place) {
            dispatch(
              updateIncPartiesPlaceForm({
                ptsPlaceId: place.ptsPlaceId,
                ptsIncPlaceId: place.ptsIncPlaceId,
                placeId: place.placeId,
                values: {
                  partyType: place.addressDetails.values.partyType,
                  placeORI: place.addressDetails.values.placeORI,
                  placeDepID: place.addressDetails.values.placeDepID,
                  placeName: place.addressDetails.values.placeName,
                  destination: place.addressDetails.values.destination,
                  placeType: place.addressDetails.values.placeType,
                },
              })
            );

            let foundPlace = false;
            addresses.forEach((a, i) => {
              if (a.addressDetails.ptsPlaceId === place.addressDetails.ptsPlaceId) {
                foundPlace = true;
                addresses[i] = place;
              }
            });
            if (foundPlace === false) addresses.push(place);
          }

          dispatch({ type: GET_ADDRESSESS, payload: addresses });

          dispatch(setListBodyCells('addresses'));

          console.log('PARTY PLACE DONE');
        } else if (
          entity === 'jewelry' ||
          entity === 'structure' ||
          entity === 'substance' ||
          entity === 'vehicle' ||
          entity === 'item' ||
          entity === 'firearm'
        ) {
          const updatedProperties = await upsertProperties(currentState, false, dispatch);
          if (updatedProperties) {
            currentState.propertyItemForm.changes = false;
            currentState.propertyFirearmForm.changes = false;
            currentState.propertySubstanceForm.changes = false;
            currentState.propertyStructureForm.changes = false;
            currentState.propertyVehicleDetailsForm.changes = false;
            currentState.propertyJewelryForm.changes = false;

            dispatch({ type: GET_PROPERTIES, payload: updatedProperties });
            dispatch(setListBodyCells('properties'));
            console.log('PROPERTY DONE');
          }
        } else if (entity === 'party') {
          let foundParty = false;
          const { parties } = currentState;

          const party = await upsertParty(currentState, dispatch);
          if (party) {
            parties.forEach((p, i) => {
              if (p.personDetail.ptsIncPersonId === party.personDetail.ptsIncPersonId) {
                foundParty = true;
                parties[i] = party;
              }
            });
            if (foundParty === false) parties.push(party);
            dispatch({ type: GET_PARTIES, payload: parties });
            dispatch(setListBodyCells('parties'));
          }
          /**
           * Below ifs will execute when user add Offenders, Victims, etc by clicking the add button
           * Attach new added party to the inc* lists
           */
          // if (menuState.handleAddIncSubject)
          //   addIncSubjects(currentState.parties[currentState.parties.length - 1]);
          // if (menuState.handleAddIncVictim)
          //   addIncVictims(currentState.parties[currentState.parties.length - 1]);
          if (menuState.handleAddIncDVSuspect)
            addIncSuspects(currentState.parties[currentState.parties.length - 1]);
          if (menuState.handleAddIncRelationship) addVictimRelationships();
          // if (menuState.handleAddIncProperty)
          //   addIncProperties(currentState.properties[currentState.properties.length - 1]);
          // if (menuState.handleAddIncArrestee)
          //   addIncArrestees(currentState.parties[currentState.parties.length - 1]);

          // Juveline Checking..and locking incident

          const juvenileReport = getJuvenileReportValues(currentState.incident.values, parties);

          await updateJuvenileStatusIncident(currentState.ptsIncidentId, juvenileReport);

          // if (parties.length === 1 && parties[0] && parties[0].personDetail.values.age) {
          //   const category = getCategoryOfCode(
          //     null,
          //     party.personDetail.values.partyType,
          //     'codePartyRelationship'
          //   );

          //   if (getJuvenileAge() >= parties[0].personDetail.values.age && category === 'ofdr') {
          //     await updateJuvenileStatusIncident(currentState.ptsIncidentId, true);
          //   } else {
          //     await updateJuvenileStatusIncident(currentState.ptsIncidentId, false);
          //   }
          // } else if (parties.length > 1) {
          //   const category = getCategoryOfCode(
          //     null,
          //     party.personDetail.values.partyType,
          //     'codePartyRelationship'
          //   );

          //   const victimJuvenileFound = parties.find(
          //     (party) => category === 'vic' && getJuvenileAge() >= party.personDetail.values.age
          //   );
          //   if (victimJuvenileFound) {
          //     await updateJuvenileStatusIncident(currentState.ptsIncidentId, true);
          //   } else {
          //     await updateJuvenileStatusIncident(currentState.ptsIncidentId, false);
          //   }
          // } else {
          //   await updateJuvenileStatusIncident(currentState.ptsIncidentId, false);
          // }
          // END: Juveline Checking..and locking incident

          console.log('PARTY DONE');

          if (foundParty === true) updateSameParties(currentState);
        } else if (entity === 'offense') {
          const incidentID = currentState.ptsIncidentId;

          if (incidentID) {
            let offenseID;
            if (currentState.offensesOffenseForm.ptsOffenseId) {
              await updateOffense(currentState.offensesOffenseForm).then(async (res) => {
                changeIBRSSubmittedState(incidentID, false);
                dispatch(setCurrentOffense(res.ptsOffenseID));
                offenseID = res.ptsOffenseID;

                const newOffense = {
                  offenseDetails: {
                    ...currentState.offensesOffenseForm,
                    ptsOffenseId: res.ptsOffenseID,
                    ptsStatuteId: res.ptsStatuteID,
                    values: {
                      ...currentState.offensesOffenseForm.values,
                      ptsOffenseId: res.ptsOffenseID,
                      statute: res.statute || '',
                      fbiCode: res.ptsFBICode,
                      offenseAttempted: res.IsAttempted,
                      structuresEntered: res.StructuresEntered,
                      cargoTheft: res.IsCargoTheft,
                      structuresOccupied: res.StructuresOccupied,
                      methodOfEntry: res.MethodOfEntry,
                      locationCategory: res.IncidentLocationType,
                      criminalActivity: res.CriminalActivity,
                      offenderSuspectedOfUsing: res.OffenderSuspectedOfUsing,
                      biasMotivation: res.BiasMotivations,
                      forceCategory: res.WeaponForceType,
                      date: res.Created,
                      created: res.Created,
                      createdBy: res.createdBy,
                      updated: res.Updated,
                      updatedBy: res.UpdatedBy,
                    },
                  },
                };

                const updatedOffenses = currentState.offenses.map((s) =>
                  s.offenseDetails.ptsOffenseId === res.ptsOffenseID ? newOffense : s
                );
                dispatch(updateIncOffenses(updatedOffenses));
              });
            } else {
              if (!currentState.incident.values.ibrStatus) {
                currentState.incident.values.ibrStatus = getCodeofCategory(
                  codeIBRStatus,
                  'PENDING'
                );
                await upsertIncident(currentState.incident);
              }
              await addNewOffense(currentState.offensesOffenseForm, incidentID, dispatch)
                .then(async (res) => {
                  changeIBRSSubmittedState(incidentID, false);
                  dispatch(setCurrentOffense(res.ptsOffenseID));
                  offenseID = res.ptsOffenseID;

                  const newOffense = {
                    offenseDetails: {
                      ...currentState.offensesOffenseForm,
                      ptsOffenseId: res.ptsOffenseID,
                      ptsStatuteId: res.ptsStatuteID,
                      values: {
                        ...currentState.offensesOffenseForm.values,
                        ptsOffenseId: res.ptsOffenseID,
                        statute: res.statute || '',
                        fbiCode: res.ptsFBICode,
                        offenseAttempted: res.IsAttempted,
                        structuresEntered: res.StructuresEntered,
                        cargoTheft: res.IsCargoTheft,
                        structuresOccupied: res.StructuresOccupied,
                        methodOfEntry: res.MethodOfEntry,
                        locationCategory: res.IncidentLocationType,
                        criminalActivity: res.CriminalActivity,
                        offenderSuspectedOfUsing: res.OffenderSuspectedOfUsing,
                        biasMotivation: res.BiasMotivations,
                        forceCategory: res.WeaponForceType,
                        excludeOffense: res.IsExcluded,
                        date: res.Created,
                        created: res.Created,
                        createdBy: res.createdBy,
                        updated: res.Updated,
                        updatedBy: res.UpdatedBy,
                      },
                    },
                  };

                  // update current offense form
                  dispatch(updateIncOffensesOffenseForm(newOffense.offenseDetails));

                  // added new offense data to the offense list
                  const updatedOffenses = currentState.offenses || [];
                  updatedOffenses.push(newOffense);
                  dispatch(updateIncOffenses(updatedOffenses));

                  //Creating a society party for crime against society type offense
                  if (
                    currentState.offensesOffenseForm.values?.statuteDetails?.CrimeAgainst ===
                    'SOCIETY'
                  ) {
                    const { parties } = currentState;
                    const foundSocietyParty = parties.find(
                      (p) => p.personDetail.values?.firstName === 'Society'
                    );

                    //Creating a society type party if there is no society type party available
                    if (!foundSocietyParty) {
                      const party = await upsertParty(currentState, dispatch);
                      if (party) {
                        let foundParty = false;
                        parties.forEach((p, i) => {
                          if (p.personDetail.ptsIncPersonId === party.personDetail.ptsIncPersonId) {
                            foundParty = true;
                            parties[i] = party;
                          }
                        });
                        if (foundParty === false) parties.push(party);

                        dispatch({ type: GET_PARTIES, payload: parties });
                      }
                    }

                    //Include the existing society type party if available or include the created party in victimslist
                    if (foundSocietyParty) addIncVictims(foundSocietyParty);
                    else addIncVictims(currentState.parties[currentState.parties.length - 1]);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            currentState.offensesOffenseForm.changes = false;

            if (offenseID) {
              changeIBRSSubmittedState(incidentID, false);
              await saveSubjects(incidentID, offenseID, dispatch).finally(async (s) => {
                await saveVictims(incidentID, offenseID, dispatch).finally(async (v) => {
                  await saveRelationships(incidentID, offenseID, dispatch);
                  await saveDVVictim(currentState, dispatch);
                  await saveDVSuspects(currentState, dispatch);
                  await saveDVAdditional(currentState, dispatch);
                });
              });
              await saveProperties(currentState, incidentID, offenseID, dispatch);
              await saveArrestees(currentState, incidentID, offenseID, dispatch);
            }

            changeIBRSSubmittedState(incidentID, false);
            dispatch(
              updateIncPartiesPersonForm({ isValid: false, values: {}, touched: {}, errors: {} })
            );
          }
          dispatch(setListBodyCells('offenses'));
        } else if (entity === 'evidence') {
          const incidentID = currentState.ptsIncidentId;
          const newEvidence = {
            itemRecordDetail: { values: {} },
            evidenceParties: [],
            custodyDetail: [],
            storageDetail: [],
            dispositionDetail: { values: {} },
          };
          let foundEvidence = false;
          let isCustodyCreated = false;
          let updatedCurrentLocation = '';

          for (let i = 0; i < currentState.incEvidenceCustody.length; i++) {
            if (currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.changes === true) {
              let custodyResponse;
              if (
                currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.ptsEvidenceCustodyId
              ) {
                custodyResponse = await updateEvidenceCustody(
                  currentState.incEvidenceCustody[i].incEvidenceCustodyDetails
                );

                newEvidence.custodyDetail =
                  currentState.incEvidenceCustody[i].incEvidenceCustodyDetails;
                console.log('Custody Updated', custodyResponse);
              } else {
                if (currentState.evidenceItemForm.ptsEvidenceId) {
                  custodyResponse = await addNewEvidenceCustody(
                    currentState.incEvidenceCustody[i].incEvidenceCustodyDetails,
                    incidentID,
                    currentState.evidenceItemForm.ptsEvidenceId
                  );
                  currentState.incEvidenceCustody[
                    i
                  ].incEvidenceCustodyDetails.ptsEvidenceCustodyId =
                    custodyResponse.ptsEvidenceCustodyID;

                  isCustodyCreated = true;

                  newEvidence.custodyDetail =
                    currentState.incEvidenceCustody[i].incEvidenceCustodyDetails;
                  console.log('Evidence custody created => ', custodyResponse);
                }
              }
            }
            currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.changes = false;
          }

          // prepare 'Current location' for saving

          const custodyList = currentState.incEvidenceCustody;

          if (custodyList?.length) {
            custodyList.sort((a, b) => {
              if (!a.incEvidenceCustodyDetails.ptsEvidenceCustodyId) return -1;
              if (!b.incEvidenceCustodyDetails.ptsEvidenceCustodyId) return 1;
              return (
                b.incEvidenceCustodyDetails.ptsEvidenceCustodyId -
                a.incEvidenceCustodyDetails.ptsEvidenceCustodyId
              );
            });

            const custody = custodyList[isCustodyCreated ? 0 : 1].incEvidenceCustodyDetails.values;

            // saving 'Current location' by last Custody's 'Custody To' in database and then store

            if (currentState.evidenceItemForm?.values?.currentLocation !== custody?.custodyTo) {
              const response = await updateEvidenceCurrentLocation(
                currentState.evidenceItemForm.ptsEvidenceId,
                custody?.custodyTo
              );

              dispatch(
                updateIncEvidenceItemForm({
                  ...currentState.evidenceItemForm,
                  values: {
                    ...currentState.evidenceItemForm.values,
                    currentLocation: response?.CurrentLocation,
                    updated: displayDateTime(response?.Updated),
                    updatedBy: response?.UpdatedBy,
                  },
                })
              );
            }
          }

          if (currentState.evidenceItemForm.changes) {
            // var offenseIDs = [];
            // if (currentState.evidenceItemForm?.values.offenses) {
            //   for (let i = 0; i < currentState.evidenceItemForm?.values.offenses?.length; i++) {
            //     for (let j = 0; j < currentState.offenses.length; j++) {
            //       if (
            //         currentState.offenses[j].offenseDetails.values.statute ===
            //         currentState.evidenceItemForm?.values.offenses[i]
            //       ) {
            //         offenseIDs.push(currentState.offenses[j].offenseDetails.ptsOffenseId);
            //       }
            //     }
            //   }
            // }

            if (Object.keys(currentState.evidenceItemForm.values).length !== 0) {
              if (currentState.evidenceItemForm.ptsEvidenceId) {
                const res = await updateEvidenceItem(
                  currentState.evidenceItemForm,
                  incidentID
                  // offenseIDs
                );

                currentState.evidenceItemForm = prepareEvidenceItemRecordDetailData(res);

                console.log('Evidence item updated => ', res);
              } else {
                const res = await addNewEvidenceItem(
                  currentState.evidenceItemForm,
                  incidentID
                  // offenseIDs
                );

                currentState.evidenceItemForm = prepareEvidenceItemRecordDetailData(res);

                console.log('Evidence item created => ', res);
              }
            }
            currentState.evidenceItemForm.changes = false;
            if (currentState.evidenceItemForm.values.ptsEvidenceId) {
              const attachments = currentState.evidenceItemForm.values.attachments;
              let attachmentDetails = await getAttachmentsRMS(
                currentState.evidenceItemForm.ptsEvidenceId,
                'EVIDENCE'
              );
              while (attachments && attachments.length !== attachmentDetails.length)
                attachmentDetails = await getAttachmentsRMS(
                  currentState.evidenceItemForm.ptsEvidenceId,
                  'EVIDENCE'
                );
              dispatch(
                updateIncEvidenceItemForm({
                  ...currentState.evidenceItemForm,
                  values: {
                    ...currentState.evidenceItemForm.values,
                    attachments: attachmentDetails,
                  },
                })
              );
            }
          }

          for (let i = 0; i < currentState.incEvidenceStorage.length; i++) {
            if (currentState.incEvidenceStorage[i].incEvidenceStorageDetails.changes === true) {
              if (
                currentState.incEvidenceStorage[i].incEvidenceStorageDetails.ptsEvidenceStorageId
              ) {
                const res = await updateEvidenceStorage(
                  currentState.incEvidenceStorage[i].incEvidenceStorageDetails
                );

                newEvidence.storageDetail =
                  currentState.incEvidenceStorage[i].incEvidenceStorageDetails;
                console.log('Evidence storage updated => ', res);
              } else {
                if (currentState.evidenceItemForm.ptsEvidenceId) {
                  const res = await addNewEvidenceStorage(
                    currentState.incEvidenceStorage[i].incEvidenceStorageDetails,
                    incidentID,
                    currentState.evidenceItemForm.ptsEvidenceId
                  );
                  currentState.incEvidenceStorage[
                    i
                  ].incEvidenceStorageDetails.ptsEvidenceStorageId = res.ptsEvidenceStorageID;

                  newEvidence.storageDetail =
                    currentState.incEvidenceStorage[i].incEvidenceStorageDetails;
                  console.log('Evidence storage created => ', res);
                }
              }
            }
            currentState.incEvidenceStorage[i].incEvidenceStorageDetails.changes = false;
          }

          if (currentState.evidenceDispositionForm.changes) {
            if (Object.keys(currentState.evidenceDispositionForm.values).length !== 0) {
              if (currentState.evidenceDispositionForm.ptsEvidenceDispositionId) {
                const res = await updateEvidenceDisposition(currentState.evidenceDispositionForm);

                newEvidence.dispositionDetail = currentState.evidenceDispositionForm;
                currentState.evidenceDispositionForm.values = {
                  ...res,
                  created: res.Created,
                  updated: res.Updated,
                  createdBy: res.CreatedBy,
                  updatedBy: res.UpdatedBy,
                };
                console.log('Evidence disposition updated => ', res);
              } else {
                if (currentState.evidenceItemForm.ptsEvidenceId) {
                  const res = await addNewEvidenceDisposition(
                    currentState.evidenceDispositionForm,
                    incidentID,
                    currentState.evidenceItemForm.ptsEvidenceId
                  );
                  currentState.evidenceDispositionForm.ptsEvidenceDispositionId =
                    res.ptsEvidenceDispositionID;

                  newEvidence.dispositionDetail = currentState.evidenceDispositionForm;
                  console.log('Evidence disposition created => ', res);
                }
              }
              currentState.evidenceDispositionForm.changes = false;

              dispatch(
                updateIncEvidenceDispositionForm({
                  ...currentState.evidenceDispositionForm,
                  values: {
                    ...currentState.evidenceDispositionForm.values,
                  },
                })
              );
            }
          }

          const updatedEvidenceParty = await upsertEvidenceParty(currentState, dispatch);

          const selectedIncidentEvidence = currentState?.selectedIncidentEvidence;

          if (selectedIncidentEvidence?.itemRecordDetail?.ptsEvidenceId && updatedEvidenceParty) {
            let found = false;

            let updatedEvidenceParties = selectedIncidentEvidence.evidenceParties.map((party) => {
              if (party.ptsEvidencePersonId == updatedEvidenceParty.ptsEvidencePersonId) {
                found = true;
                return updatedEvidenceParty;
              } else {
                return party;
              }
            });

            if (!found) updatedEvidenceParties.push(updatedEvidenceParty);

            selectedIncidentEvidence.evidenceParties = updatedEvidenceParties;

            dispatch(setSelectedIncidentEvidence(selectedIncidentEvidence));
          } else if (updatedEvidenceParty) {
            newEvidence.evidenceParties = [updatedEvidenceParty];
          }

          if (newEvidence?.itemRecordDetail?.ptsEvidenceId) {
            dispatch(setSelectedIncidentEvidence(newEvidence));
          }
          dispatch(setListBodyCells('evidenceParties'));
        } else if (entity === 'narrative') {
          const incidentID = currentState.ptsIncidentId;
          const savingDisabled = uiMenu.savingDisabled;
          if (currentState.narrativeForm.changes && !savingDisabled) {
            let ptsNarrativeID = currentState.narrativeForm.values.narrativeId;
            if (
              currentState.narrativeForm.values.narrativeId ||
              currentState.narrativeForm.ptsNarrativeID
            ) {
              const res = await updateNarratives(currentState.narrativeForm, dispatch);
              currentState.narrativeForm.values = {
                ...currentState.narrativeForm.values,
                created: res.Created,
                updated: res.Updated,
                createdBy: res.CreatedBy,
                updatedBy: res.UpdatedBy,
              };
            } else {
              const res = await addNewNarratives(currentState.narrativeForm, incidentID, dispatch);

              currentState.narrativeForm.values = {
                ...currentState.narrativeForm.values,
                created: res.Created,
                updated: res.Updated,
                createdBy: res.CreatedBy,
                updatedBy: res.UpdatedBy,
              };

              //     .then((res) => {
              //       // currentState.narrativeForm.values.narrativeId = res.ptsNarrativeID;
              //       // currentState.narrativeForm.ptsNarrativeID = res.ptsNarrativeID;

              //     })
              //     .catch((err) => {
              //       console.log(err);
              //     });
              // }
              ptsNarrativeID = res.ptsNarrativeID;
            }
            currentState.narrativeForm.changes = false;

            const { narratives } = currentState;
            let foundNarrative = false;
            const narrative = {
              narrativeDetail: {
                ptsNarrativeId: currentState.narrativeForm.ptsNarrativeID,
                narrativeId: currentState.narrativeForm.values.narrativeId,
                values: {
                  ...currentState.narrativeForm.values,
                },
              },
            };
            narratives.forEach((n, i) => {
              if (
                n.narrativeDetail.ptsNarrativeId === narrative.narrativeDetail.ptsNarrativeId ||
                n.narrativeDetail.narrativeId === narrative.narrativeDetail.narrativeId
              ) {
                foundNarrative = true;
                narratives[i] = narrative;
              }
            });
            if (foundNarrative === false) narratives.push(narrative);
            dispatch({ type: GET_NARRATIVES, payload: narratives });

            const { narrativeForm } = currentState;
            const attachments = narrativeForm.values.attachments;
            let attachmentDetails = await getAttachmentsRMS(
              currentState.narrativeForm.values.narrativeId ||
                currentState.narrativeForm.ptsNarrativeID,
              'NARRATIVE'
            );

            while (attachments && attachments.length !== attachmentDetails.length)
              attachmentDetails = await getAttachmentsRMS(
                currentState.narrativeForm.values.narrativeId ||
                  currentState.narrativeForm.ptsNarrativeID,
                'NARRATIVE'
              );

            dispatch(
              updateIncNarrativeForm({
                ...narrativeForm,
                values: {
                  ...narrativeForm.values,
                  attachments: attachmentDetails,
                  narrativeId: ptsNarrativeID,
                },
                ptsNarrativeID: ptsNarrativeID,
              })
            );

            dispatch(setListBodyCells('narratives'));
          }
        } else if (['availableOffenses', 'availableArrestees'].includes(entity)) {
          const incidentID = currentState.ptsIncidentId;
          const offenseID = currentState.currentOffense;

          await saveArrestees(currentState, incidentID, offenseID, dispatch);

          changeIBRSSubmittedState(incidentID, false);
        } else if (entity === 'availableProperties') {
          const incidentID = currentState.ptsIncidentId;
          const offenseID = currentState.currentOffense;

          await saveProperties(currentState, incidentID, offenseID, dispatch);

          changeIBRSSubmittedState(incidentID, false);
        } else if (entity === 'actionReport') {
          const actionReport = currentState?.actionReport;
          let selectedActionReport = currentState?.selectedActionReport;

          if (!Object.keys(selectedActionReport)?.length) {
            selectedActionReport = {
              afterActionDetails: {},
              actionReportParties: [],
              actionReportReviews: [],
            };
          }

          // After Action Details saving flow
          if (currentState.afterActionDetailsForm.changes) {
            if (Object.keys(currentState.afterActionDetailsForm.values).length !== 0) {
              if (currentState.afterActionDetailsForm.ptsIncAfterActionId) {
                const res = await updateAfterActionDetails(currentState.afterActionDetailsForm);

                const updatedAfterActionDetails = prepareActionReportDetailsData(res);

                dispatch(updateIncAfterActionDetailsForm(updatedAfterActionDetails));

                selectedActionReport.ptsIncAfterActionId = res?.ptsIncAfterActionID || null;
                selectedActionReport.ptsIncidentId = res?.ptsIncidentID || null;

                selectedActionReport.afterActionDetails = updatedAfterActionDetails;
              } else {
                const res = await createAfterActionDetails(currentState.afterActionDetailsForm);

                const newAfterActionDetails = prepareActionReportDetailsData(res);

                dispatch(updateIncAfterActionDetailsForm(newAfterActionDetails));

                selectedActionReport.ptsIncAfterActionId = res?.ptsIncAfterActionID || null;
                selectedActionReport.ptsIncidentId = res?.ptsIncidentID || null;

                selectedActionReport.afterActionDetails = newAfterActionDetails;
              }
            }
          }

          // After Action Party saving flow
          if (currentState.afterActionPartyForm.changes) {
            if (Object.keys(currentState.afterActionPartyForm.values).length !== 0) {
              if (currentState.afterActionPartyForm.ptsIncAfterActionPartyId) {
                const res = await updateAfterActionParty(currentState.afterActionPartyForm);

                const updatedActionParty = prepareActionReportPartyDetailsData(res);

                dispatch(updateIncAfterActionPartyForm(updatedActionParty.partyDetails));

                if (selectedActionReport?.actionReportParties?.length) {
                  selectedActionReport.actionReportParties = selectedActionReport.actionReportParties.map(
                    (arp) => {
                      if (
                        arp.ptsIncAfterActionPartyId == updatedActionParty.ptsIncAfterActionPartyId
                      )
                        return updatedActionParty;
                      else return arp;
                    }
                  );
                }
              } else {
                const res = await createAfterActionParty(currentState.afterActionPartyForm);

                const newActionParty = prepareActionReportPartyDetailsData(res);

                dispatch(updateIncAfterActionPartyForm(newActionParty.partyDetails));

                selectedActionReport.actionReportParties = [
                  ...selectedActionReport.actionReportParties,
                  newActionParty,
                ];
              }
            }
          }

          // After Action Review saving flow
          if (currentState.afterActionReviewForm.changes) {
            if (Object.keys(currentState.afterActionReviewForm.values).length !== 0) {
              if (currentState.afterActionReviewForm.ptsIncAfterActionReviewId) {
                const res = await updateAfterActionReview(currentState.afterActionReviewForm);

                const updatedActionReview = prepareActionReportReviewDetailsData(res);

                dispatch(updateIncAfterActionReviewForm(updatedActionReview.reviewDetails));

                if (selectedActionReport?.actionReportParties?.length) {
                  selectedActionReport.actionReportReviews = selectedActionReport.actionReportReviews.map(
                    (arr) => {
                      if (
                        arr.ptsIncAfterActionReviewId ==
                        updatedActionReview.ptsIncAfterActionReviewId
                      )
                        return updatedActionReview;
                      else return arr;
                    }
                  );
                }
              } else {
                const res = await createAfterActionReview(currentState.afterActionReviewForm);

                const newActionReview = prepareActionReportReviewDetailsData(res);

                dispatch(updateIncAfterActionReviewForm(newActionReview.reviewDetails));

                selectedActionReport.actionReportReviews = [
                  ...selectedActionReport.actionReportReviews,
                  newActionReview,
                ];
              }
            }
          }

          // update store selected action report
          dispatch(setSelectedActionReport(selectedActionReport));

          let isExistingActionReport = false;

          const updatedActionReportsList = actionReport.map((ar) => {
            if (ar.ptsIncAfterActionId == selectedActionReport.ptsIncAfterActionId) {
              isExistingActionReport = true;

              return selectedActionReport;
            } else return ar;
          });

          if (!isExistingActionReport) updatedActionReportsList.push(selectedActionReport);

          // update store action report list
          dispatch({
            type: GET_AFTER_ACTION_REPORT,
            payload: updatedActionReportsList,
          });

          // update store ui list for Action Report, Action Parties, Action Reviews
          dispatch(setListBodyCells('actionReport'));
          dispatch(setListBodyCells('actionReportParties'));
          dispatch(setListBodyCells('actionReportReviews'));
        }

        if (!modalState.showDuplicateModal && !store.store.getState().incident.requiredError) {
          if (currentState.ptsIncidentId > 0) {
            dispatch({ type: SET_HANDLE_INC_FORM_SAVE, payload: 'edited' });
          } else {
            dispatch({ type: SET_HANDLE_INC_FORM_SAVE, payload: 'saved' });
          }
        }

        dispatch(setIsFormDirty(false));
        // dispatch(notify('Incident is saved.', 'success'));
        dispatch({ type: SET_CIRCULAR_LOADING, payload: false });

        if (currentState.turnOfValidation === false) dispatch(nibrsRulesValidation());
      }
    } catch (e) {
      dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
      // dispatch(notify('Saving failed: ' + e, 'info'));
      console.log(e);
    }
  };
};

export const setHandleIncCadFormSave = (entity) => {
  return async (dispatch) => {
    dispatch({ type: SET_CIRCULAR_LOADING, payload: true });
    try {
      const currentState = store.store.getState().incident;
      const { zoneForm, dispositionForm, tagForm } = currentState;

      if (entity === 'zones') {
        zoneForm.values.ptsLocationId = currentState.incident.ptsLocationId;
        const zone = { values: {} };
        const savedZone = await addZone(zoneForm);
        zone.values.zone = savedZone.Zone;
        zone.values.updatedBy = savedZone.UpdatedBy;

        const { zones } = currentState;
        let foundZone = false;
        zones.forEach((z, i) => {
          if (z.values.zone === zone.values.zone) {
            foundZone = true;
            zones[i] = zone;
          }
        });
        if (foundZone === false) zones.push(zone);
        dispatch({ type: GET_ZONES, payload: zones });

        dispatch(setListBodyCells('zones'));
      }

      if (entity === 'dispositions') {
        dispositionForm.values.ptsIncidentId = currentState.incident.ptsIncidentId;
        const disposition = { values: {} };
        const savedDisposition = await addDisposition(dispositionForm);
        disposition.values.disposition = savedDisposition.Disposition;
        disposition.values.updatedBy = savedDisposition.UpdatedBy;

        const { dispositions } = currentState;
        let foundDisposition = false;
        dispositions.forEach((d, i) => {
          if (d.values.disposition === disposition.values.disposition) {
            foundDisposition = true;
            dispositions[i] = disposition;
          }
        });
        if (foundDisposition === false) dispositions.push(disposition);
        dispatch({ type: GET_DISPOSITIONS, payload: dispositions });

        dispatch(setListBodyCells('dispositions'));
      }

      if (entity === 'tags') {
        let ptsParentId = currentState.incident.ptsIncidentId;
        const tag = await addTags(tagForm, 'Incident', ptsParentId);
        tagForm.ptsTagId = tag.ptsTagID;
        tagForm.values.ptsTagId = tag.ptsTagID;
        const { tags } = currentState;
        let foundTag = false;
        tags.forEach((t, i) => {
          if (t.ptsTagId === tagForm.ptsTagId) {
            foundTag = true;
            tags[i] = tagForm;
          }
        });
        if (foundTag === false) tags.push(tagForm);

        dispatch({ type: GET_TAGS, payload: tags });

        dispatch(setListBodyCells('tags'));
      }

      setTimeout(function () {
        dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
        dispatch(notify('Incident is saved.', 'success'));
      }, 1000);
      // dispatch(notify('Incident is saved.', 'success'));
      // dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
      dispatch({ type: SET_HANDLE_INC_FORM_SAVE, payload: 'edited' });
    } catch (e) {
      dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
      dispatch(notify('Saving failed: ' + e, 'info'));
    }
  };
};

/* Only for Storing Property as Evidence */
export const setHandleIncEvidenceFormSave = () => {
  const currentState = store.store.getState().incident;
  const offenseIDs = [];
  const incidentID = currentState.ptsIncidentId;

  return async (dispatch) => {
    await addNewEvidenceItem(currentState.evidenceItemForm, incidentID, offenseIDs);
    dispatch(await getEntitiesByIncidentIdV2());
    dispatch(notify('Property is added as Evidence', 'success'));
  };
};
/* End: Only for Stroring Property as Evidence */

/*** End: Form Save Region */

export const setMenuType = (type) => {
  return async (dispatch) => {
    return dispatch({ type: SET_MENU_TYPE, payload: type });
  };
};

export const setHandleAddIncSubject = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_SUBJECT, payload: flag });
  };
};

export const setHandleAddIncVictim = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_VICTIM, payload: flag });
  };
};

export const setHandleAddIncProperty = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_PROPERTY, payload: flag });
  };
};

export const setHandleAddIncRelationship = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_RELATIONSHIP, payload: flag });
  };
};

export const setHandleAddIncArrestee = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_ARRESTEE, payload: flag });
  };
};

export const setHandleAddIncDVVictim = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_DV_VICTIM, payload: flag });
  };
};

export const setHandleAddIncDVSuspect = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_DV_SUSPECT, payload: flag });
  };
};

export const setHandleAddIncDVAdditional = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_DV_ADDITIONAL, payload: flag });
  };
};

export const setHandleOffensesRedirectFormClose = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_OFFENSES_REDIRECT_FORM_CLOSE, payload: flag });
  };
};

export const setHandleClose = (type, history) => {
  return async (dispatch) => {
    const menuState = store.store.getState().uiMenu;
    if (!menuState.handleOffensesFormRedirectCloseState) {
      switch (type) {
        case 'incidents':
          const route = () => history.push('/incidents');
          return dispatch({ type: SET_HANDLE_CLOSE, payload: route });
        case 'incForms':
          const routeIncForm = () => history.push('/incidents/add');
          return dispatch({ type: SET_HANDLE_CLOSE, payload: routeIncForm });
        case 'incSubForms':
          const routeIncSubForm = () => history.push('/incidents/add/offenses');
          return dispatch({ type: SET_HANDLE_CLOSE, payload: routeIncSubForm });
        default:
          return;
      }
    } else {
      const routeOffenseForms = () => history.push('/incidents/add/offenses');
      return dispatch({ type: SET_HANDLE_CLOSE, payload: routeOffenseForms });
    }
  };
};

export const setCircularLoading = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_CIRCULAR_LOADING, payload: flag });
  };
};

export const setShowIncidentStepper = (flag) => {
  return (dispatch) => {
    return dispatch({ type: SET_SHOW_INCIDENT_STEPPER, payload: flag });
  };
};

export const setSavingDisabled = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SAVING_DISABLED, payload: flag });
  };
};

export default function reducer(
  state = {
    menuTypeState: '',
    handleCloseState: null,
    handleIncFormSaveState: null,
    handleOffensesFormRedirectCloseState: false,
    handleAddIncSubject: false,
    handleAddIncVictim: false,
    handleAddIncProperty: false,
    handleAddIncRelationship: false,
    handleAddIncArrestee: false,
    handleAddIncDVVictim: false,
    handleAddIncDVSuspect: false,
    handleAddIncDVAdditional: false,
    handleAddIncEvidenceCustody: false,
    handleAddIncEvidenceStorage: false,
    circularLoading: false,
    showIncidentStepper: true,
    savingDisabled: false,
  },
  action
) {
  switch (action.type) {
    case SET_MENU_TYPE:
      return { ...state, menuTypeState: action.payload };
    case SET_HANDLE_CLOSE:
      return { ...state, handleCloseState: action.payload };
    case SET_HANDLE_INC_FORM_SAVE:
      return { ...state, handleIncFormSaveState: action.payload };
    case SET_OFFENSES_REDIRECT_FORM_CLOSE:
      return { ...state, handleOffensesFormRedirectCloseState: action.payload };
    case SET_HANDLE_ADD_INC_SUBJECT:
      return { ...state, handleAddIncSubject: action.payload };
    case SET_HANDLE_ADD_INC_VICTIM:
      return { ...state, handleAddIncVictim: action.payload };
    case SET_HANDLE_ADD_INC_PROPERTY:
      return { ...state, handleAddIncProperty: action.payload };
    case SET_HANDLE_ADD_INC_RELATIONSHIP:
      return { ...state, handleAddIncRelationship: action.payload };
    case SET_HANDLE_ADD_INC_ARRESTEE:
      return { ...state, handleAddIncArrestee: action.payload };
    case SET_HANDLE_ADD_INC_DV_VICTIM:
      return { ...state, handleAddIncDVVictim: action.payload };
    case SET_HANDLE_ADD_INC_DV_SUSPECT:
      return { ...state, handleAddIncDVSuspect: action.payload };
    case SET_HANDLE_ADD_INC_DV_ADDITIONAL:
      return { ...state, handleAddIncDVAdditional: action.payload };
    case SET_CIRCULAR_LOADING:
      return { ...state, circularLoading: action.payload };
    case SET_SHOW_INCIDENT_STEPPER:
      return { ...state, showIncidentStepper: action.payload };
    case SET_SAVING_DISABLED:
      return { ...state, savingDisabled: action.payload };
    default:
      return state;
  }
}
