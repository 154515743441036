import { Grid, Menu } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: '180px',
    padding: '0px !important',
    margin: '0px !important',
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
function PartyEditBtn(props) {
  const classes = useStyles();
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const { editPerson, editParty, data, entityFlag = 'Record' } = props;

  const handleMenuClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <Grid item justify="flex-end">
        <span
          variant="text"
          disableFocusRipple="true"
          disableRipple="true"
          onClick={handleMenuClick}>
          <RMSEditButton viewOnly={props.viewOnly} className={classes.fabStyle} />
        </span>
      </Grid>
      <Menu
        className={classes.menu}
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="overflow-hidden dropdown-menu-xl">
          <List className="flex-column">
            <ListItem
              button
              onClick={() => {
                editParty();
                setAnchorElMenu(null);
              }}>
              {props.viewOnly ? 'View Party' : 'Edit Party'}
            </ListItem>

            {data.ptsPersonId && (
              <ListItem
                button
                onClick={() => {
                  editPerson();
                  setAnchorElMenu(null);
                }}>
                {props.viewOnly ? 'View Person' : ' Edit Person'}
              </ListItem>
            )}
          </List>
        </div>
      </Menu>
    </>
  );
}

export default PartyEditBtn;
