import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, TextField, Typography, makeStyles } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { GrMapLocation } from 'react-icons/gr';

import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  wrap: {
    position: 'relative',
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
      opacity: 0.2,
      transition: 'all 0.1s',
    },
    '&:hover, & .Mui-focused': {
      '& .MuiAutocomplete-clearIndicator': {
        opacity: 1,
      },
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
}));

const IncidentLocationsLookup = (props) => {
  const { formState, setFormState, selectedAddressId } = props;
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  const selectedAddress = useSelector((state) => {
    const address = state.incident.addresses.filter(
      (address) => address.ptsAddressId == selectedAddressId
    );

    return address ? address[0] : null;
  });

  const addresses = useSelector((state) => {
    const addresses = state.incident.addresses.filter((address) => address.ptsAddressId);

    const uniqueAddresses = addresses.reduce((unique, address) => {
      const ptsAddressId = address.ptsAddressId;
      if (!unique.find((item) => item.ptsAddressId === ptsAddressId)) {
        unique.push(address);
      }
      return unique;
    }, []);

    return uniqueAddresses;
  });

  // effects
  useEffect(() => {
    if (selectedAddress) {
      const address = addresses.find(
        (address) => address.ptsAddressId == selectedAddress.ptsAddressId
      );
      setValue(address);
    }
  }, [selectedAddress]);

  useEffect(() => {
    setOptions([...addresses]);
  }, []);

  useEffect(() => {
    if (value) {
      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          ptsAddressId: value.ptsAddressId,
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          ptsAddressId: null,
        },
      }));
    }
  }, [value]);

  //functions
  const onChange = (event, newValue) => {
    if (newValue) {
      setValue(newValue);
    } else {
      setValue(null);
    }
  };

  const renderOptionLabel = (option) => {
    return option.addressDetails.values.fullAddressText || '';
  };

  const renderOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item>
          <GrMapLocation className={classes.icon} style={{ fontSize: '24px' }} />
        </Grid>
        <Grid item xs>
          <span style={{ fontWeight: 400 }}>{option.addressDetails.values.fullAddressText}</span>

          <Typography variant="body2" color="textSecondary">
            {option.addressDetails.values?.placeName}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.wrap}>
      <Autocomplete
        options={options}
        getOptionLabel={renderOptionLabel}
        renderOption={renderOption}
        fullWidth
        size="small"
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label="Incident Locations"
              variant="outlined"
              inputProps={{ ...params.inputProps, autoComplete: 'off' }}
            />
          </>
        )}
      />
    </div>
  );
};

export default IncidentLocationsLookup;
