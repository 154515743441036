import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';

import {
  Grid,
  List,
  ListItem,
  makeStyles,
  withStyles,
  lighten,
  Fade,
  Tooltip,
  Card,
  Badge,
  Avatar,
  useMediaQuery,
} from '@material-ui/core';
import { Typography, alpha } from '@mui/material';

import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';

import PartyEditBtn from 'pages/PageRMSIncidents/partyEditBtn';
import RMSDeleteButton from 'components/RMSButtons/RMSDeleteButton';
import PlusCircleButton from 'components/RMSButtons/RMSAddButton/plusCircleButton';

import {
  resetSubForms,
  setSelectedEntity,
  removeSelectedEntity,
  setFormType,
  updateIncAfterActionPartyForm,
  initFormModel,
} from 'reducers/IncReducer';
import { setListBodyCells } from 'reducers/ui/UiListReducer';
import { showEditPerson } from 'reducers/PersonDialogsReducer';
import { getAccessPermission, getFullPermissions } from 'reducers/PermissionsReducer';

import { colors } from 'config/colorVariables';

import {
  removeEvidencePartiesFromDatabase,
  removeEvidencePartiesFromStore,
} from 'reducers/helpers/evidenceHelpers';
import {
  removeAfterActionPartyFromDatabase,
  removeAfterActionPartyFromStore,
  removeAfterActionReviewFromDatabase,
  removeAfterActionReviewFromStore,
  removeIncidentEvidencePartiesFromStore,
} from 'reducers/helpers/incHelpers';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';

const PRIMARY_COLOR = '#1977D4';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 55,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0px 4px',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  narrativeBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  incidentLabel: {
    padding: '3px 0px',
    fontWeight: 'bold',
  },
  detailsText: {
    color:
      theme.palette.type === 'light'
        ? lighten(theme.palette.text.primary, 0.4)
        : lighten(theme.palette.text.secondary, 2.5),
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
  warrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    '&:hover': {
      background: theme.palette.error.dark + ' !important',
    },
  },
  accordionStyle: {
    backgroundColor: theme.accordion.background,
  },
  listItem: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  listItem_root: {
    color: theme.palette.colorFont,
    // borderTop: '.5px solid ',
    // borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    padding: '0px',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  inline_design: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list_root: {
    width: '100%',
    paddingTop: '2px',
    // paddingLeft: '30px',
    overflowY: 'scroll',

    // '&::-webkit-scrollbar': {
    //   width: 7,
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#888',
    //   visibility: 'visible',
    // },
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    marginLeft: '34px',
    marginRight: '11px',
  },
  avatar: {
    // margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    width: '50px',
    height: '50px',
    marginRight: theme.spacing(2),
  },
  tags_dispositions_zones_grid: {
    width: '100%',
    padding: '30px 30px',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // width: '20em',
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    // },
  },
  parent_div: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    marginLeft: '5px',
    // paddingTop: '5px',
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  noPermissionSection: {
    width: '100%',
    textAlign: 'center',
  },
}));

const AfterActionParties = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { incId, actionReportId } = useParams();

  // Props and State
  const { entityFlag, keys = [], allCardExpanded } = props;

  const [paths, setPaths] = useState([]);

  const [allEvidence, setAllEvidence] = useState([]);

  const [selectedEvidenceId, setSelectedEvidenceId] = useState(null);

  const [evidencePartiesList, setEvidencePartiesList] = useState([]);

  const [entityPermission, setEntityPermission] = useState({});
  const [selected, setSelected] = useState([]);
  const [parentType, setParentType] = useState(null);
  const [rowKeyProperty, setRowKeyProperty] = useState('ptsIncAfterActionPartyId');
  const [cardHeight, setCardHeight] = useState(0);
  const [cardExpanded, setCardExpanded] = useState(false);

  // Redux State
  const activeEvidences = useSelector((state) => state.evidence.activeEvidences);

  const selectedEvidence = useSelector((state) => state.evidence.selectedEvidence);

  const bodyList = useSelector((state) => state.uiList.incListBodyState);

  const userAgency = useSelector((state) => state.user.userAgency);

  const user = useSelector((state) => state?.user?.userData?.user?.Username);

  const evidenceItemForm = useSelector((state) => state.incident.evidenceItemForm);

  const ptsIncidentId = useSelector((state) => state.incident.ptsIncidentId);

  const incident = useSelector((state) => state.incident.incident);

  const permissions = getFullPermissions('rms', 'Action Party', userAgency);

  const isMediumScreenOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // Effects
  useEffect(() => {
    setPaths(history.location.pathname.split('/'));
  }, [history]);

  useEffect(() => {
    if (activeEvidences) {
      setAllEvidence(activeEvidences);
    }
  }, [activeEvidences]);

  useEffect(() => {
    if (allEvidence.length && selectedEvidenceId) {
      const selectedEvidence = allEvidence.find((row) => row.ptsEvidenceID == selectedEvidenceId);

      setEvidencePartiesList(selectedEvidence?.evidenceParties);
    }
  }, [selectedEvidenceId, allEvidence]);

  useEffect(() => {
    if (allCardExpanded === 'expand') setCardExpanded(true);
    else if (allCardExpanded === 'collapse') setCardExpanded(false);
  }, [allCardExpanded]);

  useEffect(() => {
    let cardHeight = 0;

    if (bodyList['actionReportParties']?.length > 0) {
      let maxHeight = cardExpanded
        ? bodyList['actionReportParties'].length * 43 + 8
        : Math.min(2, bodyList['actionReportParties'].length) * 43 + 8;
      cardHeight = maxHeight + 'px';
    }

    setCardHeight(cardHeight);
  }, [cardExpanded, bodyList['actionReportParties']]);

  // Functions
  const generateHeaderRow = () => {
    if (!bodyList['actionReportParties']?.length) return;

    return (
      <Grid container item lg={12} xs={12} className={classes.listHeader}>
        <Grid container item xs={isMediumScreenOrSmaller ? 9 : 10}>
          <Grid item xs={1}></Grid>
          <Grid item xs={isMediumScreenOrSmaller ? 5 : 3}>
            Party Type
          </Grid>
          <Grid item xs={isMediumScreenOrSmaller ? 5 : 3}>
            Party Name
          </Grid>
          <Grid item xs={isMediumScreenOrSmaller ? 7 : 5} hidden={isMediumScreenOrSmaller}>
            Party Details
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const generateList = (it) => {
    return (
      <Grid container item xs={isMediumScreenOrSmaller ? 9 : 10} className="items-center">
        <Grid item xs={1}></Grid>
        <Grid item xs={isMediumScreenOrSmaller ? 5 : 3} className={classes.parent_div}>
          {/* <Tooltip title={it?.partyTypeDescription}>{it?.partyTypeDescription}</Tooltip> */}

          <div className={classes.inline_design}>{it?.partyTypeDescription || ''}</div>
        </Grid>

        <Grid item xs={isMediumScreenOrSmaller ? 5 : 3} className={classes.parent_div}>
          <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{it.fullName || ''}</div>
        </Grid>

        <Grid
          item
          xs={isMediumScreenOrSmaller ? 7 : 5}
          className={classes.parent_div}
          hidden={isMediumScreenOrSmaller}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div>Age: {it.age || ''}</div>
            <div style={{ paddingLeft: '10px' }}>Sex: {it.sexDescription || ''}</div>
            <div style={{ paddingLeft: '10px' }}>Race: {it.raceDescription || ''}</div>
            <div style={{ paddingLeft: '10px' }}>Ethnicity: {it.ethnicityDescription || ''}</div>
          </div>
        </Grid>
      </Grid>
    );
  };

  const generateActions = (row) => {
    const isSelected = (row) => {
      return selected.length && selected[0] === row[rowKeyProperty];
    };

    const handleEdit = (row) => {
      dispatch(setFormType('edit'));
      resetSubForms('edit');

      const editUrl = `/incidents/edit/${incId}/actionReport/${actionReportId}/parties/${row?.ptsIncAfterActionPartyId}`;

      history.push(editUrl);
    };

    const handleDeleteClick = async (row) => {
      dispatch(removeAfterActionPartyFromStore(row.ptsIncAfterActionPartyId));

      dispatch(setListBodyCells('actionReportParties'));

      await removeAfterActionPartyFromDatabase(row.ptsIncAfterActionPartyId);
    };

    return (
      <Grid
        item
        xs={isMediumScreenOrSmaller ? 3 : 2}
        style={{
          paddingTop: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Fade in={selected.length > 0 && isSelected(row)}>
          <Grid container wrap="nowrap" spacing={2} justify="flex-end" xs={12}>
            <Grid item>
              <RMSEditButton
                className={classes.fabStyle}
                viewOnly={!permissions?.Edit || !!incident.values?.isLocked || !actionReportId}
                onClick={() => handleEdit(row)}
              />
            </Grid>

            <Grid item justify="flex-end">
              <RMSDeleteButton
                disabled={!permissions.Delete || !!incident.values?.isLocked || !actionReportId}
                className={classes.fabStyle}
                onClick={() => handleDeleteClick(row)}
                entityName={entityFlag}
              />
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    );
  };

  const handleAddNew = () => {
    dispatch(setFormType('add'));
    resetSubForms('add');

    dispatch(updateIncAfterActionPartyForm(initFormModel));

    const addUrl = `/incidents/${incId}/actionReport/${actionReportId}/add/parties`;

    history.push(addUrl);
  };

  const onMouseEnterEvent = (event, row) => {
    event.persist();

    let newSelected = [];
    if (!selected.length || selected[0] !== row[rowKeyProperty]) {
      newSelected.push(row[rowKeyProperty]);
    }

    setSelected(newSelected);

    dispatch(setSelectedEntity({ [rowKeyProperty]: row[rowKeyProperty] }));
  };

  const onMouseLeaveEvent = (event, row) => {
    event.persist();
    setSelected([]);
    dispatch(setSelectedEntity({}));
  };

  return (
    <>
      <Card className={classes.cardView}>
        <Grid container spacing={1}>
          <Grid
            item
            container
            style={{
              marginLeft: '10px',
              marginBottom: '15px',
            }}
            xs={12}>
            <Grid item xs={12} style={{ marginTop: '6px', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: '4px', marginRight: '6px' }}>
                <PersonIcon style={{ color: PRIMARY_COLOR }} />
              </span>
              <Typography variant="span" className={classes.spanHeader}>
                {'Parties'} ({bodyList['actionReportParties']?.length || 0})
              </Typography>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '20px',
                  paddingBottom: '3px',
                  paddingTop: '3px',
                }}>
                <span
                  style={{
                    padding: '0px',
                    '&:hover': {
                      background: 'transparent',
                    },
                    border: 'none',
                    background: 'transparent',
                    marginLeft: '-7px',
                  }}>
                  <PlusCircleButton
                    disabled={!permissions.Create || !!incident.values?.isLocked || !actionReportId}
                    onClick={handleAddNew}
                  />
                </span>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropDownCircleIcon
                    onClick={() => setCardExpanded(!cardExpanded)}
                    className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </span>
            </Grid>

            {bodyList['actionReportParties']?.length !== 0 && (
              <Grid container item xs={12} className={classes.list_div}>
                {generateHeaderRow()}
                <List className={classes.list_root} style={{ height: cardHeight }}>
                  {permissions?.Read ? (
                    bodyList['actionReportParties'].map((row, index) => {
                      return (
                        <ListItem className={classes.listItem_root} key={index}>
                          <Grid
                            item
                            container
                            xs={12}
                            style={{ borderWidth: index === 0 ? '0' : '' }}
                            className={
                              row.activeWarrant
                                ? clsx(classes.listItem, classes.warrant)
                                : classes.listItem
                            }
                            onMouseEnter={(event) => onMouseEnterEvent(event, row)}
                            onMouseLeave={(event) => onMouseLeaveEvent(event, row)}>
                            {generateList(row)}
                            {generateActions(row)}
                          </Grid>
                        </ListItem>
                      );
                    })
                  ) : (
                    <div className={classes.noPermissionSection}>
                      <h6 className="pt-2">You don't have permission to see the List</h6>
                    </div>
                  )}
                </List>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AfterActionParties;
