import { makeStyles, TextField, IconButton } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Fab, Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PrintIcon from '@material-ui/icons/Print';
import { useSelector } from 'react-redux';
import PersonSearchLite from 'components/RMSPersonSearch/PersonSearchLite';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import { Autocomplete } from '@material-ui/lab';
import { ExitToApp } from '@material-ui/icons';
import DownloadCSV from './DownloadCSV';
import { processParameters } from './reportDataProcessingHelper';
const useStyles = makeStyles((theme) => ({
  reportContainer: {
    display: 'flex',
  },
  reportSelection: {
    flex: 0.3,
  },
  reportFilter: {
    flex: 0.7,
  },
  report: {
    paddingBottom: '4px',
  },
}));

const CitationReportFilter = (props) => {
  const classes = useStyles();
  const { reportForm, handleReportFormChange, ReportID } = props;
  const formValue = reportForm[ReportID] || {};

  const codePartyRelationship = useSelector((state) => state.dictionary.codePartyRelationship);
  const [ptsStatueArray, setPtsStatueArray] = useState([]);
  const agencySettings = useSelector((state) => state.agencySettings.settings);
  const userAgency = useSelector((state) => state.user.userAgency);
  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const ptsFBICode = useSelector((state) => state.dictionary.ptsFBICode);
  const partyTypes = useMemo(() => {
    return codePartyRelationship.filter(
      (p) => p.Entities !== null && p.Entities.includes('Citation')
    );
  }, codePartyRelationship);

  const legacyExportSetting = agencySettings?.find(
    (item) => item.Path === `${userAgency}.RMS.AllowCitationLegacyExport`
  );
  const isLegacyExportEnable =
    legacyExportSetting && legacyExportSetting?.ValueBoolean === true ? true : false;

  useEffect(() => {
    let sortedArray = ptsStatute
      .filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray.forEach((s) => {
      let fbCode = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID)?.FBICode;
      s.FBICode = fbCode;
      arr.push(s);
    });
    if (isLegacyExportEnable) {
      arr = arr.filter((item) => item.StatuteID);
    }

    setPtsStatueArray(arr);
  }, []);

  return (
    <div className={classes.report}>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className="py-2">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <DownloadCSV
            ReportID={ReportID}
            filters={processParameters(ReportID, formValue, 'csv')}
          />
        </Grid>
      </Grid>
      <Grid container justify="left" alignItems="center" spacing={1}>
        <Grid item xs={6} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Citation From"
              defaultValue={null}
              value={formValue.citationDateFrom || null}
              onChange={(date) => handleReportFormChange(ReportID, 'citationDateFrom', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Citation To"
              defaultValue={null}
              value={formValue.citationDateTo || null}
              onChange={(date) => handleReportFormChange(ReportID, 'citationDateTo', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} lg={8}>
          <PersonSearchLite
            placeholder="Person"
            multiple={true}
            partyName={formValue.parties || ''}
            setName={(newVal) => {
              handleReportFormChange(ReportID, 'parties', newVal, true);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <RMSDictionary
            options={partyTypes}
            multiple={true}
            value={formValue.partyTypes || []}
            label="Party Type"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'partyTypes', newVal);
            }}
          />
        </Grid>

        <Grid item xs={12} md={8} lg={9}>
          <Autocomplete
            multiple
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => {
              return `${option.Description} | ${option.Code} | ${option.FBICode}`;
            }}
            options={ptsStatueArray}
            key={formValue?.offenses || ''}
            value={formValue?.offenses || []}
            onChange={(event, newValue) => {
              handleReportFormChange(ReportID, 'offenses', newValue);
            }}
            renderInput={(params) => (
              <Grid container xs={12} a className="mb-3 pt-1 d-flex">
                <TextField
                  autoComplete="off"
                  size="small"
                  {...params}
                  label="Search offense"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CitationReportFilter;
