import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { v4 as uuidv4 } from 'uuid';
import DateFnsUtils from '@date-io/date-fns';

import { Grid, Card, TextField, makeStyles, Typography } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

import { Row } from 'utils/formStyles';

import IncidentLocationsLookup from './IncidentLocationsLookup';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';

import {
  getFormat24,
  dateTimePicker,
  formatSaveDate,
  getDateTimeFormat,
} from 'reducers/TimeReducer';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import {
  getCityIdFromCityName,
  resetSubForms,
  setFormType,
  setSelectedEntity,
  updateIncAfterActionDetailsForm,
} from 'reducers/IncReducer';
import { Autocomplete } from '@material-ui/lab';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import RMSDeleteButton from 'components/RMSButtons/RMSDeleteButton';
import PlusCircleButton from 'components/RMSButtons/RMSAddButton/plusCircleButton';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const PRIMARY_COLOR = '#1977D4';
const useStyles = makeStyles((theme) => ({
  gridBg: {
    width: '100%',
    margin: '0px 0px 4px 0px',
    display: 'block',
    padding: '16px 10px',
  },
  contentCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    marginLeft: '5px',
    marginRight: '7px',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    // paddingTop: '5px',
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    padding: '10px',
    marginLeft: '37px',
    marginRight: '15px',
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  input: {
    borderRadius: '0.5rem ',
    color: 'black',
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const AfterActionLocation = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const format24 = getFormat24();
  const signs = [{ name: '+' }, { name: '-' }];

  // State
  const { viewOnly, changedKey, allCardExpanded } = props;
  const [componentChangedKey, setComponentChangeKey] = useState(0);
  const [cardExpanded, setCardExpanded] = useState(true);

  const [selectedCity, setSelectedCity] = useState(null);

  const [afterActionDetailsFormState, setAfterActionDetailsFormState] = useState({
    tempAfterActionId: uuidv4(),
    changes: false,
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [afterActionLocationFormState, setAfterActionLocationFormState] = useState({
    tempAddressId: uuidv4(),
    changes: false,
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  // Redux State
  const afterActionDetailsForm = useSelector((state) => state.incident.afterActionDetailsForm);

  const addresses = useSelector((state) => state.incident.addresses);

  const ptsIncidentId = useSelector((state) => state.incident.ptsIncidentId);

  const codeStates = useSelector((state) => state.dictionary.codeStates);

  const codeCounties = useSelector((state) => state.dictionary.codeCounties);

  const userAgency = useSelector((state) => state.user.userAgency);

  const permissions = getFullPermissions('rms', 'Action Report', userAgency);

  // Effects
  useEffect(() => {
    if (allCardExpanded === 'expand') setCardExpanded(true);
    else if (allCardExpanded === 'collapse') setCardExpanded(false);
  }, [allCardExpanded]);

  useEffect(() => {
    if (
      Object.keys(afterActionDetailsFormState.values).length !==
        Object.keys(afterActionDetailsForm.values).length &&
      JSON.stringify(afterActionDetailsFormState.values) !==
        JSON.stringify(afterActionDetailsForm.values)
    ) {
      setAfterActionDetailsFormState({
        ...afterActionDetailsFormState,
        ...afterActionDetailsForm,
        values: { ...afterActionDetailsFormState.values, ...afterActionDetailsForm?.values },
      });
    }
  }, [afterActionDetailsForm, changedKey]);

  useEffect(() => {
    afterActionDetailsFormState.changes = true;

    dispatch(updateIncAfterActionDetailsForm(afterActionDetailsFormState));
  }, [afterActionDetailsFormState.values?.ptsAddressId]);

  useEffect(() => {
    if (afterActionDetailsFormState.values?.ptsAddressId) {
      const selectedAddress = addresses.find(
        (address) => address.ptsAddressId == afterActionDetailsFormState.values?.ptsAddressId
      );

      setSelectedCity(getCityIdFromCityName(selectedAddress?.addressDetails?.values?.city) || null);

      setAfterActionLocationFormState({
        values: {
          city: getCityIdFromCityName(selectedAddress?.addressDetails?.values?.city) || null,
          state: selectedAddress?.addressDetails?.values?.state || null,
          postalCode: selectedAddress?.addressDetails?.values?.postalCode || null,
          county: selectedAddress?.addressDetails?.values?.county || null,
          latitudeSign: selectedAddress?.addressCoordinatesDetails?.values?.latitudeSign || null,
          latitudeDegree:
            selectedAddress?.addressCoordinatesDetails?.values?.latitudeDegree || null,
          longitudeSign: selectedAddress?.addressCoordinatesDetails?.values?.longitudeSign || null,
          longitudeDegree:
            selectedAddress?.addressCoordinatesDetails?.values?.longitudeDegree || null,
        },
      });

      setComponentChangeKey(componentChangedKey + 1);
    } else {
      setSelectedCity(null);

      setAfterActionLocationFormState({
        values: {
          city: null,
          state: null,
          postalCode: null,
          county: null,
          latitudeSign: null,
          latitudeDegree: null,
          longitudeSign: null,
          longitudeDegree: null,
        },
      });

      setComponentChangeKey(componentChangedKey + 1);
    }
  }, [afterActionDetailsFormState.values?.ptsAddressId, changedKey]);

  const renderIncidentLocationsLookup = (xs) => {
    return (
      <Grid item xs={xs}>
        <IncidentLocationsLookup
          formState={afterActionDetailsFormState}
          setFormState={setAfterActionDetailsFormState}
          selectedAddressId={afterActionDetailsFormState.values.ptsAddressId}
        />
      </Grid>
    );
  };

  const renderCounty = (xs) => {
    return (
      <Grid item xs={xs}>
        <RMSAutoComplete
          disabled={true}
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-county"
          options={codeCounties}
          label="County"
          serviceName="codeCounties"
          title="Description"
          stateHolderAttribute="county"
          variant="outlined"
          formType={'edit'}
          stateHolder={afterActionLocationFormState}
          setStateHolder={setAfterActionLocationFormState}
          setField={handleFormChangeNew}
        />
      </Grid>
    );
  };

  const renderCity = (xs) => {
    return (
      <Grid item xs={xs}>
        <RMSDictionary
          disabled={true}
          options="codeCities"
          autoComplete
          autoSelect
          autoHighlight
          onChange={(ev, newVal) => {
            setSelectedCity(newVal);
            handleFormChangeNew(
              null,
              newVal?.CityDescription || '',
              'none',
              'city',
              setAfterActionLocationFormState
            );
          }}
          value={selectedCity || ''}
          label="City"
        />
      </Grid>
    );
  };

  const renderState = (xs) => {
    return (
      <Grid item xs={xs}>
        <RMSAutoComplete
          disabled={true}
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-state"
          getOptionLabel={(option) => option || ''}
          options={codeStates}
          label="State"
          serviceName="codeStates"
          title="Description"
          stateHolderAttribute="state"
          variant="outlined"
          formType={'edit'}
          stateHolder={afterActionLocationFormState}
          setStateHolder={setAfterActionLocationFormState}
          setField={handleFormChangeNew}
        />
      </Grid>
    );
  };

  const renderPostalCode = (xs) => {
    return (
      <Grid item xs={xs}>
        <TextField
          disabled
          id="outlined-multiline-static-address-postal-code"
          autoComplete="hidden"
          size="small"
          placeholder="Postal Code"
          label="Postal Code"
          variant="outlined"
          name="postalCode"
          key={componentChangedKey}
          value={afterActionLocationFormState.values?.postalCode || ''}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'postalCode',
              setAfterActionLocationFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  };

  const renderLatitude = (xs1, xs2) => {
    return (
      <>
        <Grid item xs={xs1}>
          <Autocomplete
            disabled
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => option || ''}
            options={signs.map((s) => s.name)}
            key={componentChangedKey}
            value={afterActionLocationFormState.values?.latitudeSign || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'latitudeSign',
                setAfterActionLocationFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                size="small"
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                label="+/-"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={xs2}>
          <TextField
            disabled
            autoComplete="hidden"
            size="small"
            label="Degrees Latitude"
            placeholder="Degrees Latitude"
            variant="outlined"
            fullWidth
            name="latitudeDegree"
            key={componentChangedKey}
            defaultValue={afterActionLocationFormState.values?.latitudeDegree}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'latitudeDegree',
                setAfterActionLocationFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </>
    );
  };

  const renderLongitude = (xs1, xs2) => {
    return (
      <>
        <Grid item xs={xs1}>
          <Autocomplete
            disabled
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => option || ''}
            options={signs.map((s) => s.name)}
            key={componentChangedKey}
            value={afterActionLocationFormState.values?.longitudeSign || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'longitudeSign',
                setAfterActionLocationFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                size="small"
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                label="+/-"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={xs2}>
          <TextField
            disabled
            autoComplete="hidden"
            size="small"
            label="Degrees Longitude"
            placeholder="Degrees Longitude"
            variant="outlined"
            fullWidth
            name="longitudeDegree"
            key={componentChangedKey}
            defaultValue={afterActionLocationFormState.values?.longitudeDegree}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'longitudeDegree',
                setAfterActionLocationFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </>
    );
  };

  const renderActions = (xs) => {
    const handleEdit = () => {
      dispatch(setFormType('edit'));
      resetSubForms('edit');
      dispatch(
        setSelectedEntity({ ptsAddressId: afterActionDetailsFormState.values?.ptsAddressId })
      );

      history.push(
        `/incidents/edit/${ptsIncidentId}/addresses/${afterActionDetailsFormState.values?.ptsAddressId}`
      );
    };

    const handleAdd = () => {
      dispatch(setFormType('add'));
      resetSubForms('add');
      dispatch(setSelectedEntity({}));

      history.push(`/incidents/add/addresses`);
    };

    return (
      <Grid
        container
        direction="row"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        xs={xs}>
        <Grid item>
          <RMSEditButton
            className={classes.fabStyle}
            disabled={!afterActionDetailsFormState.values?.ptsAddressId || !ptsIncidentId}
            viewOnly={!permissions?.Edit}
            onClick={handleEdit}
          />
        </Grid>
        <Grid item>
          <PlusCircleButton disabled={!permissions?.Create} onClick={handleAdd} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Card className={classes.cardView}>
        <Grid container spacing={1}>
          <Grid item container style={{ marginLeft: '10px', marginBottom: '15px' }} xs={12}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: '6px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}>
              <span style={{ marginRight: '5px' }}></span>
              <LocationOnRoundedIcon style={{ color: PRIMARY_COLOR }} />
              <Typography variant="span" className={classes.spanHeader}>
                Location
              </Typography>

              <span
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '20px',
                  paddingBottom: '3px',
                  paddingTop: '3px',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropDownCircleIcon
                    onClick={() => setCardExpanded(!cardExpanded)}
                    className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </span>
            </Grid>

            {cardExpanded && (
              <Row>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.list_div}
                  spacing={1}
                  style={{ paddingTop: '10px' }}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    direction="row"
                    style={{ marginBottom: '5px' }}>
                    {renderIncidentLocationsLookup(6)}
                    {renderActions(2)}
                    {renderCounty(4)}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    direction="row"
                    style={{ marginBottom: '-15px' }}>
                    {renderCity(4)}
                    {renderState(4)}
                    {renderPostalCode(4)}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    direction="row"
                    style={{ marginBottom: '5px' }}>
                    {renderLatitude(2, 4)}
                    {renderLongitude(2, 4)}
                  </Grid>
                </Grid>
              </Row>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AfterActionLocation;
