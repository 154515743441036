/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

export const PersonSearchLite = (props) => {
  const { placeholder, setName, partyName, multiple } = props;
  const [value, setValue] = React.useState(multiple ? [] : null);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const wsClient = useSelector((state) => state.websocket);
  React.useEffect(() => {
    let active = true;

    if (!wsClient || !wsClient.websocket) {
      return;
    }

    if (inputValue === '') return;
    searchForPerson(active);

    return () => {
      active = false;
    };
  }, [wsClient, inputValue]);

  React.useEffect(() => {
    if (partyName)
      setValue(multiple ? (Array.isArray(partyName) ? partyName : [partyName]) : partyName);
  }, [partyName]);

  const searchForPerson = async (active) => {
    if (inputValue.length < 3) return;
    const service = wsClient.websocket.service('ptsperson');
    service.timeout = 20000;

    const response = await service.find({
      query: {
        IsDeleted: false,
        FullName: {
          $like: `%${inputValue}%`,
          $ne: null,
        },
        $select: ['FullName', 'ptsPersonID'],
      },
    });

    setOptions(response.data);
  };

  const handlePeopleSelect = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setName(newValue);
    setValue(newValue);
  };

  return (
    <Autocomplete
      size="small"
      autoComplete
      autoSelect={false}
      autoHighlight
      multiple={multiple}
      value={value}
      id="combo-search-person"
      style={{ width: '100%' }}
      getOptionLabel={(option) => {
        const fullName = option.FullName;
        return fullName ? fullName : '';
      }}
      renderOption={(option) => {
        const fullName = option.FullName;
        return fullName ? fullName : '';
      }}
      options={options}
      onChange={handlePeopleSelect}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          size="small"
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          label={placeholder}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default PersonSearchLite;
