import store from '../config/configureStore';

import {
  getCityName,
  getDescriptionofCode,
  getCategoryOfCode,
  getCodeofCategory,
  getDataOfDictionaryOptions,
  formatPersonFullName,
} from 'utils/functions';

import {
  addNewEvidenceItem,
  getEntitiesByIncIdV2,
  removeEntity,
  removeIncident,
  removeSubjectRelationships,
  removeVictimRelationships,
  removePartiesAvailableOffenses,
  removePropertiesAvailableOffenses,
  prepareActionReportData,
} from './helpers/incHelpers';
import { getAttachmentsRMS } from '../reducers/helpers/fileHelpers';
import { displayDateTime, formatSaveDate, getCurrentDate } from './TimeReducer';
import { setListBodyCells } from './ui/UiListReducer';
import { nibrsRulesValidation, saveRelationships } from './ui/UiMenuReducer';
import { updateEvidencePartiesPersonForm } from 'reducers/EvidenceReducer';
import { prepareEvidencePartyPersonData } from './helpers/evidenceHelpers';

/************************************/
/****** Region Action Types ********/
/**********************************/
export const SET_INCIDENT_CURRENT_ACTION = 'SET_INCIDENT_CURRENT_ACTION';
export const SET_INCIDENT = 'SET_INCIDENT';
export const RESET_INCIDENT = 'RESET_INCIDENT';
export const SET_IS_FORM_DIRTY = 'SET_IS_FORM_DIRTY';
export const SET_TURN_OFF_VALIDATION = 'SET_TURN_OFF_VALIDATION';
export const SET_HIDDEN_DATA = 'SET_HIDDEN_DATA';
export const SET_CUSTODY_AUTO_GENERATED_DATA = 'SET_CUSTODY_AUTO_GENERATED_DATA';
export const SET_EVENT = 'SET_EVENT';
export const SET_INC_ADDRESS = 'SET_INC_ADDRESS';
export const SET_INC_ADDRESS_ID = 'SET_INC_ADDRESS_ID';
export const SET_INC_COORDINATES = 'SET_INC_COORDINATES';
export const SET_INC_PARTIES_PERSON = 'SET_INC_PARTIES_PERSON';
export const SET_INC_PARTIES_CONTACT = 'SET_INC_PARTIES_CONTACT';
export const SET_INC_PARTIES_EMPLOYMENT = 'SET_INC_PARTIES_EMPLOYMENT';
export const SET_INC_PARTIES_OTHERS = 'SET_INC_PARTIES_OTHERS';
export const SET_INC_PARTIES_PLACE = 'SET_INC_PARTIES_PLACE';
export const SET_INC_PROPERTY_ITEM = 'SET_INC_PROPERTY_ITEM';
export const SET_INC_PROPERTY_FIREARM = 'SET_INC_PROPERTY_FIREARM';
export const SET_INC_PROPERTY_JEWELRY = 'SET_INC_PROPERTY_JEWELRY';
export const SET_INC_PROPERTY_STRUCTURE = 'SET_INC_PROPERTY_STRUCTURE';
export const SET_INC_PROPERTY_SUBSTANCE = 'SET_INC_PROPERTY_SUBSTANCE';
export const SET_INC_PROPERTY_VEHICLE_DETAILS = 'SET_INC_PROPERTY_VEHICLE_DETAILS';
export const SET_INC_PROPERTY_CREDENTIALS_ANALYSIS = 'SET_INC_PROPERTY_CREDENTIALS_ANALYSIS';
export const SET_INC_OFFENSES_OFFENSE = 'SET_INC_OFFENSES_OFFENSE';
export const SET_INC_OFFENSES_PROPERTY_OWNER = 'SET_INC_OFFENSES_PROPERTY_OWNER';
export const SET_INC_OFFENSES_VICTIM = 'SET_INC_OFFENSES_VICTIM';
export const SET_INC_OFFENSES_VICTIM_SUBFORMS = 'SET_INC_OFFENSES_VICTIM_SUBFORMS';
export const SET_INC_OFFENSES_DV_VICTIM = 'SET_INC_OFFENSES_DV_VICTIM';
export const SET_INC_SUBJECTS = 'SET_INC_SUBJECTS';
export const SET_INC_VICTIMS = 'SET_INC_VICTIMS';
export const SET_INC_PROPERTIES = 'SET_INC_PROPERTIES';
export const SET_INC_ARRESTEES = 'SET_INC_ARRESTEES';
export const SET_INC_SUSPECTS = 'SET_INC_SUSPECTS';
export const SET_INC_NARRATIVE = 'SET_INC_NARRATIVE';
export const SET_INC_RELATIONSHIPS = 'SET_INC_RELATIONSHIPS';
export const SET_INC_RELATIONS_DATA = 'SET_INC_RELATIONS_DATA';
export const SET_INC_EVIDENCE_ITEM = 'SET_INC_EVIDENCE_ITEM';
export const SET_INC_AFTER_ACTION_DETAILS_FORM = 'SET_INC_AFTER_ACTION_DETAILS_FORM';
export const SET_INC_AFTER_ACTION_REVIEW_FORM = 'SET_INC_AFTER_ACTION_REVIEW_FORM';
export const SET_INC_AFTER_ACTION_PARTY_FORM = 'SET_INC_AFTER_ACTION_PARTY_FORM';
export const SET_INC_EVIDENCE_CUSTODY = 'SET_INC_EVIDENCE_CUSTODY';
export const SET_INC_EVIDENCE_STORAGE = 'SET_INC_EVIDENCE_STORAGE';
export const SET_INC_OFFENSES_MP_BASIC = 'SET_INC_OFFENSES_MP_BASIC';
export const SET_INC_MP_RELATED_RECORDS = 'SET_INC_MP_RELATED_RECORDS';
export const SET_INC_OFFENSES_DV_SUSPECT = 'SET_INC_OFFENSES_DV_SUSPECT';
export const SET_INC_EVIDENCE_CUSTODY_NEW = 'SET_INC_EVIDENCE_CUSTODY_NEW';
export const SET_INC_EVIDENCE_STORAGE_NEW = 'SET_INC_EVIDENCE_STORAGE_NEW';
export const SET_INC_OFFENSES_RELATIONSHIP = 'SET_INC_OFFENSES_RELATIONSHIP';
export const SET_INC_EVIDENCE_DISPOSITION = 'SET_INC_EVIDENCE_DISPOSITION';
export const SET_INC_OFFENSES_DV_ADDITIONAL = 'SET_INC_OFFENSES_DV_ADDITIONAL';
export const SET_INC_OFFENSES_DV_VICTIM_SUBFORMS = 'SET_INC_OFFENSES_DV_VICTIM_SUBFORMS';
export const SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS = 'SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS';
export const SET_INC_ZONE_FORM = 'SET_INC_ZONE_FORM';
export const SET_INC_DISPOSITION_FORM = 'SET_INC_DISPOSITION_FORM';
export const SET_INC_TAG_FORM = 'SET_INC_TAG_FORM';
export const SET_INC_SCROLL_POSITION = 'SET_INC_SCROLL_POSITION';

export const SET_LIST_SUBJECTS = 'SET_LIST_SUBJECTS';
export const SET_LIST_VICTIMS = 'SET_LIST_VICTIMS';
export const SET_LIST_PROPERTIES = 'SET_LIST_PROPERTIES';
export const SET_LIST_PROP_OWNERS = 'SET_LIST_PROP_OWNERS';
export const SET_LIST_ARRESTEES = 'SET_LIST_ARRESTEES';
export const SET_LIST_SUSPECTS = 'SET_LIST_SUSPECTS';
export const SET_LIST_EVIDENCE_CUSTODY = 'SET_LIST_EVIDENCE_CUSTODY';
export const SET_LIST_EVIDENCE_STORAGE = 'SET_LIST_EVIDENCE_STORAGE';
export const SET_DIALOG_LIST_SUBJECTS = 'SET_DIALOG_LIST_SUBJECTS';
export const SET_DIALOG_LIST_VICTIMS = 'SET_DIALOG_LIST_VICTIMS';
export const SET_DIALOG_LIST_PROPERTIES = 'SET_DIALOG_LIST_PROPERTIES';
export const SET_DIALOG_LIST_ARRESTEES = 'SET_DIALOG_LIST_ARRESTEES';
export const SET_DIALOG_LIST_SUSPECTS = 'SET_DIALOG_LIST_SUSPECTS';
export const SET_DIALOG_LIST_EVIDENCES = 'SET_DIALOG_LIST_EVIDENCES';
export const SET_INC_DV_VICTIM_LIST = 'SET_INC_DV_VICTIM_LIST';
export const SET_INC_DV_SUSPECT_LIST = 'SET_INC_DV_SUSPECT_LIST';
export const SET_INC_DV_ADDITIONAL_LIST = 'SET_INC_DV_ADDITIONAL_LIST';

export const SET_SELECTED_EVIDENCE_CUSTODY = 'SET_SELECTED_EVIDENCE_CUSTODY';
export const SET_SELECTED_EVIDENCE_STORAGE = 'SET_SELECTED_EVIDENCE_STORAGE';
export const SET_SELECTED_DUPLICATE_DATA = 'SET_SELECTED_DUPLICATE_DATA';
export const SET_SELECTED_SUBJECT = 'SET_SELECTED_SUBJECT';
export const SET_SELECTED_VICTIM = 'SET_SELECTED_VICTIM';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const SET_SELECTED_PROP_OWNER = 'SET_SELECTED_PROP_OWNER';
export const SET_SELECTED_ARRESTEE = 'SET_SELECTED_ARRESTEE';
export const SET_SELECTED_INCIDENT_EVIDENCE = 'SET_SELECTED_INCIDENT_EVIDENCE';
export const SET_SELECTED_NARRATIVE = 'SET_SELECTED_NARRATIVE';
export const SET_SELECTED_ACTION_REPORT = 'SET_SELECTED_ACTION_REPORT';
export const SET_SELECTED_SUSPECT = 'SET_SELECTED_SUSPECT';
export const SET_SELECTED_INCIDENT = 'SET_SELECTED_INCIDENT';
export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';

export const SET_CURRENT_OFFENSE = 'SET_CURRENT_OFFENSE'; // To Persist current offense id
export const SET_SEARCH_KEYWORDS = 'SET_SEARCH_KEYWORDS';

export const SET_OFFENSE_VALIDATION_ERRORS = 'SET_OFFENSE_VALIDATION_ERRORS'; // for simpl-schema validation
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const SET_NIBRS_ERRORS = 'SET_NIBRS_ERRORS';

export const ADD_PARTIES = 'ADD_PARTIES';
export const ADD_ADDRESSES = 'ADD_ADDRESSES';
export const ADD_PROPERTIES = 'ADD_PROPERTIES';
export const ADD_OFFENSES = 'ADD_OFFENSES';

export const GET_ACTIVE_INCIDENTS = 'GET_ACTIVE_INCIDENTS';
export const GET_PARTIES = 'GET_PARTIES';
export const GET_ADDRESSESS = 'GET_ADDRESSESS';
export const GET_CAD_NOTES = 'GET_CAD_NOTES';
export const GET_OFFENSES = 'GET_OFFENSES';
export const GET_PROPERTIES = 'GET_PROPERTIES';
export const GET_EVIDENCES = 'GET_EVIDENCES';
export const GET_NARRATIVES = 'GET_NARRATIVES';
export const GET_AFTER_ACTION_REPORT = 'GET_AFTER_ACTION_REPORT';
export const GET_ENTITIES = 'GET_ENTITIES';
export const GET_DISPOSITIONS = 'GET_DISPOSITIONS';
export const GET_ZONES = 'GET_ZONES';
export const GET_ALERTS = 'GET_ALERTS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_TAGS = 'GET_TAGS';

export const REMOVE_INC_SUBJECTS = 'REMOVE_INC_SUBJECTS';
export const REMOVE_INC_VICTIMS = 'REMOVE_INC_VICTIMS';
export const REMOVE_INC_PROPERTIES = 'REMOVE_INC_PROPERTIES';
export const REMOVE_INC_ARRESTEES = 'REMOVE_INC_ARRESTEES';
export const REMOVE_INC_SUSPECTS = 'REMOVE_INC_SUSPECTS';

export const SET_FORM_TYPE = 'SET_FORM_TYPE';
export const SET_CARD_EXPANDED = 'SET_CARD_EXPANDED';
export const SET_NIBRS_DATA_LOADED = 'SET_NIBRS_DATA_LOADED';
/************************************/
/*** END Region: Action Types ******/
/**********************************/

/************************************/
/* Region Action Creator Functions */
/**********************************/
// start region: validation action creator functions
export const setNibrsDataLoaded = (value) => {
  return (dispatch) => {
    return dispatch({ type: SET_NIBRS_DATA_LOADED, payload: value });
  };
};

export const setFormType = (formType) => {
  return (dispatch) => {
    return dispatch({ type: SET_FORM_TYPE, payload: formType });
  };
};

export const setOffenseValidationErrors = (errors) => {
  return (dispatch) => {
    return dispatch({ type: SET_OFFENSE_VALIDATION_ERRORS, payload: errors });
  };
};

export const setValidationErrors = (errors) => {
  return (dispatch) => {
    return dispatch({ type: SET_VALIDATION_ERRORS, payload: errors });
  };
};

export const setNibrsErrors = (errors) => {
  return (dispatch) => {
    return dispatch({ type: SET_NIBRS_ERRORS, payload: errors });
  };
};
// end region: validation action creator functions

export const setSelectedIncident = (ptsIncidentId) => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    incState.ptsIncidentId = ptsIncidentId;
    return dispatch({ type: SET_SELECTED_INCIDENT, payload: ptsIncidentId });
  };
};

export const setEvent = (data) => {
  return async (dispatch) => {
    return dispatch({ type: SET_EVENT, payload: data });
  };
};

export const setCardExpanded = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const cardExpanded = incState.cardExpanded;
    return dispatch({ type: SET_CARD_EXPANDED, payload: !cardExpanded });
  };
};

export const setActiveIncidents = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_ACTIVE_INCIDENTS, payload: state });
  };
};

export const setCurrentOffense = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_CURRENT_OFFENSE, payload: state });
  };
};

export const updateCurrentIncident = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INCIDENT, payload: state });
  };
};

export const resetCurrentIncident = () => {
  return async (dispatch) => {
    return dispatch({
      type: RESET_INCIDENT,
      payload: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        isDirty: false,
        changes: false,
      },
    });
  };
};

export const updateIncAddressForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_ADDRESS, payload: state });
  };
};

export const updateIncScrollPosition = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_SCROLL_POSITION, payload: state });
  };
};

export const updateIncAddressIdentifiersForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_ADDRESS_ID, payload: state });
  };
};

export const updateIncCoordinatesForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_COORDINATES, payload: state });
  };
};

export const updateIncPartiesPersonForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_PERSON, payload: state });
  };
};

export const updateIncPartiesContactForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_CONTACT, payload: state });
  };
};

export const updateIncPartiesEmploymentForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_EMPLOYMENT, payload: state });
  };
};

export const updateIncPartiesOthersForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_OTHERS, payload: state });
  };
};

export const updateIncPartiesPlaceForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_PLACE, payload: state });
  };
};

export const updateIncPropertyItemForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_ITEM, payload: state });
  };
};

export const updateIncPropertyFirearmForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_FIREARM, payload: state });
  };
};

export const updateIncPropertyJewelryForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_JEWELRY, payload: state });
  };
};

export const updateIncPropertyStructureForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_STRUCTURE, payload: state });
  };
};

export const updateIncPropertySubstanceForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_SUBSTANCE, payload: state });
  };
};

export const updateIncPropertyVehicleDetailsForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_VEHICLE_DETAILS, payload: state });
  };
};

export const updateIncPropertyCredentialsAnalysisForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_CREDENTIALS_ANALYSIS, payload: state });
  };
};

export const updateIncOffensesOffenseForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_OFFENSE, payload: state });
  };
};

export const updateIncOffensesDVVictimForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_VICTIM, payload: state });
  };
};

export const updateIncOffensesDVVictimSubForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_VICTIM_SUBFORMS, payload: state });
  };
};

export const updateIncOffensesDVSuspectForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_SUSPECT, payload: state });
  };
};

export const updateIncOffensesDVSuspectSubForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS, payload: state });
  };
};

export const updateIncOffensesDVAdditionalForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_ADDITIONAL, payload: state });
  };
};

export const updateIncOffensesMPBasicForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_MP_BASIC, payload: state });
  };
};

export const updateIncMPRelatedRecords = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_MP_RELATED_RECORDS, payload: state });
  };
};

export const updateIncOffensesRelationshipForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_RELATIONSHIP, payload: state });
  };
};

export const updateIncOffensesPropertyOwnerForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_PROPERTY_OWNER, payload: state });
  };
};

export const updateIncAfterActionDetailsForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_AFTER_ACTION_DETAILS_FORM, payload: state });
  };
};

export const updateIncAfterActionReviewForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_AFTER_ACTION_REVIEW_FORM, payload: state });
  };
};

export const updateIncAfterActionPartyForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_AFTER_ACTION_PARTY_FORM, payload: state });
  };
};

export const updateIncEvidenceItemForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_ITEM, payload: state });
  };
};

export const updateIncEvidenceCustodyForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_CUSTODY, payload: state });
  };
};

export const updateIncEvidenceCustodyFormNew = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_CUSTODY_NEW, payload: state });
  };
};

export const updateIncEvidenceStorageForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_STORAGE, payload: state });
  };
};

export const updateIncEvidenceStorageFormNew = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_STORAGE_NEW, payload: state });
  };
};

export const updateIncEvidenceDispositionForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_DISPOSITION, payload: state });
  };
};

export const updateIncNarrativeForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_NARRATIVE, payload: state });
  };
};

export const updateIncZoneForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_ZONE_FORM, payload: state });
  };
};

export const updateIncDispositionForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_DISPOSITION_FORM, payload: state });
  };
};

export const updateIncTagForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_TAG_FORM, payload: state });
  };
};

// Set Lists Region
export const setListSubjects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_SUBJECTS, payload: list });
  };
};

export const setDialogListSubjects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_SUBJECTS, payload: list });
  };
};

export const setListSuspects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_SUSPECTS, payload: list });
  };
};

export const setDialogListSuspects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_SUSPECTS, payload: list });
  };
};

export const setListVictims = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_VICTIMS, payload: list });
  };
};

export const setDialogListVictims = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_VICTIMS, payload: list });
  };
};

export const setListProperties = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_PROPERTIES, payload: list });
  };
};

export const setDialogListProperties = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_PROPERTIES, payload: list });
  };
};

export const setDialogListEvidences = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_EVIDENCES, payload: list });
  };
};

export const setListPropertyOwners = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_PROP_OWNERS, payload: list });
  };
};

export const setListArrestees = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_ARRESTEES, payload: list });
  };
};

export const setDialogListArrestees = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_ARRESTEES, payload: list });
  };
};
// End Region

// Set Entity Region
export const setSelectedDuplicateData = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_DUPLICATE_DATA, payload: state });
  };
};

export const setSelectedSubject = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_SUBJECT, payload: state });
  };
};

export const setSelectedVictim = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_VICTIM, payload: state });
  };
};

export const setSelectedSuspect = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_SUSPECT, payload: state });
  };
};

export const setSelectedProperty = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_PROPERTY, payload: state });
  };
};

export const setSelectedPropertyOwner = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_PROP_OWNER, payload: state });
  };
};

export const setSelectedArrestee = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_ARRESTEE, payload: state });
  };
};

export const setSelectedEvidenceCustody = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_EVIDENCE_CUSTODY, payload: state });
  };
};

export const setSelectedEvidenceStorage = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_EVIDENCE_STORAGE, payload: state });
  };
};

export const setSelectedIncidentEvidence = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_INCIDENT_EVIDENCE, payload: state });
  };
};

export const setSelectedNarrative = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_NARRATIVE, payload: state });
  };
};

export const setSelectedActionReport = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_ACTION_REPORT, payload: state });
  };
};

export const setSelectedEntity = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_ENTITY, payload: state });
  };
};

export const setSearchKeyword = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_SEARCH_KEYWORDS, payload: data });
  };
};
// End Region

// Set Lists Details Region
export const updateIncOffenses = (offenses) => {
  return async (dispatch) => {
    return dispatch({
      type: ADD_OFFENSES,
      payload: offenses,
    });
  };
};
export const setIncSubjectsDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_SUBJECTS, payload: state });
  };
};

export const setIncDVVictimList = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_DV_VICTIM_LIST, payload: state });
  };
};

export const setIncDVSuspectList = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_DV_SUSPECT_LIST, payload: state });
  };
};

export const setIncDVAdditionalList = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_DV_ADDITIONAL_LIST, payload: state });
  };
};

export const updateIncSubjectsDetails = (state) => {
  const incState = store.store.getState().incident;
  let { incSubjects, selectedSubject, currentOffense, parties, incRelationships } = incState;

  let foundSubject = false;

  if (incSubjects.length > 0) {
    incSubjects.some((s) => {
      if (
        (typeof selectedSubject === 'string' &&
          s.incSubjectDetails.tempPartyId === selectedSubject &&
          s.incSubjectDetails.ptsOffenseId === currentOffense) ||
        (s.incSubjectDetails.ptsIncPersonId === selectedSubject &&
          s.incSubjectDetails.ptsOffenseId === currentOffense)
      ) {
        s.incSubjectDetails = {
          ...s.incSubjectDetails,
          changes: true,
          values: {
            ...s.incSubjectDetails.values,
            ...state.values,
          },
        };
        foundSubject = true;
      }
      return foundSubject;
    });
  }

  const newSubjectPerson = parties.find((p) => p.ptsIncPersonId === state.ptsIncPersonId);
  if (!foundSubject) {
    if (newSubjectPerson.ptsPersonId === 0) {
      const offenseVictims = incState.incVictims.filter(
        (v) => v.incVictimDetails.ptsOffenseId === currentOffense
      );

      offenseVictims.forEach((v) => {
        if (v.incVictimDetails.ptsIncPersonId) {
          incRelationships.push({
            incRelationshipDetails: {
              changes: true,
              ptsOffenseId: currentOffense,
              values: {
                relationship: {
                  offenderId: newSubjectPerson.ptsIncPersonId,
                  victimId: v.incVictimDetails.ptsIncPersonId,
                  relationship: 'Relationship Unknown',
                },
              },
            },
          });
        }
      });

      setIncRelationshipDetails(incRelationships);
    }
    incSubjects.push({
      incSubjectDetails: { changes: true, ...state, ptsPersonId: newSubjectPerson.ptsPersonId },
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_SUBJECTS,
      payload: incSubjects,
    });
  };
};

export const removeIncSubjectDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    let {
      incSubjects,
      incRelationships,
      offenses,
      currentOffense,
      selectedSubject,
      incident,
      incRelations,
    } = incState;

    let remainingRelationships = incRelationships;
    let sameSubjects = [];

    /* Checking if the subjectList contains the same person */
    sameSubjects = incSubjects.filter(
      (s) =>
        s.incSubjectDetails.ptsIncPersonId === selectedSubject &&
        s.incSubjectDetails.ptsOffenseId !== currentOffense
    );

    const deletedSubject = incSubjects.find(
      (s) =>
        s.incSubjectDetails.ptsIncPersonId === selectedSubject &&
        s.incSubjectDetails.ptsOffenseId === currentOffense
    );

    incSubjects = incSubjects.filter((s) =>
      typeof selectedSubject === 'string'
        ? s.incSubjectDetails.tempPartyId !== selectedSubject ||
          s.incSubjectDetails.ptsOffenseId !== currentOffense
        : s.incSubjectDetails.ptsIncPersonId !== selectedSubject ||
          s.incSubjectDetails.ptsOffenseId !== currentOffense
    );

    const allRelations = incRelations;
    /* Not excluding relationship with the same victim person */ if (sameSubjects.length < 1) {
      remainingRelationships = incRelationships.filter(
        (r) => r.incRelationshipDetails.values.relationship.offenderId !== selectedSubject
        //  ||
        // r.incRelationshipDetails.ptsOffenseId !== currentOffense
      );
    }

    if (deletedSubject && deletedSubject.incSubjectDetails.ptsSubjectId) {
      const { ptsSubjectId, ptsOffenseId } = deletedSubject.incSubjectDetails;
      const index = offenses.findIndex(
        (offense) => offense.offenseDetails.ptsOffenseId === ptsOffenseId
      );

      const subjectIds = offenses[index].offenseDetails.values.subjectIds ?? [];
      offenses[index].offenseDetails.values.subjectIds = subjectIds.filter(
        (id) => id !== ptsSubjectId
      );

      // const relations = offenses[index].offenseDetails.values.relations ?? [];
      // offenses[index].offenseDetails.values.relations = relations.filter(
      //   (relation) => relation.ptsSubjectID !== ptsSubjectId
      // );

      dispatch({ type: SET_INC_RELATIONSHIPS, payload: remainingRelationships });
      dispatch({ type: GET_OFFENSES, payload: [...offenses] });

      // remove relationships that are associated with the deleted subject:
      removeSubjectRelationships(ptsSubjectId, ptsOffenseId, allRelations, dispatch);

      // remove the subject
      await removeEntity('incsubject', {
        ptsSubjectId: deletedSubject.incSubjectDetails.ptsSubjectId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    }

    dispatch({
      type: SET_INC_SUBJECTS,
      payload: [...incSubjects],
    });

    await saveRelationships(incident.ptsIncidentId, 0, dispatch);

    dispatch(nibrsRulesValidation());
  };
};

export const removeOffensesAllIncSubjectsDetails = async (dispatch) => {
  try {
    const incState = store.store.getState().incident;
    let {
      incSubjects,
      incRelationships,
      offenses,
      currentOffense,
      selectedSubject,
      incident,
    } = incState;

    let remainingRelationships = incRelationships;
    let sameSubjects = [];

    /* Checking if the subjectList contains the same person */
    sameSubjects = incSubjects.filter(
      (s) =>
        s.incSubjectDetails.ptsIncPersonId === selectedSubject &&
        s.incSubjectDetails.ptsOffenseId !== currentOffense
    );

    const deletedSubject = incSubjects.find(
      (s) =>
        s.incSubjectDetails.ptsIncPersonId === selectedSubject &&
        s.incSubjectDetails.ptsOffenseId === currentOffense
    );

    incSubjects = incSubjects.filter((s) =>
      typeof selectedSubject === 'string'
        ? s.incSubjectDetails.tempPartyId !== selectedSubject ||
          s.incSubjectDetails.ptsOffenseId !== currentOffense
        : s.incSubjectDetails.ptsIncPersonId !== selectedSubject ||
          s.incSubjectDetails.ptsOffenseId !== currentOffense
    );

    /* Not excluding relationship with the same victim person */
    if (sameSubjects.length < 1) {
      remainingRelationships = incRelationships.filter(
        (r) => r.incRelationshipDetails.values.relationship.offenderId !== selectedSubject
        //  ||
        // r.incRelationshipDetails.ptsOffenseId !== currentOffense
      );
    }

    if (deletedSubject && deletedSubject.incSubjectDetails.ptsSubjectId) {
      const { ptsSubjectId, ptsOffenseId } = deletedSubject.incSubjectDetails;
      const index = offenses.findIndex(
        (offense) => offense.offenseDetails.ptsOffenseId === ptsOffenseId
      );

      const subjectIds = offenses[index].offenseDetails.values.subjectIds ?? [];
      offenses[index].offenseDetails.values.subjectIds = subjectIds.filter(
        (id) => id !== ptsSubjectId
      );

      // const relations = offenses[index].offenseDetails.values.relations ?? [];
      // offenses[index].offenseDetails.values.relations = relations.filter(
      //   (relation) => relation.ptsSubjectID !== ptsSubjectId
      // );

      dispatch({ type: SET_INC_RELATIONSHIPS, payload: remainingRelationships });
      dispatch({ type: GET_OFFENSES, payload: [...offenses] });
    }

    dispatch({
      type: SET_INC_SUBJECTS,
      payload: [...incSubjects],
    });
  } catch (error) {
    console.log('error ', error);
  }
};

export const setIncVictimsDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_VICTIMS, payload: state });
  };
};

export const updateIncVictimsDetails = (state) => {
  const incState = store.store.getState().incident;
  let { incVictims, currentOffense, selectedVictim, incSubjects, incRelationships } = incState;

  let foundVictim = false;

  incVictims.some((s) => {
    if (
      (typeof selectedVictim === 'string' &&
        s.incVictimDetails.tempPartyId === selectedVictim &&
        s.incVictimDetails.ptsOffenseId === currentOffense) ||
      (s.incVictimDetails.ptsIncPersonId === selectedVictim &&
        s.incVictimDetails.ptsOffenseId === currentOffense) ||
      (s.incVictimDetails.ptsIncPlaceId === selectedVictim &&
        s.incVictimDetails.ptsOffenseId === currentOffense)
    ) {
      s.incVictimDetails = {
        ...s.incVictimDetails,
        changes: true,
        values: {
          ...s.incVictimDetails.values,
          ...state.values,
        },
      };
      foundVictim = true;
    }
    return foundVictim;
  });

  if (!foundVictim) {
    const missingPersonSubjects = incSubjects.filter(
      (p) =>
        p.incSubjectDetails.ptsPersonId === 0 && p.incSubjectDetails.ptsOffenseId === currentOffense
    );
    if (missingPersonSubjects.length > 0 && state.ptsIncPersonId) {
      missingPersonSubjects.forEach((p) => {
        incRelationships.push({
          incRelationshipDetails: {
            changes: true,
            ptsOffenseId: currentOffense,
            values: {
              relationship: {
                offenderId: p.incSubjectDetails.ptsIncPersonId,
                victimId: state.ptsIncPersonId,
                relationship: getDataOfDictionaryOptions(
                  'codeVictimSubjectRelationship',
                  'Category',
                  'Code',
                  'RU'
                ),
              },
            },
          },
        });
      });

      setIncRelationshipDetails(incRelationships);
    }

    incVictims.push({
      incVictimDetails: { changes: true, ...state },
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_VICTIMS,
      payload: incVictims,
    });
  };
};

export const removeIncVictimDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    let {
      offenses,
      currentOffense,
      selectedVictim,
      incVictims,
      incRelationships,
      incRelations,
      incident,
      dvSuspects,
      dvVictims,
    } = incState;

    let remainingRelationships = incRelationships;
    let sameVictims = [];
    /* Checking if the victimList contains the same person */
    sameVictims = incVictims.filter(
      (v) =>
        (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) ===
          selectedVictim && v.incVictimDetails.ptsOffenseId !== currentOffense
    );

    const deletedVictim = incVictims.find(
      (v) =>
        (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) ===
          selectedVictim && v.incVictimDetails.ptsOffenseId === currentOffense
    );

    const filteredDvSuspects = dvSuspects.filter(
      (s) =>
        s.incSuspectDetails.ptsVictimPersonId != selectedVictim &&
        s.incSuspectDetails.ptsOffenseId != currentOffense
    );

    const filteredDvVictims = dvVictims.filter(
      (v) => v.ptsIncPersonId != selectedVictim && v.ptsOffenseId != currentOffense
    );

    incVictims = incVictims.filter((v) =>
      typeof selectedVictim === 'string'
        ? v.incVictimDetails.tempPartyId !== selectedVictim ||
          v.incVictimDetails.ptsOffenseId !== currentOffense
        : (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) !==
            selectedVictim || v.incVictimDetails.ptsOffenseId !== currentOffense
    );

    const allRelations = incRelations;
    /* Not excluding relationship with the same victim person */
    if (sameVictims.length < 1) {
      remainingRelationships = incRelationships.filter(
        (r) => r.incRelationshipDetails.values.relationship.victimId !== selectedVictim
        //  ||
        // r.incRelationshipDetails.ptsOffenseId !== currentOffense
      );
    }

    if (deletedVictim && deletedVictim.incVictimDetails.ptsVictimId) {
      const { ptsVictimId, ptsOffenseId } = deletedVictim.incVictimDetails;
      const index = offenses.findIndex(
        (offense) => offense.offenseDetails.ptsOffenseId === ptsOffenseId
      );
      const victimIds = offenses[index].offenseDetails.values.victimIds ?? [];
      offenses[index].offenseDetails.values.victimIds = victimIds.filter(
        (id) => id !== ptsVictimId
      );

      // const relations = offenses[index].offenseDetails.values.relations ?? [];
      // offenses[index].offenseDetails.values.relations = relations.filter(
      //   (relation) => relation.ptsVictimID !== ptsVictimId
      // );

      dispatch({ type: SET_INC_RELATIONSHIPS, payload: remainingRelationships });
      dispatch({ type: GET_OFFENSES, payload: [...offenses] });

      // remove relationships that are associated with this victim:
      removeVictimRelationships(ptsVictimId, ptsOffenseId, allRelations, dispatch);

      // remove the victim:
      await removeEntity('incvictim', {
        ptsVictimId: deletedVictim.incVictimDetails.ptsVictimId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    }

    dispatch({
      type: SET_INC_VICTIMS,
      payload: [...incVictims],
    });

    await saveRelationships(incident.ptsIncidentId, 0, dispatch);

    dispatch({ type: SET_INC_DV_SUSPECT_LIST, payload: filteredDvSuspects });
    dispatch({ type: SET_INC_DV_VICTIM_LIST, payload: filteredDvVictims });

    dispatch(nibrsRulesValidation());
  };
};

export const removeOffensesAllIncVictimDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    let {
      offenses,
      currentOffense,
      selectedVictim,
      incVictims,
      incRelationships,
      incident,
      dvSuspects,
      dvVictims,
    } = incState;

    let remainingRelationships = incRelationships;
    let sameVictims = [];
    /* Checking if the victimList contains the same person */
    sameVictims = incVictims.filter(
      (v) =>
        (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) ===
          selectedVictim && v.incVictimDetails.ptsOffenseId !== currentOffense
    );

    const deletedVictim = incVictims.find(
      (v) =>
        (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) ===
          selectedVictim && v.incVictimDetails.ptsOffenseId === currentOffense
    );

    const filteredDvSuspects = dvSuspects.filter(
      (s) =>
        s.incSuspectDetails.ptsVictimPersonId != selectedVictim &&
        s.incSuspectDetails.ptsOffenseId != currentOffense
    );

    const filteredDvVictims = dvVictims.filter(
      (v) => v.ptsIncPersonId != selectedVictim && v.ptsOffenseId != currentOffense
    );

    incVictims = incVictims.filter((v) =>
      typeof selectedVictim === 'string'
        ? v.incVictimDetails.tempPartyId !== selectedVictim ||
          v.incVictimDetails.ptsOffenseId !== currentOffense
        : (v.incVictimDetails.ptsIncPersonId || v.incVictimDetails.ptsIncPlaceId) !==
            selectedVictim || v.incVictimDetails.ptsOffenseId !== currentOffense
    );

    /* Not excluding relationship with the same victim person */
    if (sameVictims.length < 1) {
      remainingRelationships = incRelationships.filter(
        (r) => r.incRelationshipDetails.values.relationship.victimId !== selectedVictim
        //  ||
        // r.incRelationshipDetails.ptsOffenseId !== currentOffense
      );
    }

    if (deletedVictim && deletedVictim.incVictimDetails.ptsVictimId) {
      const { ptsVictimId, ptsOffenseId } = deletedVictim.incVictimDetails;
      const index = offenses.findIndex(
        (offense) => offense.offenseDetails.ptsOffenseId === ptsOffenseId
      );
      const victimIds = offenses[index].offenseDetails.values.victimIds ?? [];
      offenses[index].offenseDetails.values.victimIds = victimIds.filter(
        (id) => id !== ptsVictimId
      );

      // const relations = offenses[index].offenseDetails.values.relations ?? [];
      // offenses[index].offenseDetails.values.relations = relations.filter(
      //   (relation) => relation.ptsVictimID !== ptsVictimId
      // );

      dispatch({ type: SET_INC_RELATIONSHIPS, payload: remainingRelationships });
      dispatch({ type: GET_OFFENSES, payload: [...offenses] });
    }

    dispatch({ type: SET_INC_DV_SUSPECT_LIST, payload: filteredDvSuspects });
    dispatch({ type: SET_INC_DV_VICTIM_LIST, payload: filteredDvVictims });

    return dispatch({
      type: SET_INC_VICTIMS,
      payload: [...incVictims],
    });
  };
};

export const setIncPropertyDetails = (incProperties) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_PROPERTIES, payload: incProperties });
  };
};

export const updateIncPropertyDetails = (state) => {
  const incState = store.store.getState().incident;
  let { incProperties, selectedProperty, currentOffense } = incState;

  let foundProperty = false;

  incProperties.forEach((incProperty) => {
    if (
      (typeof selectedProperty === 'string' &&
        incProperty.incPropertyDetails.tempPropId === selectedProperty &&
        incProperty.incPropertyDetails.ptsOffenseId === currentOffense) ||
      (incProperty.incPropertyDetails.incIncidentPropertyId === selectedProperty &&
        incProperty.incPropertyDetails.ptsOffenseId === currentOffense)
    ) {
      incProperty.incPropertyDetails = {
        ...incProperty.incPropertyDetails,
        changes: true,
        values: {
          ...incProperty.incPropertyDetails.values,
          ...state.values,
        },
      };

      foundProperty = true;
    }
  });

  if (!foundProperty) {
    incProperties.push({
      incPropertyDetails: { changes: true, ...state },
    });
  }

  return async (dispatch) => {
    dispatch(setListBodyCells('properties')); // Include the latest status in Property body state
    return dispatch({
      type: SET_INC_PROPERTIES,
      payload: incProperties,
    });
  };
};

export const removeIncPropertyDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    let { incProperties, selectedProperty, currentOffense, incident } = incState;

    const deletedProperty = incProperties.find(
      (v) =>
        v.incPropertyDetails.incIncidentPropertyId === selectedProperty &&
        v.incPropertyDetails.ptsOffenseId === currentOffense
    );

    incProperties = incProperties.filter((s) =>
      typeof selectedProperty === 'string'
        ? s.incPropertyDetails.tempPropId !== selectedProperty ||
          s.incPropertyDetails.ptsOffenseId !== currentOffense
        : s.incPropertyDetails.incIncidentPropertyId !== selectedProperty ||
          s.incPropertyDetails.ptsOffenseId !== currentOffense
    );

    if (deletedProperty && deletedProperty.incPropertyDetails.ptsPropertyId) {
      await removeEntity('incproperty', {
        ptsPropertyId: deletedProperty.incPropertyDetails.ptsPropertyId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    }

    removePropertiesAvailableOffenses(deletedProperty.incPropertyDetails, dispatch);

    dispatch({
      type: SET_INC_PROPERTIES,
      payload: incProperties,
    });

    dispatch(nibrsRulesValidation());
  };
};

export const removeOffensesAllIncPropertyDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    let { incProperties, selectedProperty, currentOffense, incident } = incState;

    incProperties = incProperties.filter((s) =>
      typeof selectedProperty === 'string'
        ? s.incPropertyDetails.tempPropId !== selectedProperty ||
          s.incPropertyDetails.ptsOffenseId !== currentOffense
        : s.incPropertyDetails.incIncidentPropertyId !== selectedProperty ||
          s.incPropertyDetails.ptsOffenseId !== currentOffense
    );

    return dispatch({
      type: SET_INC_PROPERTIES,
      payload: incProperties,
    });
  };
};

export const setIncArresteeDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_INC_ARRESTEES, payload: data });
  };
};

export const updateIncArresteeDetails = (state, newData) => {
  const incState = store.store.getState().incident;
  let foundArrestee = false;
  if (incState.incArrestees.length > 0) {
    incState.incArrestees.some((s) => {
      if (
        s.incArresteeDetails.ptsIncPersonId === incState.selectedArrestee &&
        s.incArresteeDetails.ptsOffenseId === incState.currentOffense
      ) {
        s.incArresteeDetails = {
          ...s.incArresteeDetails,
          changes: true,
          values: {
            ...s.incArresteeDetails.values,
            ...state.values,
          },
        };
        foundArrestee = true;
      }
      return foundArrestee;
    });
  }

  if (!foundArrestee) {
    incState.incArrestees.push({
      incArresteeDetails: { changes: true, ...state },
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_ARRESTEES,
      payload: incState.incArrestees,
    });
  };
};

export const offenseRemovedUpdateAllIncData = async (removedOffenseId, dispatch) => {
  try {
    const {
      offenses,
      incSubjects,
      incVictims,
      incProperties,
      incArrestees,
      incident,
      incRelationships,
      incRelations,
    } = store.store.getState().incident;

    let removableSubjectsData = [];
    let removableVictimsData = [];
    let removableArresteesData = [];
    let removablePropertiesData = [];

    incSubjects.forEach((incS) => {
      if (incS.incSubjectDetails.ptsOffenseId === removedOffenseId) {
        // const relations = offenses.find(
        //   (offense) => offense.offenseDetails.ptsOffenseId === incS.incSubjectDetails.ptsOffenseId
        // )?.offenseDetails?.values?.relations;

        removableSubjectsData.push({
          ptsSubjectId: incS.incSubjectDetails.ptsSubjectId,
          ptsOffenseId: incS.incSubjectDetails.ptsOffenseId,
          ptsIncPersonId: incS.incSubjectDetails.ptsIncPersonId,
          // relations: relations ?? [],
        });

        dispatch(setCurrentOffense(incS.incSubjectDetails.ptsOffenseId));
        dispatch(setSelectedSubject(incS.incSubjectDetails.ptsIncPersonId));
        removeOffensesAllIncSubjectsDetails(dispatch);
      }
    });

    incVictims.forEach((incV) => {
      if (incV.incVictimDetails.ptsOffenseId === removedOffenseId) {
        // const relations = offenses.find(
        //   (offense) => offense.offenseDetails.ptsOffenseId === incV.incVictimDetails.ptsOffenseId
        // )?.offenseDetails?.values?.relations;

        removableVictimsData.push({
          ptsVictimId: incV.incVictimDetails.ptsVictimId,
          ptsOffenseId: incV.incVictimDetails.ptsOffenseId,
          ptsIncPersonId: incV.incVictimDetails.ptsIncPersonId,
          ptsIncPlaceId: incV.incVictimDetails.ptsIncPlaceId,
          // relations: relations ?? [],
        });

        dispatch(setCurrentOffense(incV.incVictimDetails.ptsOffenseId));
        dispatch(
          setSelectedVictim(
            incV.incVictimDetails.ptsIncPersonId || incV.incVictimDetails.ptsIncPlaceId
          )
        );
        dispatch(removeOffensesAllIncVictimDetails());
      }
    });

    incProperties.forEach((incP) => {
      if (incP.incPropertyDetails.ptsOffenseId === removedOffenseId) {
        removablePropertiesData.push({
          ptsPropertyId: incP.incPropertyDetails.ptsPropertyId,
          ptsOffenseId: incP.incPropertyDetails.ptsOffenseId,
          incIncidentPropertyId: incP.incPropertyDetails.incIncidentPropertyId,
        });

        dispatch(setSelectedProperty(incP.incPropertyDetails.incIncidentPropertyId));
        dispatch(setCurrentOffense(incP.incPropertyDetails.ptsOffenseId));
        dispatch(removeOffensesAllIncPropertyDetails());
      }
    });

    incArrestees.forEach((incA) => {
      if (incA.incArresteeDetails.ptsOffenseId === removedOffenseId) {
        removableArresteesData.push({
          ptsArresteeId: incA.incArresteeDetails.ptsArresteeId,
          ptsOffenseId: incA.incArresteeDetails.ptsOffenseId,
          ptsIncPersonId: incA.incArresteeDetails.ptsIncPersonId,
        });

        dispatch(setCurrentOffense(incA.incArresteeDetails.ptsOffenseId));
        dispatch(setSelectedArrestee(incA.incArresteeDetails.ptsIncPersonId));
        dispatch(removeOffensesIncArresteeDetails());
      }
    });

    removableSubjectsData.forEach(async (s) => {
      removeSubjectRelationships(s.ptsSubjectId, s.ptsOffenseId, incRelations, dispatch);

      await removeEntity('incsubject', {
        ptsSubjectId: s.ptsSubjectId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    });

    removableVictimsData.forEach(async (v) => {
      removeVictimRelationships(v.ptsVictimId, v.ptsOffenseId, incRelations, dispatch);

      await removeEntity('incvictim', {
        ptsVictimId: v.ptsVictimId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    });

    removablePropertiesData.forEach(async (p) => {
      await removeEntity('incproperty', {
        ptsPropertyId: p.ptsPropertyId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    });

    removableArresteesData.forEach(async (a) => {
      await removeEntity('incarrestee', {
        ptsArresteeId: a.ptsArresteeId,
        ptsIncidentId: incident.ptsIncidentId,
      });
    });

    await saveRelationships(incident.ptsIncidentId, 0, dispatch);
  } catch (error) {
    console.log('error ', error);
  }
};

export const updateAllPartiesAvailableOffenses = (removedOffenseId, dispatch) => {
  try {
    //update all parties redux
    const parties = store.store.getState().incident.parties;
    const client = store.store.getState().websocket.websocket;
    let updatePartiesDB = [];

    const updatedParties = parties.map((party) => {
      if (party.personDetail.values.availableOffenses) {
        const prevAvailableOffenses = party.personDetail.values.availableOffenses;
        let updatedAvailableOffenses = prevAvailableOffenses.filter(
          (PAO) => PAO !== removedOffenseId
        );

        if (prevAvailableOffenses.length !== updatedAvailableOffenses.length) {
          updatedAvailableOffenses = updatedAvailableOffenses.length
            ? updatedAvailableOffenses
            : null;

          updatePartiesDB.push({
            ptsIncPersonId: party.ptsIncPersonId,
            updatedAvailableOffenses: updatedAvailableOffenses
              ? JSON.stringify(updatedAvailableOffenses)
              : updatedAvailableOffenses,
          });
        }
        party.personDetail.values.availableOffenses = updatedAvailableOffenses;
      }

      return party;
    });

    dispatch({
      type: ADD_PARTIES,
      payload: updatedParties,
    });

    //update all changed parties DB
    if (!client) return;
    const service = client.service('incincidentpartyperson');
    service.timeout = 20000;

    updatePartiesDB.forEach(async (party) => {
      await service.patch(party.ptsIncPersonId, {
        AvailableOffenses: party.updatedAvailableOffenses,
      });
    });
  } catch (error) {
    console.log('error ', error);
  }
};

export const removeIncArresteeDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;

    let { offenses } = incState;

    const deletedArrestee = incState.incArrestees.find(
      (v) =>
        v.incArresteeDetails.ptsIncPersonId === incState.selectedArrestee &&
        v.incArresteeDetails.ptsOffenseId === incState.currentOffense
    );

    incState.incArrestees = incState.incArrestees.filter((s) =>
      typeof incState.selectedArrestee === 'string'
        ? s.incArresteeDetails.tempPartyId !== incState.selectedArrestee ||
          s.incArresteeDetails.ptsOffenseId !== incState.currentOffense
        : s.incArresteeDetails.ptsIncPersonId !== incState.selectedArrestee ||
          s.incArresteeDetails.ptsOffenseId !== incState.currentOffense
    );

    if (deletedArrestee && deletedArrestee.incArresteeDetails.ptsArresteeId) {
      const index = offenses.findIndex(
        (offense) =>
          offense.offenseDetails.ptsOffenseId === deletedArrestee.incArresteeDetails.ptsOffenseId
      );
      const arresteeIds = offenses[index].offenseDetails.values.arresteeIds ?? [];
      const updatedArresteeIds = arresteeIds.filter(
        (id) => id !== deletedArrestee.incArresteeDetails.ptsArresteeId
      );
      offenses[index].offenseDetails.values.arresteeIds = updatedArresteeIds.length
        ? updatedArresteeIds
        : null;

      dispatch({ type: GET_OFFENSES, payload: [...offenses] });

      dispatch(setListBodyCells('offenses'));

      await removeEntity('incarrestee', {
        ptsArresteeId: deletedArrestee.incArresteeDetails.ptsArresteeId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    removePartiesAvailableOffenses(deletedArrestee.incArresteeDetails, dispatch);

    dispatch({
      type: REMOVE_INC_ARRESTEES,
      payload: incState.incArrestees,
    });

    dispatch(nibrsRulesValidation());

    if (
      incState?.incArrestees?.length === 0 &&
      getCategoryOfCode(codeIBRStatus, incState.incident.values.ibrStatus) === 'cbyarrest'
    ) {
      let defaultIBRStatus = codeIBRStatus.find((s) => s.IsDefault === true);

      if (defaultIBRStatus) {
        incState.incident.values.ibrStatus = getCodeofCategory(
          codeIBRStatus,
          defaultIBRStatus?.Category
        );

        await upsertIncident(incState.incident);
      }
    }
  };
};

export const removeOffensesIncArresteeDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;

    let { offenses } = incState;

    const deletedArrestee = incState.incArrestees.find(
      (v) =>
        v.incArresteeDetails.ptsIncPersonId === incState.selectedArrestee &&
        v.incArresteeDetails.ptsOffenseId === incState.currentOffense
    );

    incState.incArrestees = incState.incArrestees.filter((s) =>
      typeof incState.selectedArrestee === 'string'
        ? s.incArresteeDetails.tempPartyId !== incState.selectedArrestee ||
          s.incArresteeDetails.ptsOffenseId !== incState.currentOffense
        : s.incArresteeDetails.ptsIncPersonId !== incState.selectedArrestee ||
          s.incArresteeDetails.ptsOffenseId !== incState.currentOffense
    );

    if (deletedArrestee && deletedArrestee.incArresteeDetails.ptsArresteeId) {
      const index = offenses.findIndex(
        (offense) =>
          offense.offenseDetails.ptsOffenseId === deletedArrestee.incArresteeDetails.ptsOffenseId
      );
      const arresteeIds = offenses[index].offenseDetails.values.arresteeIds ?? [];
      const updatedArresteeIds = arresteeIds.filter(
        (id) => id !== deletedArrestee.incArresteeDetails.ptsArresteeId
      );
      offenses[index].offenseDetails.values.arresteeIds = updatedArresteeIds.length
        ? updatedArresteeIds
        : null;

      dispatch({ type: GET_OFFENSES, payload: [...offenses] });

      dispatch(setListBodyCells('offenses'));
    }

    if (
      incState?.incArrestees?.length === 0 &&
      getCategoryOfCode(codeIBRStatus, incState.incident.values.ibrStatus) === 'cbyarrest'
    ) {
      let defaultIBRStatus = codeIBRStatus.find((s) => s.IsDefault === true);

      if (defaultIBRStatus) {
        incState.incident.values.ibrStatus = getCodeofCategory(
          codeIBRStatus,
          defaultIBRStatus?.Category
        );

        await upsertIncident(incState.incident);
      }
    }

    return dispatch({
      type: REMOVE_INC_ARRESTEES,
      payload: incState.incArrestees,
    });
  };
};

export const updateIncSuspectDetails = (state, newData) => {
  const incState = store.store.getState().incident;

  if (newData) {
    incState.dvSuspects.push({
      incSuspectDetails: { changes: true, ...state },
    });
  } else {
    let foundSuspect = false;
    incState.dvSuspects.map((s) => {
      if (
        typeof incState.selectedSuspect === 'string' &&
        s.incSuspectDetails.tempPartyId === incState.selectedSuspect
      ) {
        foundSuspect = true;
      } else if (s.incSuspectDetails.ptsIncPersonId === incState.selectedSuspect) {
        foundSuspect = true;
      }

      if (foundSuspect) {
        s.incSuspectDetails = {
          ...s.incSuspectDetails,
          changes: true,
          values: {
            ...s.incSuspectDetails.values,
            ...state.values,
          },
        };
        foundSuspect = false;
      }
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_SUSPECTS,
      payload: incState.dvSuspects,
    });
  };
};

export const removeIncSuspectDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    let { dvSuspects } = incState;
    const deletedSuspect = dvSuspects.find(
      (v) =>
        v.incSuspectDetails.ptsIncPersonId === incState.selectedSuspect &&
        v.incSuspectDetails.ptsOffenseId === incState.currentOffense
    );

    dvSuspects = dvSuspects.filter((s) =>
      typeof incState.selectedSuspect === 'string'
        ? !(
            s.incSuspectDetails.tempPartyId === incState.selectedSuspect &&
            s.incSuspectDetails.ptsOffenseId === incState.currentOffense
          )
        : !(
            s.incSuspectDetails.ptsIncPersonId === incState.selectedSuspect &&
            s.incSuspectDetails.ptsOffenseId === incState.currentOffense
          )
    );

    if (deletedSuspect && deletedSuspect.incSuspectDetails.ptsDVSuspectId) {
      await removeEntity('incdvsuspect', {
        ptsDVSuspectId: deletedSuspect.incSuspectDetails.ptsDVSuspectId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    dispatch({
      type: SET_INC_DV_SUSPECT_LIST,
      payload: dvSuspects,
    });

    return dispatch({
      type: REMOVE_INC_SUSPECTS,
      payload: incState.dvSuspects,
    });
  };
};

export const setIncRelationshipDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_RELATIONSHIPS, payload: state });
  };
};

export const setIncRelationsData = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_RELATIONS_DATA, payload: state });
  };
};

export const setIncEvidenceStorageDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_LIST_EVIDENCE_STORAGE, payload: state });
  };
};

export const updateIncEvidenceStorageDetails = (state) => {
  const incState = store.store.getState().incident;

  let found = false;
  incState.incEvidenceStorage.map((s) => {
    if (
      typeof incState.selectedEvidenceStorage === 'string' &&
      s.incEvidenceStorageDetails.tempEvidenceStorageId === incState.selectedEvidenceStorage
    ) {
      found = true;
    } else if (
      s.incEvidenceStorageDetails.ptsEvidenceStorageId === incState.selectedEvidenceStorage
    ) {
      found = true;
    }
    if (found) {
      s.incEvidenceStorageDetails = {
        ...s.incEvidenceStorageDetails,
        changes: true,
        values: {
          ...s.incEvidenceStorageDetails.values,
          ...state.values,
        },
      };
      found = false;
    }
  });
  return async (dispatch) => {
    return dispatch({
      type: SET_LIST_EVIDENCE_STORAGE,
      payload: incState.incEvidenceStorage,
    });
  };
};

export const removeIncEvidenceStorageDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedStorage = incState.incEvidenceStorage.find(
      (v) => v.incEvidenceStorageDetails.ptsEvidenceStorageId === incState.selectedEvidenceStorage
    );

    incState.incEvidenceStorage = incState.incEvidenceStorage.filter((v) =>
      typeof incState.selectedEvidenceStorage === 'string'
        ? v.incEvidenceStorageDetails.tempEvidenceStorageId !== incState.selectedEvidenceStorage
        : v.incEvidenceStorageDetails.ptsEvidenceStorageId !== incState.selectedEvidenceStorage
    );

    if (deletedStorage && deletedStorage.incEvidenceStorageDetails.ptsEvidenceStorageId) {
      await removeEntity('incevidencestorage', {
        ptsEvidenceStorageId: deletedStorage.incEvidenceStorageDetails.ptsEvidenceStorageId,
        ptsIncidentId: incState.evidenceItemForm.ptsIncidentId ?? incState.ptsIncidentId,
      });
    }

    return dispatch({
      type: SET_LIST_EVIDENCE_STORAGE,
      payload: incState.incEvidenceStorage,
    });
  };
};

export const setIncEvidenceCustodyDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_LIST_EVIDENCE_CUSTODY, payload: state });
  };
};

export const updateIncEvidenceCustodyDetails = (state) => {
  const incState = store.store.getState().incident;

  let found = false;
  incState.incEvidenceCustody.map((s) => {
    if (
      typeof incState.selectedEvidenceCustody === 'string' &&
      s.incEvidenceCustodyDetails.tempEvidenceCustodyId === incState.selectedEvidenceCustody
    ) {
      found = true;
    } else if (
      s.incEvidenceCustodyDetails.ptsEvidenceCustodyId === incState.selectedEvidenceCustody
    ) {
      found = true;
    }
    if (found) {
      s.incEvidenceCustodyDetails = {
        ...s.incEvidenceCustodyDetails,
        changes: true,
        values: {
          ...s.incEvidenceCustodyDetails.values,
          ...state.values,
        },
      };
      found = false;
    }
  });
  return async (dispatch) => {
    return dispatch({
      type: SET_LIST_EVIDENCE_CUSTODY,
      payload: incState.incEvidenceCustody,
    });
  };
};

export const removeIncEvidenceCustodyDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;

    const deletedCustody = incState.incEvidenceCustody.find(
      (v) =>
        v.incEvidenceCustodyDetails.ptsEvidenceCustodyId === incState.selectedEvidenceCustody ||
        v.incEvidenceCustodyDetails.tempEvidenceCustodyId === incState.selectedEvidenceCustody
    );

    incState.incEvidenceCustody = incState.incEvidenceCustody.filter((v) =>
      typeof incState.selectedEvidenceCustody === 'string'
        ? v.incEvidenceCustodyDetails.tempEvidenceCustodyId !== incState.selectedEvidenceCustody
        : v.incEvidenceCustodyDetails.ptsEvidenceCustodyId !== incState.selectedEvidenceCustody
    );

    if (deletedCustody && deletedCustody.incEvidenceCustodyDetails.ptsEvidenceCustodyId) {
      await removeEntity('incevidencecustody', {
        ptsEvidenceCustodyId: deletedCustody.incEvidenceCustodyDetails.ptsEvidenceCustodyId,
        ptsIncidentId: incState.evidenceItemForm.ptsIncidentId ?? incState.ptsIncidentId,
      });
    }

    return dispatch({
      type: SET_LIST_EVIDENCE_CUSTODY,
      payload: incState.incEvidenceCustody,
    });
  };
};

export const setIsFormDirty = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_IS_FORM_DIRTY, payload: state });
  };
};

export const setTurnOfValidation = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_TURN_OFF_VALIDATION, payload: state });
  };
};

export const setHiddenData = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HIDDEN_DATA, payload: state });
  };
};

export const setCustodyAutoGenerated = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_CUSTODY_AUTO_GENERATED_DATA, payload: state });
  };
};

export const setIncidentCurrentAction = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INCIDENT_CURRENT_ACTION, payload: state });
  };
};
/****************************************/
/* END Region Action Creator Functions */
/**************************************/

/*region Helper Functions*/
// TODO: Refactor and move all of the helper functions to IncHelpers.js
export const removeSelectedIncident = () => {
  return async (dispatch) => {
    const { incident, uiHomeScreen } = store.store.getState();

    const remainingItems = incident.activeIncidents.filter((item) => {
      return item.incidentId !== incident.ptsIncidentId;
    });

    uiHomeScreen.activeIncidents = remainingItems;

    uiHomeScreen.totalIncidents = uiHomeScreen.totalIncidents - 1;

    await removeIncident();

    return dispatch({ type: GET_ACTIVE_INCIDENTS, payload: remainingItems });
  };
};

export const removeSelectedEntity = (entity) => {
  const definition = {
    actionReport: {
      reduxKey: 'actionReport',
      subKey: 'afterActionDetails',
      filterKey: 'ptsIncAfterActionId', // key is used for filtering.
      reduxAction: GET_AFTER_ACTION_REPORT,
    },
    addresses: {
      reduxKey: 'addresses',
      subKey: 'addressDetails',
      filterKey: 'ptsAddressId', // key is used for filtering.
      reduxAction: ADD_ADDRESSES,
    },
    partyplace: {
      reduxKey: 'addresses',
      subKey: 'addressDetails',
      filterKey: 'ptsAddressId', // key is used for filtering.
      placeKey: 'ptsIncPlaceId',
      reduxAction: ADD_ADDRESSES,
    },
    parties: {
      reduxKey: 'parties',
      subKey: 'personDetail',
      filterKey: 'ptsIncPersonId', // key is used for filtering.
      reduxAction: ADD_PARTIES,
    },
    offenses: {
      reduxKey: 'offenses',
      subKey: 'offenseDetails',
      filterKey: 'ptsOffenseId', // key is used for filtering.
      reduxAction: ADD_OFFENSES,
    },
    properties: {
      reduxKey: 'properties',
      subKey: 'propertyDetails',
      filterKey: 'incIncidentPropertyId', // key is used for filtering.
      reduxAction: GET_PROPERTIES,
    },
    narratives: {
      reduxKey: 'narratives',
      subKey: 'narrativeDetail',
      filterKey: 'ptsNarrativeId', // key is used for filtering.
      reduxAction: GET_NARRATIVES,
    },
    evidence: {
      reduxKey: 'evidences',
      subKey: 'itemRecordDetail',
      filterKey: 'ptsEvidenceId', // key is used for filtering.
      reduxAction: GET_EVIDENCES,
    },
    zones: {
      reduxKey: 'zones',
      subKey: 'values',
      filterKey: 'zone', // key is used for filtering.
      reduxAction: GET_ZONES,
    },
    alerts: {
      reduxKey: 'alerts',
      subKey: 'values',
      filterKey: 'ptsAlertId', // key is used for filtering.
      reduxAction: GET_ALERTS,
    },
    dispositions: {
      reduxKey: 'dispositions',
      subKey: 'values',
      filterKey: 'disposition', // key is used for filtering.
      reduxAction: GET_DISPOSITIONS,
    },
    notifications: {
      reduxKey: 'notifications',
      subKey: 'values',
      filterKey: 'ptsNotificationId', // key is used for filtering.
      reduxAction: GET_NOTIFICATIONS,
    },
    tags: {
      reduxKey: 'tags',
      subKey: 'values',
      filterKey: 'ptsTagId',
      reduxAction: GET_TAGS,
    },
  };

  return async (dispatch) => {
    const { incident } = store.store.getState();
    const selectedEntity = incident.selectedEntity;

    // Attach incident id with the payload
    selectedEntity['ptsIncidentId'] = incident.ptsIncidentId;

    const allListItems = incident[definition[entity].reduxKey];
    const filterKey = definition[entity].filterKey;
    const reduxAction = definition[entity].reduxAction;

    if (!Array.isArray(allListItems)) return;

    const remainingItems = allListItems.filter((item) => {
      if (definition[entity].subKey) {
        item = item[definition[entity].subKey];
      }

      if (entity === 'partyplace') {
        return (
          item[definition[entity].placeKey] !== parseInt(selectedEntity[filterKey].split('-')[1])
        );
      } else return item[filterKey] !== selectedEntity[filterKey];
    });

    await removeEntity(entity, selectedEntity, dispatch);

    dispatch({ type: reduxAction, payload: remainingItems });

    return true;
  };
};

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function preparePartyPersonData(data, isJson = true) {
  if (!data || !data.Parties) return [];

  let { Parties } = data;
  const { codePartyRelationship } = store.store.getState().dictionary;

  Parties = isJson ? JSON.parse(Parties) : Parties;

  if (!Parties || !Parties.length) return [];
  try {
    return Parties.map((item) => {
      if (item.Relationship) {
        item.PartyType = item.Relationship;
        item.PartyTypeDescription = item.partyTypeDescription;
      }

      if (isJson) {
        if (item?.Addresses) item.PartiesAddresses = item?.Addresses[0];
        if (item?.Employments) item.PartiesEmployment = item?.Employments[0];
        if (item?.MPVictim) item.MPVictim = item?.MPVictim[0];
        if (item?.PersonImage) {
          // item.PersonImage = arrayBufferToBase64(item?.ppa[0]?.PersonImage);
          item.PersonImage = item?.PersonImage;
        }
        if (item?.sexDescription) {
          item.sexDescription = item?.sexDescription;
        }
        if (item?.raceDescription) {
          item.raceDescription = item?.raceDescription;
        }
        if (item?.ethnicityDescription) {
          item.ethnicityDescription = item?.ethnicityDescription;
        }
        item.ActiveWarrant = item?.Warrant;
      }

      let availableOffenses = [];
      if (item.AvailableOffenses !== null && !Array.isArray(item.AvailableOffenses)) {
        availableOffenses = JSON.parse(item.AvailableOffenses);
      } else availableOffenses = null;

      const person = {
        ptsIncPersonId: item.ptsIncPersonID,
        ptsPersonId: item.ptsPersonID,
        PersonId: item.PersonID,
        personDetail: {
          ptsIncPersonId: item.ptsIncPersonID,
          ptsPersonId: item.ptsPersonID,
          PersonId: item.PersonID,
          values: {
            firstName: item.FirstName,
            lastName: item.LastName,
            middleName: item.MiddleName,
            fullName: item.FullName,
            ptsPersonId: item.ptsPersonID,
            age: item.Age,
            ageRangeIncrement: item.AgeRangeIncrement,
            ptsImageId: item.ptsImageID,
            personImage: item.PersonImage,
            birthdate: item.Born,
            bloodType: item.BloodType,
            // credentialId: item.,
            ethnicity: item.Ethnicity,
            eyeColor: item.EyeColor,
            hairColor: item.HairColor,
            hairLength: item.HairLength,
            hairStyle: item.HairStyle,
            handed: item.Handed,
            height: item.Height,
            incidentId: item.ptsIncidentID,
            partyType: item.PartyType,
            PartyTypeDescription: item.PartyTypeDescription
              ? item.PartyTypeDescription
              : getDescriptionofCode(codePartyRelationship, item.PartyType),
            partyOrder: item.PartyCode?.Order,
            personId: item.PersonID,
            race: item.Race,
            sex: item.Sex,
            sexDescription: item.sexDescription,
            raceDescription: item.raceDescription,
            ethnicityDescription: item.ethnicityDescription,
            weight: item.Weight,
            ssn: item.SSNNumber,
            oln: item.OLNNumber,
            state: item.OLNState,
            suffix: item.Suffix,
            isUnknownParty: item.IsUnknownParty,
            isArrestee: item?.Arrestee ? true : false,
            availableOffenses: availableOffenses,
            isSexOffender: item.SexOffender,
            isSociety: item.IsSociety,
            activeWarrant: item.ActiveWarrant,
            updatedBy: item.UpdatedBy,
            updated: item.Updated,
            createdBy: item.CreatedBy,
            created: item.Created,
            isVictim: item.IsVictim,
            isOffender: item.IsOffender,
            partyClearanceLevel: item.ClearanceLevel,
            attachments: item.Attachments ? item.Attachments : [],
          },
        },
        contactDetail: {
          ptsAddressId: item?.PartiesAddresses?.ptsAddressID,
          ptsIncAddressId: item?.PartiesAddresses?.ptsIncAddressID,
          values: {
            incidentId: item?.PartiesAddresses?.ptsIncidentID,
            personId: item?.PartiesAddresses?.ptsPersonID,
            cityId: item?.PartiesAddresses?.ptsCityID,
            addressNumberPrefix: item?.PartiesAddresses?.AddressNumberPrefix,
            addressNumber: item?.PartiesAddresses?.AddressNumber,
            addressNumberSuffix: item?.PartiesAddresses?.AddressNumberSuffix,
            preDirection: item?.PartiesAddresses?.PreDirection,
            streetName: item?.PartiesAddresses?.StreetName,
            streetType: item?.PartiesAddresses?.StreetType,
            postDirection: item?.PartiesAddresses?.PostDirection,
            city:
              item?.PartiesAddresses?.City?.CityDescription || item?.PartiesAddresses?.cc
                ? item?.PartiesAddresses?.cc[0]?.City
                : item?.PartiesAddresses?.City,
            state: item?.PartiesAddresses?.State,
            postalCode: item?.PartiesAddresses?.PostalCode,
            postalCodeExtension: item?.PartiesAddresses?.PostalCodeExtension,
            county: item?.PartiesAddresses?.County,
            country: item?.PartiesAddresses?.Country,
            fullAddressText: item?.PartiesAddresses?.FullAddressText,
            textmask1: item.ContactInfo1,
            textmask2: item.ContactInfo2,
            emailmask: item.ContactInfo3,
            textmask3: item.ContactInfo4,
            notes: item?.PartiesAddresses?.Notes,
          },
        },
        employmentDetail: {
          ptsIncPartyEmploymentId: item?.PartiesEmployment?.ptsIncPartyEmploymentID,
          ptsPlaceId: item?.PartiesEmployment?.ptsPlaceID,
          ptsAddressId: item?.PartiesEmployment?.ptsAddressID,
          values: {
            incidentId: item?.PartiesEmployment?.ptsIncidentID,
            personId: item?.PartiesEmployment?.ptsPersonID,
            addressId: item?.PartiesEmployment?.ptsAddressID,
            placeName: item?.PartiesEmployment?.PlaceName,
            cityId: item?.PartiesEmployment?.ptsCityID,
            addressNumberPrefix: item?.PartiesEmployment?.AddressNumberPrefix,
            addressNumber: item?.PartiesEmployment?.AddressNumber,
            addressNumberSuffix: item?.PartiesEmployment?.AddressNumberSuffix,
            preDirection: item?.PartiesEmployment?.PreDirection,
            postDirection: item?.PartiesEmployment?.PostDirection,
            streetName: item?.PartiesEmployment?.StreetName,
            streetType: item?.PartiesEmployment?.StreetType,
            city:
              item?.PartiesEmployment?.City?.CityDescription || item?.PartiesEmployment?.cc
                ? item?.PartiesEmployment?.cc[0]?.City
                : item?.PartiesEmployment?.City,
            state: item?.PartiesEmployment?.State,
            postalCode: item?.PartiesEmployment?.PostalCode,
            postalCodeExtension: item?.PartiesEmployment?.PostalCodeExtension,
            county: item?.PartiesEmployment?.County,
            country: item?.PartiesEmployment?.Country,
            fullAddressText: item?.PartiesEmployment?.FullAddressText,
            textmask1: item?.PartiesEmployment?.ContactInfo1,
            textmask2: item?.PartiesEmployment?.ContactInfo2,
            emailmask: item?.PartiesEmployment?.ContactInfo3,
            notes: item?.PartiesEmployment?.Notes,
          },
        },
        otherDetail: {
          values: {
            incidentId: item.ptsIncidentID,
            personId: item?.ptsPersonID,
            addressId: item?.ptsAddressID,
            generalAppearance: item?.GeneralAppearance,
            personNotes: item?.Notes,
          },
        },
        mpBasicDetail: {
          ptsMPVictimId: item?.MPVictim?.ptsMPVictimID,
          ptsIncPersonId: item?.ptsIncPersonID,
          values: {
            typeVictim: item?.MPVictim?.VictimType,
            category: item?.MPVictim?.VictimCategory,
            reportingAgency: item?.MPVictim?.ReportingAgency,
            ori: item?.MPVictim?.ORI,
            dojNumber: item?.MPVictim?.DOJNumber,
            ncicNumber: item?.MPVictim?.NCICNumber,
            recordType: item?.MPVictim?.RecordType ? JSON.parse(item?.MPVictim?.RecordType) : [],
            dateMissing: item?.MPVictim?.DateTimeMissing,
            dateFound: item?.MPVictim?.FoundDateTime,
            mentalCondition: item?.MPVictim?.MentalCondition,
            scarsMarksTattoos: item?.MPVictim?.ScarsMarksTattoos,
            locationLastSeen: item?.MPVictim?.LocationLastSeen,
            portableDestination: item?.MPVictim?.ProbableDestination,
            age: item?.MPVictim?.AgeInPhoto,
            clothingDesc: item?.MPVictim?.ClothingDescription,
            brokenOrMissingBones: item?.MPVictim?.BrokenBonesMissingOrgans,
            visibleDentalWork: item?.MPVictim?.VisibleDentalWork,
            glasses: item?.MPVictim?.HasGlasses,
            contactLenses: item?.MPVictim?.HasContactLenses,
            xrays: item?.MPVictim?.HasSkeletonXrays,
            dental: item?.MPVictim?.HasDentalXrays,
            dentures: item?.MPVictim?.Dentures,
            textmask2: item?.MPVictim?.DentistPhone,
            textmask1: item?.MPVictim?.DoctorPhone,
            photo: item?.MPVictim?.PhotoAvailable,
            missingStatus: item?.MPVictim?.MissingStatus,
          },
        },
        mpRelatedRecords: {},
      };

      // Formatting MP Related Records Information
      let mppropertyrecords = [];
      let mpaddressrecords = [];
      let mppartyrecords = [];

      if (
        item?.MPVictim?.MPProperty ||
        item?.MPVictim?.MPPartyPerson ||
        item?.MPVictim?.MPAddress
      ) {
        if (item?.MPVictim?.MPProperty && item?.MPVictim?.MPProperty.length > 0) {
          for (let arrayIndex = 0; arrayIndex < item?.MPVictim?.MPProperty.length; arrayIndex++) {
            mppropertyrecords[arrayIndex] =
              item?.MPVictim?.MPProperty[arrayIndex].incIncidentPropertyID;
          }
        }

        if (item?.MPVictim?.MPAddress && item?.MPVictim?.MPAddress.length > 0) {
          for (let arrayIndex = 0; arrayIndex < item?.MPVictim?.MPAddress.length; arrayIndex++) {
            mpaddressrecords[arrayIndex] = item?.MPVictim?.MPAddress[arrayIndex].ptsAddressID;
          }
        }

        if (item?.MPVictim?.MPPartyPerson && item?.MPVictim?.MPPartyPerson.length > 0) {
          for (
            let arrayIndex = 0;
            arrayIndex < item?.MPVictim?.MPPartyPerson.length;
            arrayIndex++
          ) {
            mppartyrecords[arrayIndex] = item?.MPVictim?.MPPartyPerson[arrayIndex].ptsIncPersonID;
          }
        }
      } else if (item?.MPVictim?.RelatedRecords) {
        mppropertyrecords = item?.MPVictim?.RelatedRecords.property;
        mpaddressrecords = item?.MPVictim?.RelatedRecords.address;
        mppartyrecords = item?.MPVictim?.RelatedRecords.party;
      }

      person.mpRelatedRecords = {
        property: mppropertyrecords,
        address: mpaddressrecords,
        party: mppartyrecords,
      };

      return person;
    });
  } catch (err) {
    console.log(err);
  }
}

function prepareAddressData(data) {
  if (!data) return [];
  let { Addresses } = data;

  Addresses = JSON.parse(Addresses);

  let addresses = [];

  if (data.Addresses) {
    addresses = Addresses.map((adrs) => {
      // adrs is having the data of incLocationAddress
      return {
        ptsAddressId: adrs.ptsAddressID,
        ptsLocationId: adrs.ptsLocationID,
        ptsCoordinateId: adrs.ptsCoordinateID,
        isVictim: adrs.cc[0]?.AddressPlaces
          ? adrs.cc[0]?.AddressPlaces[0].Relationship === 'VIC'
            ? true
            : false
          : false,
        addressDetails: {
          ptsAddressId: adrs.ptsAddressID,
          values: {
            ptsLocationId: adrs.ptsLocationID,
            fullAddressText: adrs.FullAddress,
            created: adrs.Created,
            createdBy: adrs.CreatedBy,
            updated: adrs.Updated,
            updatedBy: adrs.UpdatedBy,
            updatedIp: adrs.UpdatedIP,
            addressNumberPrefix: adrs.AddressNumberPrefix,
            addressNumberSuffix: adrs.AddressNumberSuffix,
            addressNumber: adrs.AddressNumber,
            streetName: adrs.StreetName,
            preDirection: adrs.PreDirection,
            postDirection: adrs.PostDirection,
            city: adrs?.cc[0]?.City,
            state: adrs.State,
            county: adrs.County,
            country: adrs.Country,
            postalCode: adrs.PostalCode,
            postalCodeExtension: adrs?.PostalCodeExtension,
            streetType: adrs.StreetType,
            notes: adrs.Notes,
            isPrimary: adrs.IsPrimary,
            ptsPlaceId: adrs.ptsPlaceID,
            ptsIncPlaceId: adrs.ptsIncPlaceID,
            addressPlaces: adrs.cc[0]?.AddressPlaces ? adrs.cc[0]?.AddressPlaces[0].PlaceName : '',
            placeName: adrs.cc[0]?.AddressPlaces ? adrs.cc[0]?.AddressPlaces[0].PlaceName : '',
            partyType: adrs.cc[0]?.AddressPlaces ? adrs.cc[0]?.AddressPlaces[0].Relationship : '',
            placeType: adrs.cc[0]?.AddressPlaces ? adrs.cc[0]?.AddressPlaces[0].PlaceType : '',
            placeORI: adrs.cc[0]?.AddressPlaces ? adrs.cc[0]?.AddressPlaces[0].PlaceORI : '',
            placeDepID: adrs.cc[0]?.AddressPlaces
              ? adrs.cc[0]?.AddressPlaces[0].PlaceDepartmentID
              : '',
            destination: adrs.cc[0]?.AddressPlaces
              ? adrs.cc[0]?.AddressPlaces[0].IsDestination
              : '',
            placeClearanceLevel: adrs.cc[0]?.AddressPlaces
              ? adrs.cc[0]?.AddressPlaces[0].ClearanceLevel
              : '',
            placeId: adrs.cc[0]?.AddressPlaces ? adrs.cc[0]?.AddressPlaces[0].PlaceID : null,
            unitType: adrs.UnitType,
            unitTypeIdentifier: adrs.UnitIdentifier,
          },
        },
        addressIdDetails: {
          ptsAddressId: adrs.ptsAddressID,
          values: {
            isEventLookup: adrs.ShowInEventLookup,
            isSexOffender: adrs.SexOffender,
            isParolee: adrs.Parolee,
            isProbation: adrs.Probation,
            isNonValidated: adrs.NonValidatedAddress,
            isCampusResidential: adrs.IsCampusResidential,
            isCampusNonResidential: adrs.IsCampusNonResidential,
            isNonCampus: adrs.IsNonCampus,
            isPublic: adrs.IsPublic,
            floorType: adrs.FloorType,
            floorIdentifier: adrs.FloorIdentifier,
            buildingType: adrs.BldgType,
            buildingIdentifier: adrs.BldgIdentifier,
          },
        },
        addressCoordinatesDetails: {
          ptsAddressId: adrs.ptsAddressID,
          ptsCoordinateId: adrs.ptsCoordinateID,
          values: {
            locationId: adrs.ptsLocationID,
            addressId: adrs.ptsAddressID,
            coordinateId: adrs?.ptsCoordinateID,
            latitudeSign: adrs?.LatitudeSign,
            latitudeDegree: adrs?.LatitudeDegree,
            longitudeSign: adrs?.LongitudeSign,
            longitudeDegree: adrs?.LongitudeDegree,
            uncertaintyDistance: adrs?.UnCertaintyDistance,
            datum: adrs?.Datum,
            coordinateDatetime: adrs?.CoordinateDT,
            altitude: adrs?.Altitude,
            altitudePor: adrs?.AltitudeRefPoint,
            altitudeUom: adrs?.AltitudeUnitMeasure,
            cellTower: adrs?.CellIDText,
            sectorId: adrs?.CellSectorIDText,
            speed: adrs?.Speed,
            speedUom: adrs?.SpeedUnitMeasure,
          },
        },
      };
    });
  }

  return addresses;
}

const generateOffenseArray = (allOffenses, parties, properties) => {
  const incState = store.store.getState().incident;
  const ptsStatutes = store.store.getState().dictionary.ptsStatute;
  const ptsFBICode = store.store.getState().dictionary.ptsFBICode;
  const dispatch = store.store.dispatch;

  let dvSuspects = [];
  let dvVictims = [];

  const offenses = allOffenses.map((offense) => {
    let statuteDetails = ptsStatutes.find((x) => x.ptsStatuteID === offense?.ptsStatuteID);
    let fbiDetails = ptsFBICode.find((x) => x.ptsFBICodeID === statuteDetails?.ptsFBICodeID);
    statuteDetails = {
      ...statuteDetails,
      FBICode: fbiDetails?.FBICode,
      FBIDescription: fbiDetails?.Description,
      CrimeAgainst: fbiDetails?.CrimeAgainst,
      CargoTheft: fbiDetails?.CargoTheft,
      OffenderSuspectedOfUsing: fbiDetails?.OffenderSuspectedOfUsing,
      BiasMotivation: fbiDetails?.BiasMotivation,
      LocationType: fbiDetails?.LocationType,
      MethodOfEntry: fbiDetails?.MethodOfEntry,
      CriminalActivityOrGangInfo: fbiDetails?.CriminalActivityOrGangInfo,
      TypeWeaponOrForceInvolved: fbiDetails?.TypeWeaponOrForceInvolved,
      PropertyLossType: fbiDetails?.PropertyLossType,
      AggravatedAssaultOrHomicide: fbiDetails?.AggravatedAssaultOrHomicide,
      JustifiableHomicide: fbiDetails?.JustifiableHomicide,
      InjuryType: fbiDetails?.InjuryType,
      AllowedCriminalActivityOrGangInfo: fbiDetails?.AllowedCriminalActivityOrGangInfo,
      StructureEntered: fbiDetails?.StructureEntered,
      OfficerActivity: fbiDetails?.OfficerActivity,
      NegligentManslaughter: fbiDetails?.NegligentManslaughter,
    };

    let victims = [];
    if (offense?.Victims) {
      victims = offense?.Victims.map((vic) => {
        if (vic.DVSuspect) {
          vic.DVSuspect.forEach((sus) =>
            dvSuspects.push({
              incSuspectDetails: {
                ptsVictimId: sus.ptsVictimID,
                ptsDVSuspectId: sus.ptsDVSuspectID,
                ptsOffenseId: vic.ptsOffenseID,
                ptsIncPersonId: sus.ptsIncPersonID,
                ptsPersonId: sus.ptsPersonID,
                ptsVictimPersonId: vic.ptsIncPersonID,
                values: { ...sus },
              },
            })
          );
        }
        if (vic.DVVictim) {
          vic.DVVictim.forEach((v) =>
            dvVictims.push({
              ptsVictimId: v.ptsVictimID,
              ptsDVVictimId: v.ptsDVVictimID,
              ptsOffenseId: vic.ptsOffenseID,
              ptsIncPersonId: vic.ptsIncPersonID,
              ptsPersonId: v.ptsPersonID,
              values: { ...v },
            })
          );
        }
        return {
          incVictimDetails: {
            ptsPersonId: vic.ptsParentID,
            ptsIncPersonId: vic.ptsIncPersonID,
            ptsIncPlaceId: vic.ptsIncPlaceID,
            ptsVictimId: vic.ptsVictimID,
            ptsOffenseId: vic.ptsOffenseID,
            values: {
              incidentId: vic.ptsIncidentID,
              offenseId: vic.ptsOffenseID,
              injuryType: vic.InjuryType,
              negligentManslaughter: vic.NegligentManslaughter,
              justifiableHomicide: vic.JustifiableHomicide,
              additionalJustifiableHomicide: vic.AddlJustifiableHomicide,
              officerORI: vic.OfficerORI,
              officerActivity: vic.OfficerActivity,
              officerAssignment: vic.OfficerAssignment,
              seekingProsecution: vic.IsSeekingPersecution,
              society: vic.IsSociety,
              caseNumber: vic.VICAPCaseNumber,
              category: vic.VictimCategory,
              disposition: vic.VictimDisposition,
              treatment: vic.VictimTreatment,
              residentStatus: vic.ResidentStatus,
              aggravatedAssaultHomicide: vic.AggAssaultType,
              notes: vic.Notes,
              dvVictims: vic.DVVictim,
              dvSuspects: vic.DVSuspect,
              dvAdditionals: vic.DVAdditional,
              created: vic.Created,
              createdBy: vic.CreatedBy,
              updated: vic.Updated,
              updatedBy: vic.UpdatedBy,
            },
          },
        };
      });
    }

    offense.Subjects = offense.Subjects ? offense.Subjects : [];
    offense.Victims = offense.Victims ? offense.Victims : [];
    offense.Arrestees = offense.Arrestees ? offense.Arrestees : [];
    offense.Properties = offense.Properties ? offense.Properties : [];

    return {
      offenseDetails: {
        ptsOffenseId: offense.ptsOffenseID,
        ptsStatuteId: statuteDetails.ptsStatuteID,
        values: {
          ptsOffenseId: offense.ptsOffenseID,
          subjectIds: offense.Subjects?.reduce((ids, sub) => [...ids, sub.ptsSubjectID], []),
          victimIds: offense.Victims?.reduce((ids, vic) => [...ids, vic.ptsVictimID], []),
          arresteeIds: offense.Arrestees?.reduce((ids, a) => [...ids, a.ptsArresteeID], []),
          subjects: offense.Subjects ?? [],
          victims: victims,
          // relations: offense.Relations ?? [],
          properties: offense.Properties ?? [],
          arrestees: offense.Arrestees ?? [],
          statute: offense.statute || '',
          statuteDetails: statuteDetails || {},
          fbiCode: offense.ptsFBICode,
          offenseAttempted: offense.IsAttempted,
          structuresEntered: offense.StructuresEntered,
          structuresOccupied: offense.StructuresOccupied,
          cargoTheft: offense.IsCargoTheft,
          excludeOffense: offense.IsExcluded,
          methodOfEntry: offense.MethodOfEntry,
          locationCategory: offense.IncidentLocationType,
          criminalActivity: offense.CriminalActivity,
          offenderSuspectedOfUsing: offense.OffenderSuspectedOfUsing,
          biasMotivation: offense.BiasMotivations,
          forceCategory: offense.WeaponForceType,
          date: offense.Created,
          offenseClearanceLevel: offense.ClearanceLevel,
          updated: offense.Updated,
          updatedBy: offense.UpdatedBy,
          created: offense.Created,
          createdBy: offense.CreatedBy,
        },
      },
    };
  });

  dispatch({ type: SET_INC_DV_SUSPECT_LIST, payload: dvSuspects });
  dispatch({ type: SET_INC_DV_VICTIM_LIST, payload: dvVictims });

  return offenses;
};

const prepareAllIncArresteesData = (offenses, dispatch) => {
  let allArrestees = [];
  offenses.forEach((offense) => {
    let { arrestees } = offense.offenseDetails.values;

    arrestees &&
      arrestees.map((a) => {
        allArrestees.push({
          incArresteeDetails: {
            ptsPersonId: a?.ptsPersonID,
            ptsIncPersonId: a?.ptsIncPersonID,
            ptsArresteeId: a?.ptsArresteeID,
            ptsOffenseId: a?.ptsOffenseID,
            values: {
              arrestLocation: a?.ArrestLocation,
              arrestDate: a?.ArrestDate,
              cleared: a?.IsCleared,
              arrestType: a?.ArrestType,
              arresteeSegmentsIndicator: a?.ArrestSegmentIndicator,
              juvenileDisposition: a?.JuvenileDisposition,
              arresteeResidentStatus: a?.ResidentStatus,
              arresteeArmedWith: a?.ArresteeArmed,
              offenseDescription: a?.OffenseDescription,
              notes: a?.Notes,
              arrestOfficer: a?.ArrestOfficer,
              rightsOfficer: a?.RightsOfficer,
              rightsLocation: a?.RightsLocation,
              rightsDate: a?.RightsDate,
              createdBy: a?.CreatedBy,
              created: a?.Created,
              updatedBy: a?.UpdatedBy,
              updated: a?.Updated,
            },
          },
        });
      });
  });

  dispatch(setIncArresteeDetails(allArrestees));
};

const prepareAllIncSubjectsData = (offenses, dispatch) => {
  let allSubjects = [];
  offenses.forEach((offense) => {
    let { subjects } = offense.offenseDetails.values;

    subjects &&
      subjects.forEach((sub) => {
        allSubjects.push({
          incSubjectDetails: {
            ptsPersonId: sub.ptsPersonID,
            ptsIncPersonId: sub.ptsIncPersonID,
            ptsSubjectId: sub.ptsSubjectID,
            ptsOffenseId: sub.ptsOffenseID,
            values: {
              incidentId: sub.ptsIncidentID,
              offenseId: sub.ptsOffenseID,
              subjectId: sub.ptsSubjectID,
              armed: sub.IsArmed,
              extradition: sub.IsExtradition,
              unknown: sub.IsUnknown,
              dnaCollection: sub.DNACollection,
              involvement: sub.SubjectInvolvement,
              mo: sub.SubjectMO,
              status: sub.SubjectStatus,
              weapon: sub.SubjectWeapon,
              notes: sub.Notes,
              createdBy: sub.CreatedBy,
              created: sub.Created,
              updatedBy: sub.UpdatedBy,
              updated: sub.Updated,
            },
          },
        });
      });
  });

  dispatch(setIncSubjectsDetails(allSubjects));
};

const prepareAllIncVictimsData = (offenses, dispatch) => {
  let allVictims = [];
  offenses.forEach((offense) => {
    allVictims.push(...offense.offenseDetails.values.victims);
  });

  dispatch(setIncVictimsDetails(allVictims));
};

const prepareAllIncRelationshipsData = (data, dispatch) => {
  if (!data || !data.Relations) return;

  let allRelations = [];
  let relations = JSON.parse(data.Relations);
  let subjects = store.store.getState().incident.incSubjects;
  let victims = store.store.getState().incident.incVictims;

  // offenses.forEach((offense) => {
  //   const offensesOffense = offense?.offenseDetails?.values;
  //   const { subjects, victims } = offensesOffense;

  relations.length &&
    relations.forEach((r) => {
      const subjectId = subjects.find(
        (sub) => sub.incSubjectDetails.ptsSubjectId === r.ptsSubjectID
      )?.incSubjectDetails?.ptsIncPersonId;

      const victimId = victims.find((vic) => vic.incVictimDetails.ptsVictimId === r.ptsVictimID)
        ?.incVictimDetails?.ptsIncPersonId;

      subjectId &&
        victimId &&
        allRelations.push({
          incRelationshipDetails: {
            ptsIncidentId: r.ptsIncidentID,
            values: {
              relationship: {
                offenderId: subjectId || null,
                victimId: victimId || null,
                relationship: r.Relationship,
              },
            },
          },
        });
    });
  // });

  dispatch(setIncRelationshipDetails(allRelations));
  dispatch(setIncRelationsData(relations));
};

const prepareAllIncPropertiesData = (offenses, dispatch) => {
  let allProperties = [];
  offenses.forEach((offense) => {
    const { properties } = offense?.offenseDetails?.values;
    properties &&
      properties.forEach((property) => {
        allProperties.push({
          incPropertyDetails: {
            incIncidentPropertyId: property.incIncidentPropertyID,
            ptsOffenseId: property.ptsOffenseID,
            ptsPropertyId: property.ptsPropertyID,
            values: {
              incIncidentPropertyId: property.incIncidentPropertyID,
              ownerFullName: '', // Need to join with incPropert eyOwners
              propertyDescription: property.PropertyDescription,
              type: property.PropertyLossType,
              parentType: property.ParentType,
              status: property.PropertyStatus,
              quantity: property.Quantity,
              quantityMeasure: property.QuantityMeasure,
              notes: property.Notes,
              updated: property.Updated,
              updatedBy: property.UpdatedBy,
              created: property.Created,
              createdBy: property.CreatedBy,
              value: property.Value,
              recoveredValue: property.RecoveredValue,
              recoveredDate: property.RecoveryDate,
              statusDate: property.StatusDate,
              stolenFromOtherJurisdiction: property.IsStolenFromOtherJurisdiction,
              recoveredFromOtherJurisdiction: property.IsRecoveredFromOtherJurisdiction,
              stolenVehicles: property.StolenVehicles,
              recoveredVehicles: property.RecoveredVehicles,
            },
          },
        });
      });
  });

  dispatch(setIncPropertyDetails(allProperties));
};

function prepareOffenseData(data, dispatch) {
  if (!data || !data.Offenses) return [];

  let { Offenses, Parties, Properties } = data;

  Offenses = JSON.parse(Offenses);
  Parties = JSON.parse(Parties);
  Properties = JSON.parse(Properties);
  if (!Offenses || !Offenses.length) return [];

  const res = generateOffenseArray(Offenses, Parties, Properties);

  prepareAllIncArresteesData(res, dispatch);
  prepareAllIncSubjectsData(res, dispatch);
  prepareAllIncVictimsData(res, dispatch);
  prepareAllIncPropertiesData(res, dispatch);

  return res;
}

function capitalize(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function lowercaseObjectKeys(obj) {
  var key,
    keys = Object.keys(obj);
  var n = keys.length;
  var newobj = {};
  while (n--) {
    key = keys[n];
    const key1 = key.charAt(0).toLowerCase() + key.slice(1);
    newobj[key1] = obj[key];
  }
  return newobj;
}

function prepareCadNotesData(data, isJson = true) {
  if (!data || !data.CadNotes) return [];
  let { CadNotes } = data;
  CadNotes = isJson ? JSON.parse(CadNotes) : CadNotes;
  if (!CadNotes.length) return [];

  const notes = CadNotes.map((note) => {
    return {
      parentType: note.ParentType,
      ptsCommentID: note.ptsCommentID,
      Comment: note.Comment,
      created: note.Created,
      createdBy: note.CreatedBy,
      updated: note.Updated,
      updatedBy: note.UpdatedBy,
    };
  });
  return notes;
}

function preparePropertyData(data, isJson = true) {
  if (!data || !data.Properties) return [];
  let { Properties } = data;
  Properties = isJson ? JSON.parse(Properties) : Properties;

  const properties = [];

  Properties.forEach((property) => {
    const capParentType = capitalize(property.PropertyType);
    let currentProperty = property[capParentType];
    if (!currentProperty) return;
    //console.log('curent property ', currentProperty);
    currentProperty =
      currentProperty && currentProperty.length === 1 ? currentProperty[0] : currentProperty;

    let newObj = lowercaseObjectKeys(currentProperty);
    // mapping the structure attribtues.

    let propertyStorage, vehicleReg;

    if (currentProperty.PropertyStorage) {
      propertyStorage = JSON.parse(currentProperty.PropertyStorage);
    }
    delete newObj.propertyStorage;

    if (currentProperty.VehicleReg) {
      vehicleReg = JSON.parse(currentProperty.VehicleReg);
    }
    delete newObj.vehicleReg;

    if (newObj.isResidence) {
      newObj = { ...newObj, residence: newObj.isResidence };
    }
    if (newObj.bathroomQty) {
      newObj = { ...newObj, bathroomQuantity: newObj.bathroomQty };
    }
    if (newObj.quantityMeasure) {
      newObj = { ...newObj, substanceQuantityMeasurement: newObj.quantityMeasure };
    }
    if (newObj.drugDaysSupply) {
      newObj = { ...newObj, substanceDaysSupply: newObj.drugDaysSupply };
    }
    if (newObj.drugStrength) {
      newObj = { ...newObj, substanceStrength: newObj.drugStrength };
    }
    if (newObj.dEAClass) {
      newObj = { ...newObj, deaClass: newObj.dEAClass };
    }
    if (newObj.isRented) {
      newObj = { ...newObj, rented: newObj.isRented };
    }
    if (newObj.isWanted) {
      newObj = { ...newObj, wanted: newObj.isWanted };
    }
    if (newObj.isAutomatic) {
      newObj = { ...newObj, automatic: newObj.isAutomatic };
    }
    if (newObj.bedroomQty) {
      newObj = { ...newObj, bedroomQuantity: newObj.bedroomQty };
    }
    if (newObj.bathroomQty) {
      newObj = { ...newObj, bathroomQuantity: newObj.bathroomQty };
    }
    if (newObj.commercialID) {
      newObj = { ...newObj, commercialId: newObj.commercialID };
    }
    if (newObj.commercial) {
      newObj = { ...newObj, commercialCode: newObj.commercial };
    }
    if (newObj.propertyID) {
      newObj = { ...newObj, propertyId: newObj.propertyID };
    }
    if (newObj.transmission) {
      newObj = { ...newObj, transmissionCode: newObj.transmission };
    }
    if (newObj.emissionInspectionQty) {
      newObj = { ...newObj, emissionInspection: newObj.emissionInspectionQty };
    }
    if (newObj.property) {
      newObj = { ...newObj, propertyCode: newObj.property };
    }

    let availableOffenses = [];
    if (
      property.AvailableOffenses &&
      property.AvailableOffenses !== null &&
      !Array.isArray(property.AvailableOffenses)
    ) {
      availableOffenses = JSON.parse(property.AvailableOffenses);
    } else availableOffenses = null;

    if (currentProperty.ptsVehicleID) {
      properties.push({
        propertyType: property.PropertyType.toLowerCase(),
        propertyDetails: {
          incIncidentPropertyId: property.incIncidentPropertyID,
          [`pts${capParentType}Id`]: property[capParentType]?.[`pts${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID
          ptsVehicleId: currentProperty.ptsVehicleID,
          propertyId: property[capParentType]?.[`${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID
          ptsRegistrationId: vehicleReg?.ptsRegistrationID || newObj?.ptsRegistrationID,
          values: {
            incidentId: property.ptsIncidentID,
            incIncidentPropertyId: property.incIncidentPropertyID,
            clearanceLevel: property.ClearanceLevel,
            propertyOwner: property.PropertyOwner,
            propertyOwnerType: property.PropertyOwnerType,
            value: property.Value,
            attachments: property.Attachments ? property.Attachments : [],
            ownerFullName: '', // Need to join with incPropert eyOwners
            description: currentProperty.Description,
            propertyLossType: '', // Where to get it?
            make: currentProperty.Make,
            model: currentProperty.Model,
            parentType: property.PropertyType,
            updated: property.Updated,
            updatedBy: property.UpdatedBy,
            created: property.Created,
            createdBy: property.CreatedBy,
            availableOffenses: availableOffenses,
            name: currentProperty.Name,
            ptsPersonId: propertyStorage?.ptsPersonID || null,
            ptsPropertyId: propertyStorage?.ptsPropertyID || null,
            ptsPropertyStorageId: propertyStorage?.ptsPropertyStorageID || null,
            storageLocation: propertyStorage?.StorageLocation || null,
            storedDateTime: propertyStorage?.StoredDateTime || null,
            disposition: propertyStorage?.Disposition || null,
            dispositionDateTime: propertyStorage?.DispositionDateTime || null,
            ...newObj,
            vin: currentProperty.VIN,
            quantity: currentProperty.Quantity,
            vinAnalysisTest: currentProperty.VINAnalysisText,
            passengerQuantity: currentProperty.PassengerSafeQty,
            seatQuantity: currentProperty.SeatQty,
            plateType: vehicleReg?.PlateType || newObj?.plateType,
            plateColor: vehicleReg?.PlateColor || newObj?.plateColor,
            plateNumber: vehicleReg?.PlateNumber || newObj?.plateNumber,
            plateState: vehicleReg?.PlateState || newObj?.plateState,
            plateStatus: vehicleReg?.PlateStatus || newObj?.plateStatus,
            plateDetail: vehicleReg?.PlateDetail || newObj?.plateDetail,
            plateExpiration: vehicleReg?.PlateExpiration || newObj?.plateExpiration,
            plateBackgroundText: vehicleReg?.PlateBackgroundText || newObj?.plateBackgroundText,
          },
        },
      });
    } else {
      properties.push({
        propertyType: property.PropertyType.toLowerCase(),
        propertyDetails: {
          incIncidentPropertyId: property.incIncidentPropertyID,
          [`pts${capParentType}Id`]: property[capParentType]?.[`pts${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID
          propertyId: property[capParentType]?.[`${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID

          values: {
            incidentId: property.ptsIncidentID,
            incIncidentPropertyId: property.incIncidentPropertyID,
            clearanceLevel: property.ClearanceLevel,
            propertyOwner: property.PropertyOwner,
            propertyOwnerType: property.PropertyOwnerType,
            value: property.Value,
            attachments: property.Attachments ? property.Attachments : [],
            ownerFullName: '', // Need to join with incPropertyOwners
            description: currentProperty.Description,
            propertyLossType: '', // Where to get it?
            make: currentProperty.Make,
            model: currentProperty.Model,
            parentType: property.PropertyType,
            updated: property.Updated,
            updatedBy: property.UpdatedBy,
            created: property.Created,
            createdBy: property.CreatedBy,
            availableOffenses: availableOffenses,
            name: currentProperty.Name,
            ptsPersonId: propertyStorage?.ptsPersonID || null,
            ptsPropertyId: propertyStorage?.ptsPropertyID || null,
            ptsPropertyStorageId: propertyStorage?.ptsPropertyStorageID || null,
            storageLocation: propertyStorage?.StorageLocation || null,
            storedDateTime: propertyStorage?.StoredDateTime || null,
            disposition: propertyStorage?.Disposition || null,
            dispositionDateTime: propertyStorage?.DispositionDateTime || null,
            ...newObj,
          },
        },
      });
    }
  });

  return properties;
}

function prepareEvidenceData(data) {
  if (!data || !data.Evidences) return [];

  const { Evidences } = data;

  if (!Evidences) return [];

  const Evidence = JSON.parse(Evidences);

  const { codeEvidenceCustodyReason } = store.store.getState().dictionary;

  const evidences = Evidence.map((item) => {
    const { Custodies, Dispositions, Storages } = item;

    let evidenceStorageDetails = [];
    const EvidenceStorage = Storages ? Storages : [];
    EvidenceStorage.map((s) => {
      let storageDetail = {
        incEvidenceStorageDetails: {
          ptsEvidenceId: item.ptsEvidenceID,
          ptsIncidentId: item.ptsIncidentID,
          ptsEvidenceStorageId: s?.ptsEvidenceStorageID,
          values: {
            incidentId: item.ptsIncidentID,
            evidenceId: item.ptsEvidenceID,
            evidenceStorageId: s?.ptsEvidenceStorageID,
            dateIn: s?.DateTimeIn,
            storageLocation: s?.StorageLocation,
            container: s?.Container,
            subLocation: s?.SubLocation,
            enteredBy: s?.UpdatedBy,
          },
        },
      };
      setSelectedEvidenceStorage(storageDetail.incEvidenceStorageDetails.ptsEvidenceStorageId);
      evidenceStorageDetails.push(storageDetail);
    });

    let evidenceCustodyDetails = [];
    const EvidenceCustody = Custodies ? Custodies : [];
    EvidenceCustody.map((s) => {
      let custodyDetail = {
        incEvidenceCustodyDetails: {
          ptsEvidenceId: item.ptsEvidenceID,
          ptsIncidentId: item.ptsIncidentID,
          ptsEvidenceCustodyId: s?.ptsEvidenceCustodyID,
          values: {
            incidentId: item.ptsIncidentID,
            evidenceId: item.ptsEvidenceID,
            transactionDate: s?.TransactionDate ? s?.TransactionDate : null,
            transactionType: s?.TransactionType ? s?.TransactionType : '',
            evidenceCustodyId: s?.ptsEvidenceCustodyID,
            Status: s?.Status,
            custodyFrom: s?.CustodyFrom,
            custodyTo: s?.CustodyTo,
            reason: s?.Reason,
            reasonDescription: s?.Reason
              ? getDescriptionofCode(codeEvidenceCustodyReason, s?.Reason)
              : null,
            notes: s?.Notes,
            requiredAction: s?.RequiredAction,
            disposalCode: s?.DisposalCode,
            created: displayDateTime(s?.Created),
            createdBy: s?.CreatedBy,
            comeFromCheckInFlow: s?.ComeFromCheckInFlow || false,
          },
        },
      };
      setSelectedEvidenceCustody(custodyDetail.incEvidenceCustodyDetails.ptsEvidenceCustodyId);
      evidenceCustodyDetails.push(custodyDetail);
    });

    const EvidenceDisposition = Dispositions ? Dispositions[0] : {};
    let evidenceDispositionDetails = {
      ptsEvidenceId: item.ptsEvidenceID,
      ptsIncidentId: item.ptsIncidentID,
    };

    if (EvidenceDisposition) {
      evidenceDispositionDetails = {
        ...evidenceDispositionDetails,
        ptsEvidenceDispositionId: EvidenceDisposition?.ptsEvidenceDispositionID,
        values: {
          incidentId: item.ptsIncidentID,
          evidenceId: item.ptsEvidenceID,
          evidenceDispositionId: EvidenceDisposition?.ptsEvidenceDispositionID,
          bypassDisposalAssumption: EvidenceDisposition?.BypassDispAssumption,
          readyForDisposal: EvidenceDisposition?.ReadyForDisposal,
          isDisposed: EvidenceDisposition?.IsDisposed,
          disposedOfBy: EvidenceDisposition?.DisposedOfBy,
          dispositionDate: EvidenceDisposition?.DispositionDate,
          finalDisposition: EvidenceDisposition?.FinalDisposition,
          dispositionNotes: EvidenceDisposition?.DispositionNotes,
        },
      };
    }

    const evidenceParties = prepareEvidencePartyPersonData(item, false); // IsJSON false

    let collectedByFullName = '';

    if (evidenceParties?.length > 0) {
      const collectedBy = evidenceParties.find((ep) => {
        const partyType = ep?.personDetail?.values?.partyType;

        return ['COLLECTED'].includes(
          getDataOfDictionaryOptions('codePartyRelationship', 'Category', 'Code', partyType)
        );
      });

      if (collectedBy) {
        collectedByFullName = `${
          collectedBy?.personDetail?.values?.firstName
            ? collectedBy?.personDetail.values.firstName
            : ''
        } ${
          collectedBy?.personDetail?.values?.middleName
            ? collectedBy?.personDetail.values.middleName
            : ''
        } ${
          collectedBy?.personDetail?.values.lastName
            ? collectedBy?.personDetail.values.lastName
            : ''
        }`;
      }
    }

    return {
      itemRecordDetail: {
        ptsEvidenceId: item.ptsEvidenceID,
        ptsIncidentId: item.ptsIncidentID,
        incIncidentPropertyId: item.incIncidentPropertyID,
        evidenceId: item.EvidenceID,
        values: {
          ptsEvidenceId: item.ptsEvidenceID,
          evidenceType: item.EvidenceType,
          description: item.Description,
          requiredAction: item.RequiredAction,
          actionDate: item.ActionDate,
          submittedAt: item.SubmittedAt,
          collectedDate: item.DateTimeCollected,
          collectedBy: collectedByFullName,
          submittedBy: item.SubmittedBy,
          incidentId: item.ptsIncidentID,
          evidenceId: item.EvidenceID,
          category: item.Category,
          locationCollected: item.LocationCollected,
          temporaryLocation: item.TemporaryLocation,
          notes: item.Notes,
          disposalCode: item.DisposalCode,
          offenses: item.Offenses ? JSON.parse(item.Offenses) : null,
          quantity: item.Quantity,
          tagNumber: item.TagNumber,
          make: item.Make,
          itemMakeDescription: item.ItemMakeDescription,
          itemModelDescription: item.ItemModelDescription,
          itemConditionDescription: item.ItemConditionDescription,
          model: item.Model,
          serialNumber: item.SerialNumber,
          vin: item.VIN,
          licenseNumber: item.LicenseNumber,
          condition: item.Condition,
          substanceType: item.SubstanceType,
          substanceClass: item.SubstanceClass,
          measurement: item.Measurement,
          evidenceClearanceLevel: item.ClearanceLevel,
          updated: item.Updated,
          updatedBy: item.UpdatedBy,
          created: item.Created,
          createdBy: item.CreatedBy,
          propertyOwner: item.PropertyOwner,
          propertyOwnerType: item.PropertyOwnerType,
          currentLocation: item.CurrentLocation,
          attachments: item.Attachments ? item.Attachments : [],
        },
      },
      evidenceParties,
      custodyDetail: evidenceCustodyDetails,
      storageDetail: evidenceStorageDetails,
      dispositionDetail: evidenceDispositionDetails,
    };
  });
  return evidences;
}

function prepareGeneralData(data) {
  if (!data) return;

  return {
    ptsIncidentId: data.ptsIncidentID,
    ptsEventId: data.ptsEventID,
    ptsLocationId: data.ptsLocationID,
    CustomId: data.IncidentID,
    AgencyId: data.AgencyID,
    values: {
      callType: data?.CallType,
      status: data?.Status,
      StatusDate: data?.StatusDate,
      incidentCategory: data?.IncidentCategory,
      weather: data?.Weather,
      ibrStatus: data?.ibrStatus,
      ibrSubStatus: data?.ibrSubStatus,
      locationCategory: data.location?.LocationCategory,
      lighting: data?.Lighting,
      ibrSubStatusDate: data?.ibrSubStatusDate
        ? `${data?.ibrSubStatusDate}T00:00:00`
        : data?.ibrSubStatusDate,
      occurred: data?.Occurred,
      occurredTo: data?.OccurredTo,
      createdBy: data.CreatedBy,
      created: data.Created,
      updated: data?.Updated,
      updatedBy: data?.UpdatedBy,
      dispatched: data?.Dispatched,
      received: data?.Received,
      enroute: data?.Enroute,
      arrived: data?.Arrived,
      underCont: data?.UnderCont,
      completed: data?.Completed,
      synopsis: data?.Synopsis,
      incidentClearanceLevel: data.ClearanceLevel,
      IsJuvenileReport: data.IsJuvenileReport,
      IbrLastSubmittedDate: data.IbrLastSubmittedDate,
      isLocked: data.IsLocked,
      lockedBy: data.LockedBy,
      lockedByPersonId: data.LockedByPersonID,
      locked: data.Locked,
      lockedReasons: data.LockedReasons,
      lockedComments: data.LockedComments,
      promptToRelock: data.PromptToRelock,
    },
  };
}

function prepareNarrativeData(data) {
  if (!data.Narratives) return [];

  const { Narratives } = data;
  const Narrative = JSON.parse(Narratives);

  const narratives = Narrative.map((item) => {
    return {
      narrativeDetail: {
        ptsNarrativeId: item.ptsNarrativeID,
        narrativeId: item.ptsNarrativeID,
        values: {
          ptsNarrativeId: item.ptsNarrativeID,
          narrativeId: item.ptsNarrativeID,
          narrativeTitle: item.NarrativeTitle,
          narrativeBody: item.NarrativeBody,
          narrativeType: item.NarrativeType,
          approvedDT: item.ApprovedDT,
          narrativeDT: item.NarrativeDT,
          sequence: item.NarrativeSequence,
          narrativeClearanceLevel: item.ClearanceLevel,
          approvedBy: item.ApprovedBy,
          updated: item.Updated,
          updatedBy: item.UpdatedBy,
          created: item.Created,
          createdBy: item.CreatedBy,
          entryClerkId: item.ptsEntryClerkID,
          entryClerk: item.EntryClerk,
          authorId: item.ptsAuthorID,
          author: item.au[0]?.AuthorName,
          attachments: item.Attachments ? item.Attachments : [],
        },
      },
    };
  });

  return narratives;
}

function prepareDispositionData(data) {
  if (!data.Dispositions) return [];

  let { Dispositions } = data;
  Dispositions = JSON.parse(Dispositions);
  const dispositions = Dispositions.map((item) => {
    return {
      ptsDispositionId: item.ptsDispositionID,
      values: {
        ptsDispositionId: item.ptsDispositionID,
        disposition: item.Disposition,
        dispositionDescription: item.DispositionDescription,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
        created: item.Created,
        createdBy: item.CreatedBy,
        filed: item.Filed,
      },
    };
  });

  return dispositions;
}

function prepareZoneData(data) {
  if (!data.Zones) return [];

  let { Zones } = data;
  Zones = JSON.parse(Zones);
  const zones = Zones.map((item) => {
    return {
      ptsLocationId: item.ptsLocationID,
      values: {
        ptsLocationId: item.ptsLocationID,
        zone: item.Zone,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
        created: item.Created,
        createdBy: item.CreatedBy,
      },
    };
  });

  return zones;
}

function prepareAlertData(data) {
  if (!data.Alerts) return [];

  const { Alerts } = data;
  const zones = data.Alerts.map((item) => {
    return {
      ptsAlertId: item.ptsAlertID,
      values: {
        ptsAlertId: item.ptsAlertID,
        alertType: item.AlertType,
        notification: item.Notification,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    };
  });

  return zones;
}

function prepareNotificationData(data) {
  if (!data.Notifications) return [];

  const { Notifications } = data;

  const notifications = data.Notifications.map((item) => {
    return {
      ptsNotificationId: item.ptsNotificationID,
      values: {
        ptsNotificationId: item.ptsNotificationID,
        notification: item.Notification,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    };
  });

  return notifications;
}

function prepareTagData(data) {
  if (!data.Tags) return [];

  let { Tags } = data;

  Tags = JSON.parse(Tags);

  const tags = Tags.map((item) => {
    return {
      ptsTagId: item.ptsTagID,
      values: {
        ptsTagId: item.ptsTagID,
        type: item.Type,
        typeDescription: item.c[0].TypeDescription,
        value: item.Value,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
        created: item.Created,
        createdBy: item.CreatedBy,
      },
    };
  });

  return tags;
}

function handleHiddenData(data) {
  const {
    HiddenEvidences,
    HiddenNarratives,
    HiddenOffenses,
    HiddenPartyPersons,
    HiddenPartyPlaces,
    HiddenProperties,
  } = data;

  const hiddenData =
    HiddenEvidences ||
    HiddenNarratives ||
    HiddenOffenses ||
    HiddenPartyPersons ||
    HiddenPartyPlaces ||
    HiddenProperties
      ? true
      : false;

  return hiddenData;
}

function handleTurnOfValidation(data, dispatch) {
  const { HiddenOffenses } = data;
  if (HiddenOffenses) {
    dispatch({ type: SET_TURN_OFF_VALIDATION, payload: true });
    return;
  }

  const {
    incSubjects,
    incVictims,
    incArrestees,
    incProperties,
    parties,
    properties,
    addresses,
    offenses,
  } = store.store.getState().incident;

  if (offenses.length === 0) {
    dispatch({ type: SET_TURN_OFF_VALIDATION, payload: false });
    return;
  }

  const allVictimPresent = incVictims.every((item) => {
    if (item.incVictimDetails.ptsIncPersonId) {
      return parties.some((p) => item.incVictimDetails.ptsIncPersonId === p.ptsIncPersonId);
    } else if (item.incVictimDetails.ptsIncPlaceId) {
      return addresses.some(
        (p) => item.incVictimDetails.ptsIncPlaceId === p.addressDetails.values.ptsIncPlaceId
      );
    } else return false;
  });

  const entityMissing =
    incSubjects.every((item) =>
      parties.some((p) => item.incSubjectDetails.ptsIncPersonId === p.ptsIncPersonId)
    ) &&
    allVictimPresent &&
    incArrestees.every((item) =>
      parties.some((p) => item.incArresteeDetails.ptsIncPersonId === p.ptsIncPersonId)
    ) &&
    incProperties.every((item) =>
      properties.some(
        (p) =>
          item.incPropertyDetails.incIncidentPropertyId === p.propertyDetails.incIncidentPropertyId
      )
    );

  dispatch({ type: SET_TURN_OFF_VALIDATION, payload: !entityMissing });
  dispatch({ type: SET_NIBRS_DATA_LOADED, payload: true });
}

function prepareAfterActionReportsData(data, dispatch) {
  if (!data || !data.AfterActionReports) return [];

  let { AfterActionReports } = data;

  AfterActionReports = JSON.parse(AfterActionReports);

  if (!AfterActionReports || !AfterActionReports.length) return [];

  const actionReports = AfterActionReports.map((report) => {
    return prepareActionReportData(report);
  });

  return actionReports;
}

export const getEntitiesByIncidentIdV2 = () => {
  return async (dispatch) => {
    const { incident, user } = store.store.getState();
    const selectedIncId = incident.ptsIncidentId;
    const clearanceLevel = parseInt(user.userCurrentClearanceLevel.Level);

    const data = await getEntitiesByIncIdV2(selectedIncId, clearanceLevel);
    if (!data || !data[0][0]) return;

    let payload = prepareGeneralData(data[0][0]);
    let actionType = SET_INCIDENT;
    dispatch({ type: actionType, payload: payload });

    payload = preparePartyPersonData(data[0][0]);
    actionType = GET_PARTIES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('parties'));

    payload = prepareAddressData(data[0][0]);
    actionType = GET_ADDRESSESS;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('addresses'));

    payload = prepareOffenseData(data[0][0], dispatch);
    actionType = GET_OFFENSES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('offenses'));

    payload = preparePropertyData(data[0][0]);
    actionType = GET_PROPERTIES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('properties'));

    payload = prepareEvidenceData(data[0][0]);
    actionType = GET_EVIDENCES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('evidence'));

    payload = prepareDispositionData(data[0][0]);
    actionType = GET_DISPOSITIONS;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('dispositions'));

    payload = prepareZoneData(data[0][0]);
    actionType = GET_ZONES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('zones'));

    payload = prepareTagData(data[0][0]);
    actionType = GET_TAGS;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('tags'));

    payload = prepareNarrativeData(data[0][0]);
    actionType = GET_NARRATIVES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('narratives'));

    payload = prepareCadNotesData(data[0][0]);
    actionType = GET_CAD_NOTES;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('cadNotes'));

    prepareAllIncRelationshipsData(data[0][0], dispatch);

    payload = handleHiddenData(data[0][0]);
    actionType = SET_HIDDEN_DATA;
    dispatch({ type: actionType, payload: payload });
    handleTurnOfValidation(data[0][0], dispatch);

    payload = prepareAfterActionReportsData(data[0][0], dispatch); // need to change
    actionType = GET_AFTER_ACTION_REPORT;

    dispatch({ type: actionType, payload: payload });
    dispatch(setListBodyCells('actionReport'));
    dispatch(setListBodyCells('actionReportParties'));
    dispatch(setListBodyCells('actionReportReviews'));
  };
};

//TODO: This function will be REMOVED
export const getEntitiesByIncidentId = (entity, q = {}) => {
  return async (dispatch) => {
    const { incident } = store.store.getState();
    const selectedIncId = incident.ptsIncidentId;
    let query = {};

    if (['parties', 'suspects'].includes(entity)) {
      entity = 'parties';
      query['contact'] = true;
    }

    return true;
  };
};

export const getCityIdFromCityName = (cityName) => {
  const { codeCities } = store.store.getState().dictionary;

  if (!Array.isArray(codeCities)) {
    return;
  }

  for (const city of codeCities) {
    if (city.CityDescription === cityName) {
      return city.ptsCityID;
    }
  }
};

export const upsertAddress = async (data, records = false, dispatch) => {
  let { addressForm, addressIdForm, coordinatesForm, incident, addresses } = data;
  const user = store.store.getState().user.userData?.user.Username;
  const client = store.store.getState().websocket.websocket;

  if (!addressForm) return;

  if (!client) {
    return;
  }

  if (typeof addressForm.values?.city === 'string') {
    addressForm.values.cityId = getCityIdFromCityName(addressForm.values?.city);
  }

  if (Object.keys(addressForm.values).length <= 1) return;

  // If it is first address of this incident, then it is primary address
  if (addresses.length === 0) {
    addressForm = {
      ...addressForm,
      values: {
        ...addressForm.values,
        isPrimary: true,
      },
    };
  }
  let placeData = 0;
  if (
    addressForm.values?.partyType ||
    addressForm.values?.placeType ||
    addressForm.values?.placeORI ||
    addressForm.values?.placeName ||
    addressForm.values?.placeDepID ||
    addressForm.values?.placeClearanceLevel
  ) {
    placeData = {
      ptsIncidentId: incident.ptsIncidentId,
      ptsPlaceId: addressForm?.ptsPlaceId || addressForm.values?.ptsPlaceId,
      values: {
        partyType: addressForm.values?.partyType ?? '',
        placeType: addressForm.values?.placeType ?? '',
        placeORI: addressForm.values?.placeORI ?? '',
        placeName: addressForm.values?.placeName ?? '',
        placeDepID: addressForm.values?.placeDepID ?? '',
        placeId: addressForm.values?.placeId ?? '',
        placeClearanceLevel: addressForm.values?.placeClearanceLevel,
        destination: addressForm.values?.destination ? true : false,
      },
    };
  }

  if (addressForm.values?.ptsIncPlaceId)
    placeData.ptsIncPlaceId = addressForm.values?.ptsIncPlaceId;

  const addressRequestBody = {
    ptsAddressId: addressForm?.ptsAddressId,
    ptsLocationId: incident.ptsLocationId,
    tempAddressId: addressForm.tempAddressId,
    addressDetails: addressForm,
    addressIdDetails: addressIdForm,
    addressCoordinatesDetails: coordinatesForm,
    placeData: placeData,
    user: user,
  };

  let service;
  if (records) {
    service = client.service('record-address');

    // Address Duplicate Checking for Records
    // let duplicateFound = await duplicateCheck(addressRequestBody.addressDetails, 'address');
    // if (duplicateFound) {
    //   dispatch(notify('Duplicate Found.', 'warning'));
    //   return;
    // }

    service.timeout = 20000;

    let response;
    if (addressForm?.ptsAddressId) {
      response = await service.patch(addressForm?.ptsAddressId, addressRequestBody);
    } else {
      response = await service.create(addressRequestBody);
    }

    // Change the flag
    data.changed = false;
    const attachments = addressForm.values.attachments;
    let attachmentdetails = await getAttachmentsRMS(response.ptsAddressId, 'ADDRESS');
    while (attachments && attachments.length !== attachmentdetails.length) {
      attachmentdetails = await getAttachmentsRMS(response.ptsAddressId, 'ADDRESS');
    }

    dispatch(
      updateIncAddressForm({
        ...addressForm,
        values: { ...addressForm.values, attachments: attachmentdetails },
      })
    );

    return {
      ptsAddressId: response.ptsAddressID,
      ptsCoordinateId:
        response?.locationAddress?.ptsCoordinateID || response?.ptsCoordinates.ptsCoordinateID,
    };
  } else {
    service = client.service('rms-address');

    // Address Duplicate Checking for Incident Address
    // let duplicateFound = !addressForm?.ptsAddressId
    //   ? await duplicateCheck(addressRequestBody.addressDetails, 'address')
    //   : false;

    // if (duplicateFound) {
    //   dispatch(notify('Duplicate Found.', 'warning'));
    //   return;
    // }

    service.timeout = 20000;

    let response;
    if (addressForm?.ptsAddressId) {
      response = await service.patch(addressForm?.ptsAddressId, addressRequestBody);
    } else {
      response = await service.create(addressRequestBody);
    }

    // Change the flag
    data.changed = false;
    dispatch(
      updateIncAddressForm({
        ...addressForm,
        values: {
          ...addressForm.values,
          created: response.locationAddress?.Created,
          createdBy: response.locationAddress?.CreatedBy,
          updated: response.locationAddress?.Updated,
          updatedBy: response.locationAddress?.UpdatedBy,
        },
      })
    );
    return {
      ptsAddressId: response.locationAddress?.ptsAddressID,
      ptsCoordinateId: response?.locationAddress?.ptsCoordinateID,
      ptsPlaceId: response?.locationAddress?.ptsPlaceID,
      ptsIncPlaceId: response?.locationAddress?.ptsIncPlaceID,
      isVictim: response?.newPlace?.partyPlace?.Relationship === 'VIC',
      addressDetails: {
        ptsPlaceId: response?.locationAddress?.ptsPlaceID,
        ptsIncPlaceId: response?.locationAddress?.ptsIncPlaceID,
        ptsAddressId: response.locationAddress?.ptsAddressID,
        values: {
          ptsLocationId: response.locationAddress?.ptsLocationID,
          fullAddressText: response.locationAddress?.FullAddressText,
          created: response.locationAddress?.Created,
          updated: response.locationAddress?.Updated,
          updatedBy: response.locationAddress?.UpdatedBy,
          updatedIp: response.locationAddress?.UpdatedIP,
          addressNumberPrefix: response.locationAddress?.AddressNumberPrefix,
          addressNumberSuffix: response.locationAddress?.AddressNumberSuffix,
          addressNumber: response.locationAddress?.AddressNumber,
          streetName: response.locationAddress?.StreetName,
          preDirection: response.locationAddress?.PreDirection,
          postDirection: response.locationAddress?.PostDirection,
          city: getCityName(response?.locationAddress?.ptsCityID),
          state: response.locationAddress?.State,
          county: response.locationAddress?.County,
          country: response.locationAddress?.Country,
          postalCode: response.locationAddress?.PostalCode,
          postalCodeExtension: response?.locationAddress?.PostalCodeExtension,
          streetType: response?.locationAddress?.StreetType,
          notes: response?.locationAddress?.Notes,
          isPrimary: response?.locationAddress?.IsPrimary,
          placeName: response?.newPlace?.partyPlace?.PlaceName || '',
          placeType: response?.newPlace?.partyPlace?.PlaceType || '',
          placeORI: response?.newPlace?.partyPlace?.PlaceORI || '',
          placeDepID: response?.newPlace?.partyPlace?.PlaceDepartmentID || '',
          placeId: response?.newPlace?.partyPlace?.PlaceID || '',
          partyType: response?.newPlace?.partyPlace?.Relationship || '',
          ptsPlaceId: response?.locationAddress?.ptsPlaceID,
          ptsIncPlaceId: response?.locationAddress?.ptsIncPlaceID,
          addressPlaces: response?.newPlace?.partyPlace?.PlaceName || '',
          placeClearanceLevel: response?.newPlace?.partyPlace?.ClearanceLevel,
          unitType: response?.locationAddress?.UnitType,
          unitTypeIdentifier: response?.locationAddress?.UnitIdentifier,
        },
      },
      addressIdDetails: {
        ptsAddressId: response.locationAddress?.ptsAddressID,
        ptsLocationId: incident.ptsLocationId,
        values: {
          isEventLookup: response?.locationAddress?.ShowInEventLookup,
          isSexOffender: response?.locationAddress?.SexOffender,
          isParolee: response?.locationAddress?.Parolee,
          isProbation: response?.locationAddress?.Probation,
          isNonValidated: response?.locationAddress?.NonValidatedAddress,
          isCampusResidential: response?.locationAddress?.IsCampusResidential,
          isCampusNonResidential: response?.locationAddress?.IsCampusNonResidential,
          isNonCampus: response?.locationAddress?.IsNonCampus,
          isPublic: response?.locationAddress?.IsPublic,
          floorType: response?.locationAddress?.FloorType,
          floorIdentifier: response?.locationAddress?.FloorIdentifier,
          buildingType: response?.locationAddress?.BldgType,
          buildingIdentifier: response?.locationAddress?.BldgIdentifier,
        },
      },
      addressCoordinatesDetails: {
        ptsAddressId: response.locationAddress?.ptsAddressID,
        ptsLocationId: incident.ptsLocationId,
        ptsCoordinateId: response?.locationAddress?.ptsCoordinateID,
        values: {
          incidentId: data?.ptsIncidentId,
          coordinateId: response?.locationAddress?.ptsCoordinateID,
          latitudeSign: response?.locationAddress.LatitudeSign,
          latitudeDegree: response?.locationAddress.LatitudeDegree,
          longitudeSign: response?.locationAddress.LongitudeSign,
          longitudeDegree: response?.locationAddress.LongitudeDegree,
          uncertaintyDistance: response?.locationAddress.UnCertaintyDistance,
          datum: response?.locationAddress.Datum,
          coordinateDatetime: response?.locationAddress.CoordinateDT,
          altitude: response?.locationAddress.Altitude,
          altitudePor: response?.locationAddress.AltitudeRefPoint,
          altitudeUom: response?.locationAddress.AltitudeUnitMeasure,
          cellTower: response?.locationAddress.CellIDText,
          sectorId: response?.locationAddress.CellSectorIDText,
          speed: response?.locationAddress.Speed,
          speedUom: response?.locationAddress.SpeedUnitMeasure,
        },
      },
    };
  }
};

export const upsertPartyPlace = async (data) => {
  let { partiesPlaceForm, incident } = data;
  const user = store.store.getState().user.userData?.user.Username;
  const client = store.store.getState().websocket.websocket;

  if (!partiesPlaceForm) return;

  if (!client) return;

  const partyPlaceRequestBody = {
    ptsPlaceId: partiesPlaceForm?.ptsPlaceId,
    placeId: partiesPlaceForm.values?.placeId,
    ptsIncidentId: incident.ptsIncidentId,
    relationship: partiesPlaceForm?.partyType,
    partyPlaceDetails: partiesPlaceForm,
    user: user,
  };

  let service = client.service('rms-party-place');
  service.timeout = 20000;

  let response;
  if (partiesPlaceForm?.ptsPlaceId)
    response = await service.patch(partiesPlaceForm?.ptsPlaceId, partyPlaceRequestBody);
  else response = await service.create(partyPlaceRequestBody);

  data.changed = false;

  return {
    ptsPlaceId: response.partyPlace.ptsPlaceID,
    ptsIncPlaceId: response.partyPlace.ptsIncPlaceID,
    placeId: response.partyPlace.PlaceID,
    addressDetails: {
      ptsPlaceId: response.partyPlace.ptsPlaceID,
      ptsIncPlaceId: response.partyPlace.ptsIncPlaceID,
      placeId: response.partyPlace.PlaceID,
      values: {
        partyType: response.partyPlace.Relationship,
        placeORI: response.partyPlace.PlaceORI,
        placeDepID: response.partyPlace.PlaceDepartmentID,
        placeName: response.partyPlace.PlaceName,
        destination: response.partyPlace.IsDestination,
        placeType: response.partyPlace.PlaceType,
        updatedBy: response.partyPlace.UpdatedBy,
      },
    },
  };
};

export const upsertIncident = async (data) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData?.user.Username;
  const userAgency = store.store.getState().user.userAgency;
  const codeIncidentStatus = store.store.getState().dictionary.codeIncidentStatus;

  if (!client) {
    return;
  }

  const service = client.service('rms-incident');
  service.timeout = 100000;

  const defaultIncidentStatus = codeIncidentStatus.find((status) => status.IsDefault === true);

  let requestBody = {
    agencyID: userAgency,
    lighting: data.values?.lighting,
    ibrStatus: data.values?.ibrStatus ?? '',
    ibrSubStatus: data.values?.ibrSubStatus ?? '',
    weather: data.values?.weather ?? '',
    callType: data.values?.callType ?? '',
    incidentCategory: data.values?.incidentCategory ?? '',
    tempIncidentId: data.values?.tempIncidentId,
    status: data.values?.status || defaultIncidentStatus?.Code || '',
    occurred: data.values?.occurred
      ? formatSaveDate(data.values?.occurred)
      : formatSaveDate(getCurrentDate()),
    ibrSubStatusDate: data.values?.ibrSubStatusDate
      ? formatSaveDate(data.values?.ibrSubStatusDate)
      : null,
    occurredTo: data.values?.occurredTo ? formatSaveDate(data.values?.occurredTo) : null,
    locationCategory: data.values?.locationCategory ?? '',
    locationSubdivision: data.values.locationSubdivision,
    locationName: data.values.locationName,
    locationHighway: data.values.locationHighway,
    locationLandmarkText: data.values.locationLandmarkText,
    locationCrossStreet: data.values.locationCrossStreet,
    received: data.values?.received ? formatSaveDate(data.values?.received) : null,
    dispatched: data.values?.dispatched ? formatSaveDate(data.values?.dispatched) : null,
    underCont: data.values?.underCont ? formatSaveDate(data.values?.underCont) : null,
    completed: data.values?.completed ? formatSaveDate(data.values?.completed) : null,
    arrived: data.values?.arrived ? formatSaveDate(data.values?.arrived) : null,
    enroute: data.values?.enroute ? formatSaveDate(data.values?.enroute) : null,
    attachments: data.values?.attachments,
    synopsis: data.values?.synopsis,
    incidentClearanceLevel: data.values?.incidentClearanceLevel,
    ibrIsSubmitted: false,
    ibrLastChange: formatSaveDate(getCurrentDate()),
    StatusDate: data.values?.StatusDate ? formatSaveDate(data.values?.StatusDate) : null,
    isLocked: data.values?.isLocked || false,
    lockedBy: data.values?.lockedBy || null,
    lockedByPersonId: data.values?.lockedByPersonId || null,
    locked: data.values?.locked ? formatSaveDate(data.values?.locked) : null,
    lockedReasons: data.values?.lockedReasons || null,
    lockedComments: data.values?.lockedComments || null,
    promptToRelock: data.values?.promptToRelock || false,
  };

  let response;
  if (data?.ptsIncidentId > 0) {
    requestBody = { ...requestBody, createdBy: data.values?.CreatedBy, updatedBy: user };
    response = await service.patch(data?.ptsIncidentId, requestBody);
  } else {
    requestBody = { ...requestBody, createdBy: user, updatedBy: user };
    response = await service.create(requestBody);
  }

  // Change the flag
  data.changes = false;

  //if (response.ptsIncidentID) changeIBRSSubmittedState(response.ptsIncidentID, false);

  return {
    ptsIncidentId: response.ptsIncidentID,
    ptsLocationId: response.location?.ptsLocationID,
    CustomId: response.IncidentID,
    values: {
      callType: response?.CallType,
      status: response?.Status,
      incidentCategory: response?.IncidentCategory,
      weather: response?.Weather,
      lighting: response?.Lighting,
      ibrStatus: response?.ibrStatus,
      ibrSubStatus: response?.ibrSubStatus,
      locationCategory: response.location?.LocationCategory,
      locationName: response.location?.Name,
      locationCrossStreet: response.location?.CrossStreet,
      locationHighway: response.location?.Highway,
      locationLandmarkText: response.location?.LandmarkText,
      locationSubdivision: response.location?.Subdivision,
      occurred: response?.Occurred,
      occurredTo: response?.OccurredTo,
      received: response?.Received,
      dispatched: response?.Dispatched,
      enroute: response?.Enroute,
      arrived: response?.Arrived,
      underCont: response?.UnderCont,
      completed: response?.Completed,
      updated: response?.Updated,
      synopsis: response?.Synopsis,
      ibrSubStatusDate: response?.ibrSubStatusDate
        ? `${response?.ibrSubStatusDate}T00:00:00`
        : response?.ibrSubStatusDate,
      incidentClearanceLevel: response?.ClearanceLevel,
      isLocked: response?.IsLocked,
      lockedBy: response?.LockedBy,
      lockedByPersonId: response?.LockedByPersonID,
      locked: response?.Locked ? displayDateTime(response?.Locked) : null,
      lockedReasons: response?.LockedReasons,
      lockedComments: response?.LockedComments,
      createdBy: user,
      updatedBy: user,
    },
  };
};

export const upsertProperties = async (data, records = false, dispatch) => {
  const {
    ptsIncidentId,
    propertyItemForm,
    propertyFirearmForm,
    propertyStructureForm,
    propertyVehicleDetailsForm,
    propertySubstanceForm,
    propertyJewelryForm,
    propertyCredentialsAnalysisForm,
    evidenceItemForm,
  } = data;

  // Vehicle Duplicate Checking
  // if (
  //   (propertyVehicleDetailsForm.changes || propertyCredentialsAnalysisForm.changes) &&
  //   Object.keys(propertyVehicleDetailsForm.values).length !== 0 &&
  //   Object.keys(propertyCredentialsAnalysisForm.values).length !== 0 &&
  //   propertyCredentialsAnalysisForm.values.vin !== undefined
  // ) {
  //   let duplicateFound = await duplicateCheck(propertyCredentialsAnalysisForm, 'vehicle');

  //   if (duplicateFound) {
  //     dispatch(notify('Duplicate Found.', 'warning'));
  //     return;
  //   }
  // }

  const user = store.store.getState().user.userData?.user.Username;
  const ptsPersonID = store.store.getState().user.userData?.user.ptsPersonID;
  const currentState = store.store.getState().incident;
  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }

  let requestBody = {};
  let response;
  let incIncidentPropertyId = 0;
  let propertyId = 0;
  let serviceName = 'rms-property-item';
  let parentType = 'Item';

  if (propertyItemForm.changes && Object.keys(propertyItemForm.values).length > 0) {
    propertyItemForm.values.quantity = Number.isInteger(Number(propertyItemForm.values.quantity))
      ? Number(propertyItemForm.values.quantity)
      : 0;

    incIncidentPropertyId = propertyItemForm.incIncidentPropertyId;
    propertyId = propertyItemForm.values.ptsItemID;
    requestBody = {
      Condition: propertyItemForm.values.condition,
      SerialNumber: propertyItemForm.values.serialNumber,
      Model: propertyItemForm.values.model,
      Make: propertyItemForm.values.make,
      Description: propertyItemForm.values.description,
      Name: propertyItemForm.values.name,
      Quantity: propertyItemForm.values.quantity,
      ClearanceLevel: propertyItemForm.values.clearanceLevel || '0',
      PropertyOwner: propertyItemForm.values.propertyOwner || '',
      PropertyOwnerType: propertyItemForm.values.propertyOwnerType || '',
      Value: propertyItemForm.values.value,
      attachments: propertyItemForm.values.attachments,
      ptsPersonID: ptsPersonID,
      ptsPropertyStorageID: propertyItemForm.values?.ptsPropertyStorageId || 0,
      PropertyType: propertyItemForm.values.parentType,
      StorageLocation: propertyItemForm.values.storageLocation,
      StoredDateTime: propertyItemForm.values.storedDateTime,
      Disposition: propertyItemForm.values.disposition,
      DispositionDateTime: propertyItemForm.values.dispositionDateTime,
      CreatedBy: propertyItemForm.values.createdBy || user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
      ptsItemID: propertyItemForm.values.ptsItemID,
    };
  } else if (propertyFirearmForm.changes && Object.keys(propertyFirearmForm.values).length > 0) {
    parentType = 'Firearm';
    serviceName = 'rms-property-firearm';
    incIncidentPropertyId = propertyFirearmForm.incIncidentPropertyId;
    propertyId = propertyFirearmForm.values.ptsFirearmID;
    requestBody = {
      FirearmCategory: propertyFirearmForm.values.firearmCategory,
      IsAutomatic: propertyFirearmForm.values.automatic || false,
      BarrelLength: Number.isInteger(Number(propertyFirearmForm.values.barrelLength))
        ? Number(propertyFirearmForm.values.barrelLength)
        : 0,
      BarrelLengthMeasure: propertyFirearmForm.values.barrelLengthMeasure,
      Caliber: propertyFirearmForm.values.caliber,
      Make: propertyFirearmForm.values.make,
      Model: propertyFirearmForm.values.model,
      Description: propertyFirearmForm.values.description,
      SerialNumber: propertyFirearmForm.values.serialNumber,
      Condition: propertyFirearmForm.values.condition,
      ClearanceLevel: propertyFirearmForm.values.clearanceLevel || '0',
      PropertyOwner: propertyFirearmForm.values.propertyOwner || '',
      PropertyOwnerType: propertyFirearmForm.values.propertyOwnerType || '',
      Value: propertyFirearmForm.values.value,
      attachments: propertyFirearmForm.values.attachments,
      ptsPersonID: ptsPersonID,
      ptsPropertyStorageID: propertyFirearmForm.values?.ptsPropertyStorageId || 0,
      PropertyType: propertyFirearmForm.values.parentType,
      StorageLocation: propertyFirearmForm.values.storageLocation,
      StoredDateTime: propertyFirearmForm.values.storedDateTime,
      Disposition: propertyFirearmForm.values.disposition,
      DispositionDateTime: propertyFirearmForm.values.dispositionDateTime,
      CreatedBy: propertyFirearmForm.values.createdBy || user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
      ptsFirearmID: propertyFirearmForm.values.ptsFirearmID,
    };
  } else if (
    propertyStructureForm.changes &&
    Object.keys(propertyStructureForm.values).length > 0
  ) {
    parentType = 'Structure';
    serviceName = 'rms-property-structure';
    incIncidentPropertyId = propertyStructureForm.incIncidentPropertyId;
    propertyId = propertyStructureForm.values.ptsStructureID;
    requestBody = {
      Description: propertyStructureForm.values.description,
      PrimaryColor: propertyStructureForm.values.primaryColor,
      SecondaryColor: propertyStructureForm.values.secondaryColor,
      GarageType: propertyStructureForm.values.garageType,
      Notes: propertyStructureForm.values.notes,
      IsResidence: propertyStructureForm.values.isResidence,
      BedroomQty: Number.isInteger(Number(propertyStructureForm.values.bedroomQuantity))
        ? Number(propertyStructureForm.values.bedroomQuantity)
        : 0,
      BathroomQty: Number.isInteger(Number(propertyStructureForm.values.bathroomQuantity))
        ? Number(propertyStructureForm.values.bedroomQuantity)
        : 0,
      ClearanceLevel: propertyStructureForm.values.clearanceLevel || '0',
      PropertyOwner: propertyStructureForm.values.propertyOwner || '',
      PropertyOwnerType: propertyStructureForm.values.propertyOwnerType || '',
      Value: propertyStructureForm.values.value,
      attachments: propertyStructureForm.values.attachments,
      ptsPersonID: ptsPersonID,
      ptsPropertyStorageID: propertyStructureForm.values?.ptsPropertyStorageId || 0,
      PropertyType: propertyStructureForm.values.parentType,
      StorageLocation: propertyStructureForm.values.storageLocation,
      StoredDateTime: propertyStructureForm.values.storedDateTime,
      Disposition: propertyStructureForm.values.disposition,
      DispositionDateTime: propertyStructureForm.values.dispositionDateTime,
      CreatedBy: propertyStructureForm.values.createdBy || user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
      ptsStructureID: propertyStructureForm.values.ptsStructureID,
    };
  } else if (
    propertySubstanceForm.changes &&
    Object.keys(propertySubstanceForm.values).length > 0
  ) {
    propertySubstanceForm.values.quantity = Number.isInteger(
      Number(propertySubstanceForm.values.quantity)
    )
      ? Number(propertySubstanceForm.values.quantity)
      : 0;

    parentType = 'Substance';
    serviceName = 'rms-property-substance';
    incIncidentPropertyId = propertySubstanceForm.incIncidentPropertyId;
    propertyId = propertySubstanceForm.values.ptsSubstanceID;
    requestBody = {
      SubstanceType: propertySubstanceForm.values.substanceType,
      Designator: propertySubstanceForm.values.designator,
      Container: propertySubstanceForm.values.container,
      Quantity: propertySubstanceForm.values.quantity,
      Composition: propertySubstanceForm.values.composition,
      Form: propertySubstanceForm.values.form,
      Description: propertySubstanceForm.values.description,
      DrugDaysSupply: Number.isInteger(Number(propertySubstanceForm.values.substanceDaysSupply))
        ? Number(propertySubstanceForm.values.substanceDaysSupply)
        : 0,
      DrugStrength: propertySubstanceForm.values.substanceStrength,
      DEAClass: propertySubstanceForm.values.deaClass,
      ptsPrescribingPlaceID: propertySubstanceForm.values.placeId,
      ptsPrescribingPersonID: propertySubstanceForm.values.ptsPrescribingPersonID,
      QuantityMeasure: propertySubstanceForm.values.quantityMeasure,
      ClearanceLevel: propertySubstanceForm.values.clearanceLevel || '0',
      PropertyOwner: propertySubstanceForm.values.propertyOwner || '',
      PropertyOwnerType: propertySubstanceForm.values.propertyOwnerType || '',
      Value: propertySubstanceForm.values.value,
      attachments: propertySubstanceForm.values.attachments,
      ptsPersonID: ptsPersonID,
      ptsPropertyStorageID: propertySubstanceForm.values?.ptsPropertyStorageId || 0,
      PropertyType: propertySubstanceForm.values.parentType,
      StorageLocation: propertySubstanceForm.values.storageLocation,
      StoredDateTime: propertySubstanceForm.values.storedDateTime,
      Disposition: propertySubstanceForm.values.disposition,
      DispositionDateTime: propertySubstanceForm.values.dispositionDateTime,
      CreatedBy: propertySubstanceForm.values.createdBy || user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
      ptsSubstanceID: propertySubstanceForm.values.ptsSubstanceID,
    };
  } else if (propertyJewelryForm.changes && Object.keys(propertyJewelryForm.values).length > 0) {
    parentType = 'Jewelry';
    serviceName = 'rms-property-jewelry';
    incIncidentPropertyId = propertyJewelryForm.incIncidentPropertyId;
    propertyId = propertyJewelryForm.values.ptsJewelryID;
    requestBody = {
      Make: propertyJewelryForm.values.make,
      Model: propertyJewelryForm.values.model,
      Description: propertyJewelryForm.values.description,
      SerialNumber: propertyJewelryForm.values.serialNumber,
      Condition: propertyJewelryForm.values.condition,
      JewelryCategory: propertyJewelryForm.values.jewelryCategory,
      Sex: propertyJewelryForm.values.sex,
      Shape: propertyJewelryForm.values.shape,
      Arrangement: propertyJewelryForm.values.arrangement,
      Carat: Number.isInteger(Number(propertyJewelryForm.values.carat))
        ? Number(propertyJewelryForm.values.carat)
        : 0,
      Band: propertyJewelryForm.values.band,
      MainPendant: propertyJewelryForm.values.mainPendant,
      PendantQuantity: Number.isInteger(Number(propertyJewelryForm.values.pendantQuantity))
        ? Number(propertyJewelryForm.values.pendantQuantity)
        : 0,
      Material: propertyJewelryForm.values.material,
      ClearanceLevel: propertyJewelryForm.values.clearanceLevel || '0',
      PropertyOwner: propertyJewelryForm.values.propertyOwner || '',
      PropertyOwnerType: propertyJewelryForm.values.propertyOwnerType || '',
      Value: propertyJewelryForm.values.value,
      attachments: propertyJewelryForm.values.attachments,
      ptsPersonID: ptsPersonID,
      ptsPropertyStorageID: propertyJewelryForm.values?.ptsPropertyStorageId || 0,
      PropertyType: propertyJewelryForm.values.parentType,
      StorageLocation: propertyJewelryForm.values.storageLocation,
      StoredDateTime: propertyJewelryForm.values.storedDateTime,
      Disposition: propertyJewelryForm.values.disposition,
      DispositionDateTime: propertyJewelryForm.values.dispositionDateTime,
      CreatedBy: propertyJewelryForm.values.createdBy || user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
      ptsJewelryID: propertyJewelryForm.values.ptsJewelryID,
    };
  } else if (
    (propertyVehicleDetailsForm.changes || propertyCredentialsAnalysisForm.changes) &&
    Object.keys(propertyVehicleDetailsForm.values).length > 0
  ) {
    parentType = 'Vehicle';
    serviceName = 'rms-property-vehicle';
    incIncidentPropertyId = propertyVehicleDetailsForm.incIncidentPropertyId;
    propertyId = propertyVehicleDetailsForm.values.ptsVehicleID;
    requestBody = {
      CreatedBy: user,
      UpdatedBy: user,
      ClearanceLevel: propertyVehicleDetailsForm.values.clearanceLevel || '0',
      PropertyOwner: propertyVehicleDetailsForm.values.propertyOwner || '',
      ptsPersonID: ptsPersonID,
      ptsPropertyStorageID: propertyVehicleDetailsForm.values?.ptsPropertyStorageId || 0,
      PropertyType: propertyVehicleDetailsForm.values.parentType,
      StorageLocation: propertyVehicleDetailsForm.values.storageLocation,
      StoredDateTime: propertyVehicleDetailsForm.values.storedDateTime,
      Disposition: propertyVehicleDetailsForm.values.disposition,
      DispositionDateTime: propertyVehicleDetailsForm.values.dispositionDateTime,
      ptsVehicleID: propertyVehicleDetailsForm.values.ptsVehicleID,
      registrationDetails: {
        ptsRegistrationId: propertyVehicleDetailsForm.ptsRegistrationId,
        values: {
          ptsVehicleID: propertyId,
          PlateType: propertyCredentialsAnalysisForm.values.plateType,
          PlateColor: propertyCredentialsAnalysisForm.values.plateColor,
          PlateNumber: propertyCredentialsAnalysisForm.values.plateNumber,
          PlateState: propertyCredentialsAnalysisForm.values.plateState,
          PlateStatus: propertyCredentialsAnalysisForm.values.plateStatus,
          PlateDetail: propertyCredentialsAnalysisForm.values?.plateDetail,
          PlateExpiration: propertyCredentialsAnalysisForm.values?.plateExpiration,
          PlateBackgroundText: propertyCredentialsAnalysisForm.values?.plateBackgroundText,
          ptsParentID: ptsIncidentId,
          PartyType: 'INCIDENT',
        },
      },
      vehicleDetails: {
        values: {
          Brand: propertyVehicleDetailsForm.values.brand,
          Brander: propertyVehicleDetailsForm.values.brander,
          Commercial: propertyCredentialsAnalysisForm.values.commercialCode,
          IsCMVIndicator: propertyCredentialsAnalysisForm.values.cmv,
          GarageFacility: propertyCredentialsAnalysisForm.values.garageFacility,
          GarageIndicator: propertyCredentialsAnalysisForm.values.garageIndicator,
          VIN: propertyCredentialsAnalysisForm.values.vin,
          VINAnalysisText: propertyCredentialsAnalysisForm.values.vinAnalysisTest,
          IsRented: propertyCredentialsAnalysisForm.values.rented,
          Classification: propertyVehicleDetailsForm.values.classification,
          PrimaryColor: propertyVehicleDetailsForm.values.primaryColor,
          SecondaryColor: propertyVehicleDetailsForm.values.secondaryColor,
          InteriorColor: propertyVehicleDetailsForm.values.interior,
          Make: propertyVehicleDetailsForm.values.make,
          Model: propertyVehicleDetailsForm.values.model,
          Style: propertyVehicleDetailsForm.values.style,
          Year: Number.isInteger(Number(propertyVehicleDetailsForm.values.year))
            ? Number(propertyVehicleDetailsForm.values.year)
            : 0,
          PassengerSafeQty: Number.isInteger(
            Number(propertyVehicleDetailsForm.values.passengerQuantity)
          )
            ? Number(propertyVehicleDetailsForm.values.passengerQuantity)
            : 0,
          Transmission: propertyCredentialsAnalysisForm.values.transmissionCode,
          IsWanted: propertyCredentialsAnalysisForm.values.wanted,
          Description: propertyVehicleDetailsForm.values.description,
          ClearanceLevel: propertyVehicleDetailsForm.values.clearanceLevel || '0',
          PropertyOwner: propertyVehicleDetailsForm.values.propertyOwner || '',
          PropertyOwnerType: propertyVehicleDetailsForm.values.propertyOwnerType || '',
          Value: propertyVehicleDetailsForm.values.value,
          CreatedBy: propertyVehicleDetailsForm.values.createdBy || user,
          UpdatedBy: user,
          IsDeleted: false,
          SeatQty: Number.isInteger(Number(propertyVehicleDetailsForm.values.seatQuantity))
            ? Number(propertyVehicleDetailsForm.values.seatQuantity)
            : 0,
          EmissionInspectionQty: Number.isInteger(
            Number(propertyCredentialsAnalysisForm.values.emissionInspection)
          )
            ? Number(propertyCredentialsAnalysisForm.values.emissionInspection)
            : 0,
          // assuming commercial code in then backend corresponds to commercialId in the front end
          CommercialID: propertyCredentialsAnalysisForm.values.commercialId,
          Property: propertyCredentialsAnalysisForm.values.propertyCode,
          PropertyID: propertyCredentialsAnalysisForm.values.propertyId,
          attachments: propertyVehicleDetailsForm.values.attachments,
        },
      },
    };
  }

  const service = client.service(serviceName);
  service.timeout = 20000;
  if (Object.keys(requestBody).length < 1) {
    return;
  }

  requestBody['ptsIncidentID'] = records ? 0 : ptsIncidentId;
  requestBody['incIncidentPropertyId'] = records ? 0 : incIncidentPropertyId;
  let reduxPayload;

  if (incIncidentPropertyId || propertyId) {
    response = await service.patch(propertyId, requestBody);
    if (parentType === 'Vehicle') {
      requestBody = {
        ...requestBody,
        ...requestBody.registrationDetails.values,
        ...requestBody.vehicleDetails.values,
      };
      delete requestBody.vehicleDetails;
      delete requestBody.registrationDetails;
    }

    reduxPayload = preparePropertyData(
      {
        Properties: [
          {
            ptsIncidentID: ptsIncidentId,
            incIncidentPropertyID: incIncidentPropertyId,
            PropertyType: parentType,
            [parentType]: response,
          },
        ],
      },
      false
    );

    for (let i = 0; i < currentState.properties.length; i++) {
      if (
        currentState.properties[i].propertyDetails.incIncidentPropertyId === incIncidentPropertyId
      ) {
        currentState.properties[i] = reduxPayload[0];
      }
    }
  } else {
    response = await service.create(requestBody);
    reduxPayload = preparePropertyData(
      {
        Properties: [
          {
            ptsIncidentID: response.ptsIncidentID,
            incIncidentPropertyID: response.incIncidentPropertyID,
            PropertyType: parentType,
            [parentType]: response,
          },
        ],
      },
      false
    );

    currentState.properties.push(reduxPayload[0]);
  }
  let key;
  let type = 'INCIDENTPROPERTY';
  if (records) {
    type = parentType.toUpperCase();
    switch (parentType) {
      case 'Item':
        key = propertyId || reduxPayload[0].propertyDetails.values.ptsItemID;
        break;
      case 'Firearm':
        key = propertyId || reduxPayload[0].propertyDetails.values.ptsFirearmID;
        break;
      case 'Structure':
        key = propertyId || reduxPayload[0].propertyDetails.values.ptsStructureID;
        break;
      case 'Substance':
        key = propertyId || reduxPayload[0].propertyDetails.values.ptsSubstanceID;
        break;
      case 'Jewelry':
        key = propertyId || reduxPayload[0].propertyDetails.values.ptsJewelryID;
        break;
      case 'Vehicle':
        key = propertyId || reduxPayload[0].propertyDetails.values.ptsVehicleID;
        break;
    }
  } else {
    key = incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
  }

  let attachmentDetails = await getAttachmentsRMS(key, type);
  // Saving Property as Evidence
  if (
    (propertyVehicleDetailsForm.values.isEvidence &&
      propertyVehicleDetailsForm.values.ptsVehicleID === undefined) ||
    (propertyJewelryForm.values.isEvidence &&
      propertyJewelryForm.values.ptsJewelryID === undefined) ||
    (propertySubstanceForm.values.isEvidence &&
      propertySubstanceForm.values.ptsSubstanceID === undefined) ||
    (propertyStructureForm.values.isEvidence &&
      propertyStructureForm.values.ptsStructureID === undefined) ||
    (propertyFirearmForm.values.isEvidence &&
      propertyFirearmForm.values.ptsFirearmID === undefined) ||
    (propertyItemForm.values.isEvidence && propertyItemForm.values.ptsItemID === undefined)
  ) {
    let evidenceForm = {
      ...evidenceItemForm,
      values: {
        ...evidenceItemForm.values,
        incIncidentPropertyId: response.incIncidentPropertyID,
      },
    };

    const res = await addNewEvidenceItem(evidenceForm, ptsIncidentId, []);
  }
  if (parentType === 'Item') {
    const attachments = propertyItemForm.values.attachments;
    propertyItemForm.incIncidentPropertyId =
      incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
    propertyItemForm.values.ptsItemID =
      propertyId || reduxPayload[0].propertyDetails.values.ptsItemID;

    propertyItemForm.values.ptsPropertyStorageId =
      reduxPayload[0].propertyDetails.values.ptsPropertyStorageID;

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(key, type);

    dispatch(
      updateIncPropertyItemForm({
        ...propertyItemForm,
        values: {
          ...propertyItemForm.values,
          created: reduxPayload[0].propertyDetails.values.created,
          updated: reduxPayload[0].propertyDetails.values.updated,
          createdBy: reduxPayload[0].propertyDetails.values.createdBy,
          updatedBy: reduxPayload[0].propertyDetails.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
  } else if (parentType === 'Firearm') {
    const attachments = propertyFirearmForm.values.attachments;
    propertyFirearmForm.incIncidentPropertyId =
      incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
    propertyFirearmForm.values.ptsFirearmID =
      propertyId || reduxPayload[0].propertyDetails.values.ptsFirearmID;

    propertyFirearmForm.values.ptsPropertyStorageId =
      reduxPayload[0].propertyDetails.values.ptsPropertyStorageID;

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(key, type);

    dispatch(
      updateIncPropertyFirearmForm({
        ...propertyFirearmForm,
        values: {
          ...propertyFirearmForm.values,
          created: reduxPayload[0].propertyDetails.values.created,
          updated: reduxPayload[0].propertyDetails.values.updated,
          createdBy: reduxPayload[0].propertyDetails.values.createdBy,
          updatedBy: reduxPayload[0].propertyDetails.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
  } else if (parentType === 'Structure') {
    const attachments = propertyStructureForm.values.attachments;
    propertyStructureForm.incIncidentPropertyId =
      incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
    propertyStructureForm.values.ptsStructureID =
      propertyId || reduxPayload[0].propertyDetails.values.ptsStructureID;

    propertyStructureForm.values.ptsPropertyStorageId =
      reduxPayload[0].propertyDetails.values.ptsPropertyStorageID;

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(key, type);

    dispatch(
      updateIncPropertyStructureForm({
        ...propertyStructureForm,
        values: {
          ...propertyStructureForm.values,
          created: reduxPayload[0].propertyDetails.values.created,
          updated: reduxPayload[0].propertyDetails.values.updated,
          createdBy: reduxPayload[0].propertyDetails.values.createdBy,
          updatedBy: reduxPayload[0].propertyDetails.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
  } else if (parentType === 'Substance') {
    const attachments = propertySubstanceForm.values.attachments;
    propertySubstanceForm.incIncidentPropertyId =
      incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
    propertySubstanceForm.values.ptsSubstanceID =
      propertyId || reduxPayload[0].propertyDetails.values.ptsSubstanceID;

    propertySubstanceForm.values.ptsPropertyStorageId =
      reduxPayload[0].propertyDetails.values.ptsPropertyStorageID;

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(key, type);

    dispatch(
      updateIncPropertySubstanceForm({
        ...propertySubstanceForm,
        values: {
          ...propertySubstanceForm.values,
          created: reduxPayload[0].propertyDetails.values.created,
          updated: reduxPayload[0].propertyDetails.values.updated,
          createdBy: reduxPayload[0].propertyDetails.values.createdBy,
          updatedBy: reduxPayload[0].propertyDetails.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
  } else if (parentType === 'Jewelry') {
    const attachments = propertyJewelryForm.values.attachments;
    propertyJewelryForm.incIncidentPropertyId =
      incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
    propertyJewelryForm.values.ptsJewelryID =
      propertyId || reduxPayload[0].propertyDetails.values.ptsJewelryID;

    propertyJewelryForm.values.ptsPropertyStorageId =
      reduxPayload[0].propertyDetails.values.ptsPropertyStorageID;

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(key, type);

    dispatch(
      updateIncPropertyJewelryForm({
        ...propertyJewelryForm,
        values: {
          ...propertyJewelryForm.values,
          created: reduxPayload[0].propertyDetails.values.created,
          updated: reduxPayload[0].propertyDetails.values.updated,
          createdBy: reduxPayload[0].propertyDetails.values.createdBy,
          updatedBy: reduxPayload[0].propertyDetails.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
  } else if (parentType === 'Vehicle') {
    const attachments = propertyVehicleDetailsForm.values.attachments;
    propertyVehicleDetailsForm.incIncidentPropertyId =
      incIncidentPropertyId || reduxPayload[0].propertyDetails.values.incIncidentPropertyId;
    propertyVehicleDetailsForm.values.ptsVehicleID =
      propertyId || reduxPayload[0].propertyDetails.values.ptsVehicleID;

    propertyVehicleDetailsForm.values.ptsPropertyStorageId =
      reduxPayload[0].propertyDetails.values.ptsPropertyStorageID;

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(key, type);

    dispatch(
      updateIncPropertyVehicleDetailsForm({
        ...propertyVehicleDetailsForm,
        values: {
          ...propertyVehicleDetailsForm.values,
          created: reduxPayload[0].propertyDetails.values.created,
          updated: reduxPayload[0].propertyDetails.values.updated,
          createdBy: reduxPayload[0].propertyDetails.values.createdBy,
          updatedBy: reduxPayload[0].propertyDetails.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
  }

  return currentState.properties;
};

export const upsertParty = async (data, dispatch) => {
  const {
    ptsIncidentId,
    partiesPersonForm,
    partiesContactForm,
    partiesEmploymentForm,
    partiesOthersForm,
    offensesMPBasicForm,
    mpRelatedRecords,
  } = data;
  const recordsState = store.store.getState().records;
  const user = store.store.getState().user.userData?.user.Username;
  const modalState = store.store.getState().uiModal;

  if (Object.keys(partiesPersonForm.values).length === 0) return;

  if (typeof partiesContactForm.values?.city === 'string') {
    partiesContactForm.values.cityId = getCityIdFromCityName(partiesContactForm.values?.city);
  }

  if (typeof partiesEmploymentForm.values?.city === 'string') {
    partiesEmploymentForm.values.cityId = getCityIdFromCityName(partiesEmploymentForm.values?.city);
  }

  /** Duplicate Checking */
  // modalState.duplicateModalMessage = '';
  // let duplicateFoundPerson,
  //   duplicateFoundContact,
  //   duplicateFoundEmployment = false;
  // Person
  // duplicateFoundPerson = !partiesPersonForm.ptsPersonId
  //   ? await duplicateCheck(partiesPersonForm, 'person')
  //   : false;
  // Employment Address
  // if (
  //   !partiesEmploymentForm.ptsAddressId &&
  //   Object.keys(partiesEmploymentForm.values).length !== 0
  // ) {
  //   duplicateFoundEmployment = await duplicateCheck(partiesEmploymentForm, 'address', 'employment');
  // }

  // Contact Address
  // if (!partiesContactForm.ptsAddressId && Object.keys(partiesContactForm.values).length !== 0) {
  //   duplicateFoundContact = await duplicateCheck(partiesContactForm, 'address', 'contact');
  // }

  // modalState.showDuplicateModal =
  //   duplicateFoundPerson || duplicateFoundContact || duplicateFoundEmployment;
  // if (duplicateFoundPerson || duplicateFoundContact || duplicateFoundEmployment) {
  //   dispatch(notify('Duplicate Found.', 'warning'));
  //   return;
  // }
  /** End Duplicate Checking */

  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }

  const service = client.service('rms-party');
  service.timeout = 100000;

  const requestBody = {
    ptsIncidentId,
    ptsPersonId: partiesPersonForm.ptsPersonId,
    ptsIncPersonId: partiesPersonForm.ptsIncPersonId,
    partyType: partiesPersonForm.values.partyType,
    personDetail: partiesPersonForm,
    contactDetail: partiesContactForm,
    employmentDetail: partiesEmploymentForm,
    otherDetail: partiesOthersForm,
    user: user,
  };

  if (
    getDataOfDictionaryOptions(
      'codePartyRelationship',
      'Code',
      'AllowMissingPerson',
      partiesPersonForm.values?.partyType
    ) === true &&
    Object.keys(offensesMPBasicForm.values).length !== 0 &&
    offensesMPBasicForm.changes === true
  ) {
    requestBody.mpBasicFormDetails = offensesMPBasicForm;
    requestBody.mpRelatedRecords = mpRelatedRecords;
  }

  let response;
  if (partiesPersonForm.ptsPersonId && recordsState.recordActive) {
    response = await service.patch(null, requestBody);
  } else {
    if (partiesPersonForm.ptsIncPersonId) {
      response = await service.patch(null, requestBody);
    } else {
      response = await service.create(requestBody);
    }

    let formatResponse = {
      ...response.partyPerson,
      PartiesAddresses: response?.partyContactInfo?.partyAddress,
      ContactInfo: [
        response?.partyContactInfo.contact1,
        response?.partyContactInfo.contact2,
        response?.partyContactInfo.contact3,
        response?.partyEmploymentInfo.contact1,
        response?.partyEmploymentInfo.contact2,
        response?.partyEmploymentInfo.contact3,
      ],
      PartiesEmployment: {
        ...response?.partyEmploymentInfo?.partyEmployment,
        Place: response?.partyEmploymentInfo?.place,
      },
    };

    if (response.mpBasic) {
      formatResponse.MPVictim = {
        ...response.mpBasic,
        RelatedRecords: response.mpRelatedRecords,
      };
    }

    const reduxPayload = preparePartyPersonData(
      {
        Parties: [formatResponse],
      },
      false
    );

    partiesPersonForm.ptsIncPersonId = reduxPayload[0].personDetail.ptsIncPersonId;
    partiesPersonForm.values.partyType = reduxPayload[0].personDetail.values.partyType;
    offensesMPBasicForm.ptsMPVictimId = reduxPayload[0].mpBasicDetail.ptsMPVictimId;

    const attachments = partiesPersonForm.values.attachments;
    let attachmentDetails = await getAttachmentsRMS(
      partiesPersonForm.ptsIncPersonId,
      'INCIDENTPERSON'
    );

    while (attachments && attachments.length !== attachmentDetails.length)
      attachmentDetails = await getAttachmentsRMS(
        partiesPersonForm.ptsIncPersonId,
        'INCIDENTPERSON'
      );

    // console.log('redux ', reduxPayload[0]);
    dispatch(
      updateIncPartiesPersonForm({
        ...partiesPersonForm,
        values: {
          ...partiesPersonForm.values,
          created: reduxPayload[0].personDetail.values.created,
          updated: reduxPayload[0].personDetail.values.updated,
          createdBy: reduxPayload[0].personDetail.values.createdBy,
          updatedBy: reduxPayload[0].personDetail.values.updatedBy,
          attachments: attachmentDetails,
        },
      })
    );
    dispatch(
      updateIncOffensesMPBasicForm({
        ...offensesMPBasicForm,
        values: {
          ...offensesMPBasicForm.values,
        },
      })
    );

    return reduxPayload[0];
  }
};

export const upsertEvidenceParty = async (data, dispatch) => {
  const { partiesPersonForm, partiesContactForm, partiesEmploymentForm, partiesOthersForm } = data;
  // const recordsState = store.store.getState().records;
  const user = store.store.getState().user.userData?.user.Username;
  // const modalState = store.store.getState().uiModal;

  if (Object.keys(partiesPersonForm.values).length === 0) return;

  if (typeof partiesContactForm.values?.city === 'string') {
    partiesContactForm.values.cityId = getCityIdFromCityName(partiesContactForm.values?.city);
  }

  if (typeof partiesEmploymentForm.values?.city === 'string') {
    partiesEmploymentForm.values.cityId = getCityIdFromCityName(partiesEmploymentForm.values?.city);
  }

  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }

  const service = client.service('rms-evidence-party');
  service.timeout = 100000;

  const requestBody = {
    ptsEvidenceId:
      data?.selectedEvidence?.ptsEvidenceID ||
      data?.selectedIncidentEvidence?.itemRecordDetail?.ptsEvidenceId,
    ptsPersonId: partiesPersonForm.ptsPersonId,
    ptsEvidencePersonId: partiesPersonForm.ptsEvidencePersonId,
    partyType: partiesPersonForm.values.partyType,
    personDetail: partiesPersonForm,
    contactDetail: partiesContactForm,
    employmentDetail: partiesEmploymentForm,
    otherDetail: partiesOthersForm,
    user: user,
  };

  let response;

  if (partiesPersonForm?.ptsEvidencePersonId) {
    response = await service.patch(null, requestBody);
  } else {
    response = await service.create(requestBody);
  }

  let formatResponse = {
    ...response.partyPerson,
    PartiesAddresses: response?.partyContactInfo?.partyAddress,
    ContactInfo: [
      response?.partyContactInfo.contact1,
      response?.partyContactInfo.contact2,
      response?.partyContactInfo.contact3,
      response?.partyEmploymentInfo.contact1,
      response?.partyEmploymentInfo.contact2,
      response?.partyEmploymentInfo.contact3,
    ],
    PartiesEmployment: {
      ...response?.partyEmploymentInfo?.partyEmployment,
      Place: response?.partyEmploymentInfo?.place,
    },
  };

  const reduxPayload = prepareEvidencePartyPersonData(
    {
      EvidenceParties: [formatResponse],
    },
    false
  );

  partiesPersonForm.ptsEvidencePersonId = reduxPayload[0].personDetail.ptsEvidencePersonId;
  partiesPersonForm.values.partyType = reduxPayload[0].personDetail.values.partyType;

  const attachments = partiesPersonForm.values.attachments;
  let attachmentDetails = await getAttachmentsRMS(
    partiesPersonForm.ptsIncPersonId,
    'EVIDENCEPERSON'
  );

  while (attachments && attachments.length !== attachmentDetails.length)
    attachmentDetails = await getAttachmentsRMS(partiesPersonForm.ptsIncPersonId, 'EVIDENCEPERSON');

  dispatch(
    updateEvidencePartiesPersonForm({
      ...partiesPersonForm,
      values: {
        ...partiesPersonForm.values,
        created: reduxPayload[0].personDetail.values.created,
        updated: reduxPayload[0].personDetail.values.updated,
        createdBy: reduxPayload[0].personDetail.values.createdBy,
        updatedBy: reduxPayload[0].personDetail.values.updatedBy,
        attachments: attachmentDetails,
      },
    })
  );

  return reduxPayload[0];
};

export const addEvidences = () => {
  const incState = store.store.getState().incident;
  if (
    !incState.evidences.some(
      (x) => x.itemRecordDetail.tempEvidenceId === incState.evidenceItemForm.tempEvidenceId
    )
  ) {
    if (Object.keys(incState.evidenceItemForm.values).length !== 0) {
      let evidence = {
        itemRecordDetail: incState.evidenceItemForm,
        custodyDetail: incState.evidenceCustodyForm,
        storageDetail: incState.evidenceStorageForm,
        dispositionDetail: incState.evidenceDispositionForm,
      };
      incState.evidences.push(evidence);
    }
  }
};

export const addNarratives = () => {
  const incState = store.store.getState().incident;
  if (
    !incState.narratives.some((x) => x.tempNarrativeId === incState.narrativeForm.tempNarrativeId)
  ) {
    if (Object.keys(incState.narrativeForm.values).length !== 0) {
      incState.narratives.push(incState.narrativeForm);
    }
  }
};

export const addIncSubjects = (party) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  if (party.personDetail.values?.partyType?.toLowerCase() === 'offender') {
    let subject = {
      incSubjectDetails: {
        ...initFormModel,
        changes: true,
        ptsOffenseId: offenseID,
        ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
        ptsIncPersonId: party.ptsIncPersonId,
      },
    };
    incState.incSubjects.push(subject);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncSubject = false;
};

export const addIncVictims = (party) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  if (
    getDataOfDictionaryOptions(
      'codePartyRelationship',
      'Code',
      'Category',
      party.personDetail.values?.partyType
    ) === 'VIC'
  ) {
    let victim = {
      incVictimDetails: {
        ...initFormModel,
        changes: true,
        ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
        ptsIncPersonId: party.ptsIncPersonId,
        ptsOffenseId: offenseID,
      },
    };

    if (party.personDetail.values?.firstName === 'Society')
      victim.incVictimDetails.values.category = getDataOfDictionaryOptions(
        'codeVictimCategory',
        'Category',
        'Code',
        'S'
      );

    incState.incVictims.push(victim);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncVictim = false;
};

export const addVictimRelationships = () => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();

  if (
    !incState.incRelationships.find(
      (x) =>
        x.incRelationshipDetails.ptsIncidentId ===
          incState.offensesRelationshipForm.ptsIncidentId &&
        x.incRelationshipDetails.values.relationship.offenderId ===
          incState.offensesRelationshipForm.values.relationship?.offenderId &&
        x.incRelationshipDetails.values.relationship.victimId ===
          incState.offensesRelationshipForm.values.relationship?.victimId
    )
  ) {
    if (incState.offensesRelationshipForm.values.relationship)
      incState.incRelationships.push({
        incRelationshipDetails: incState.offensesRelationshipForm,
      });
  } else {
    let updateRelationship = incState.incRelationships.find(
      (x) =>
        x.incRelationshipDetails.ptsIncidentId ===
          incState.offensesRelationshipForm.ptsIncidentId &&
        x.incRelationshipDetails.values.relationship.offenderId ===
          incState.offensesRelationshipForm.values.relationship?.offenderId &&
        x.incRelationshipDetails.values.relationship.victimId ===
          incState.offensesRelationshipForm.values.relationship?.victimId
    );
    if (updateRelationship !== undefined)
      updateRelationship.incRelationshipDetails = incState.offensesRelationshipForm;
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncRelationship = false;
};

export const addIncProperties = (prop) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  let property = {
    incPropertyDetails: {
      ...initFormModel,
      changes: true,
      incIncidentPropertyId: prop.propertyDetails.incIncidentPropertyId,
      ptsOffenseId: offenseID,
    },
  };
  incState.incProperties.push(property);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncProperty = false;
};

export const addIncArrestees = (party) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  let arrestee = {
    incArresteeDetails: {
      ...initFormModel,
      changes: true,
      ptsOffenseId: offenseID,
      ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
      ptsIncPersonId: party.ptsIncPersonId,
    },
  };
  incState.incArrestees.push(arrestee);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncArrestee = false;
};

export const addIncDVVictim = () => {
  const incState = store.store.getState().incident;
  let { offensesDVVictimForm, selectedVictim, incVictims, dvVictims, currentOffense } = incState;
  let isNew = true;
  setLinkedFormOffenseId();

  if (Object.keys(offensesDVVictimForm.values).length === 0) {
    return;
  }

  for (let i = 0; i < dvVictims.length; i++) {
    if (
      dvVictims[i].ptsIncPersonId === selectedVictim &&
      dvVictims[i].ptsOffenseId === currentOffense
    ) {
      isNew = false;
      dvVictims[i].values = offensesDVVictimForm.values;
      break;
    }
  }

  if (isNew) {
    offensesDVVictimForm.ptsVictimId = incVictims.find(
      (x) => x.incVictimDetails.ptsIncPersonId === selectedVictim
    )?.incVictimDetails?.ptsVictimId;
    offensesDVVictimForm.ptsIncPersonId = selectedVictim;
    dvVictims.push(offensesDVVictimForm);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncDVVictim = false;
};

export const addIncSuspects = (party) => {
  const incState = store.store.getState().incident;
  let { selectedVictim, incVictims } = incState;
  setLinkedFormOffenseId();

  if (incState.partiesPersonForm.values.partyType === 'Suspect') {
    let suspect = {
      incSuspectDetails: {
        ...initFormModel,
        changes: true,
        ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
        ptsIncPersonId: party.ptsIncPersonId,
        ptsVictimId: incVictims.find((x) => x.incVictimDetails.ptsIncPersonId === selectedVictim)
          ?.incVictimDetails?.ptsVictimId,
        ptsVictimPersonId: selectedVictim,
      },
    };

    incState.dvSuspects.push(suspect);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncSuspect = false;
};

export const addIncDVAdditional = () => {
  const incState = store.store.getState().incident;
  let isNew = true;
  let { offensesDVAdditionalForm, selectedVictim, incVictims, dvAdditionals } = incState;
  setLinkedFormOffenseId();

  if (Object.keys(offensesDVAdditionalForm.values).length === 0) {
    return;
  }

  for (let i = 0; i < dvAdditionals.length; i++) {
    if (dvAdditionals[i].ptsIncPersonId === selectedVictim) {
      isNew = false;
      dvAdditionals[i].values = offensesDVAdditionalForm.values;
      break;
    }
  }

  if (isNew) {
    offensesDVAdditionalForm.ptsVictimId = incVictims.find(
      (x) => x.incVictimDetails.ptsIncPersonId === selectedVictim
    )?.incVictimDetails?.ptsVictimId;
    offensesDVAdditionalForm.ptsIncPersonId = selectedVictim;
    incState.dvAdditionals.push(offensesDVAdditionalForm);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncDVAdditional = false;
};

export const addIncEvidenceCustody = (data) => {
  const incState = store.store.getState().incident;
  const currentUser = store.store.getState().user.userData?.user;
  const currentdate = formatSaveDate(getCurrentDate());

  let custody = {
    incEvidenceCustodyDetails: {
      ...initFormModel,
      values: {
        ...data.values,
        created: currentdate,
        createdBy: formatPersonFullName(currentUser),
      },
      changes: true,
      ptsEvidenceId: incState.evidenceItemForm.ptsEvidenceId,
      tempEvidenceCustodyId: data.tempEvidenceCustodyId,
    },
  };
  incState.incEvidenceCustody.push(custody); // updating redux, action name should be SET_LIST_EVIDENCE_CUSTODY

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncEvidenceCustody = false;
};

export const addIncEvidenceStorage = (data) => {
  const incState = store.store.getState().incident;

  let storage = {
    incEvidenceStorageDetails: {
      ...initFormModel,
      values: {
        ...data.values,
      },
      changes: true,
      ptsEvidenceId: incState.evidenceItemForm.ptsEvidenceId,
      tempEvidenceStorageId: data.tempEvidenceStorageId,
    },
  };
  incState.incEvidenceStorage.push(storage);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncEvidenceStorage = false;
};

export const setActiveOffensePropertyForm = () => {
  const incState = store.store.getState().incident;
  incState.offensesPropertyForm = incState.incProperties.find(
    (x) => x.incPropertyDetails.tempPropId === incState.selectedProperty
  ).incPropertyDetails;
};

export const setLinkedFormOffenseId = () => {
  const incState = store.store.getState().incident;
  incState.offensesOffendersForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesVictimForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesPropertyForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  // incState.offensesRelationshipForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesArresteesForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesDVVictimForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesDVSuspectForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesDVAdditionalForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;

  incState.offensesOffendersForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesVictimForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesPropertyForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  // incState.offensesRelationshipForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesRelationshipForm.ptsIncidentId = incState.incident.ptsIncidentId;
  incState.offensesArresteesForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesDVVictimForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesDVSuspectForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesDVAdditionalForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
};

export const initFormModel = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
  isDirty: false,
  changes: false,
};

export const resetAllForms = () => {
  const incState = store.store.getState().incident;
  incState.ptsIncidentId = 0;
  incState.addresses = [];
  incState.cadNotes = [];
  incState.parties = [];
  incState.properties = [];
  incState.offenses = [];
  incState.evidences = [];
  incState.dispositions = [];
  incState.zones = [];
  incState.incSubjects = [];
  incState.incVictims = [];
  incState.incArrestees = [];
  incState.incRelationships = [];
  incState.incProperties = [];
  incState.incRelations = [];
  incState.incPropertyOwners = [];
  incState.incEvidenceCustody = [];
  incState.incEvidenceStorage = [];
  incState.listSubjects = [];
  incState.listVictims = [];
  incState.listProperties = [];
  incState.listDialogSubjects = [];
  incState.listDialogVictims = [];
  incState.listDialogProperties = [];
  incState.listPropertyOwners = [];
  incState.listArrestees = [];
  incState.listDialogArrestees = [];
  incState.mpRelatedRecords = [];
  incState.selectedDuplicateData = null;
  incState.selectedSubject = '';
  incState.selectedVictim = '';
  incState.selectedProperty = '';
  incState.selectedPropertyOwner = '';
  incState.selectedArrestee = '';
  incState.selectedEvidence = '';
  incState.selectedNarrative = '';
  incState.currentOffense = '';
  incState.incident = initFormModel;
  incState.addressForm = initFormModel;
  incState.addressIdForm = initFormModel;
  incState.coordinatesForm = initFormModel;
  incState.partiesPersonForm = initFormModel;
  incState.partiesContactForm = initFormModel;
  incState.partiesEmploymentForm = initFormModel;
  incState.partiesOthersForm = initFormModel;
  incState.partiesPlaceForm = initFormModel;
  incState.propertyItemForm = initFormModel;
  incState.propertyFirearmForm = initFormModel;
  incState.propertyJewelryForm = initFormModel;
  incState.propertyStructureForm = initFormModel;
  incState.propertySubstanceForm = initFormModel;
  incState.propertyVehicleDetailsForm = initFormModel;
  incState.propertyCredentialsAnalysisForm = initFormModel;
  incState.offensesOffenseForm = initFormModel;
  incState.offensesOffendersForm = initFormModel;
  incState.offensesVictimForm = initFormModel;
  incState.offensesDVVictimForm = initFormModel;
  incState.offensesDVSuspectForm = initFormModel;
  incState.offensesDVAdditionalForm = initFormModel;
  incState.offensesMPBasicForm = initFormModel;
  incState.offensesMPLocationForm = initFormModel;
  incState.offensesMPAdd1Form = initFormModel;
  incState.offensesMPAdd2Form = initFormModel;
  incState.offensesMPAdd3Form = initFormModel;
  incState.offensesRelationshipForm = initFormModel;
  incState.offensesPropertyForm = initFormModel;
  incState.offensesArresteesForm = initFormModel;
  incState.evidenceItemForm = initFormModel;
  incState.evidenceCustodyForm = initFormModel;
  incState.evidenceStorageForm = initFormModel;
  incState.evidenceDispositionForm = initFormModel;
  incState.narrativeForm = initFormModel;
  incState.zoneForm = initFormModel;
  incState.dispositionForm = initFormModel;
  incState.tagForm = initFormModel;
  incState.update = false;
  incState.validationErrors = [];
  incState.nibrsErrors = [];
  incState.searchKeyword = {
    partiesKeyword: '',
    propertiesKeyword: '',
    offensesKeyword: '',
    evidencesKeyword: '',
    evidencesKeyword2: '',
    evidencesKeyword3: '',
  };
  incState.scrollPosition = 0;
  incState.cardExpanded = false;
};

export const resetSubForms = (flag) => {
  const incState = store.store.getState().incident;
  if (flag === 'add' && incState.selectedEntity) {
    incState.selectedEntity.ptsOffenseId = null;
    incState.selectedEntity.ptsAddressId = null;
    incState.selectedEntity.ptsEvidenceId = null;
    incState.selectedEntity.incIncidentPropertyId = null;
    incState.selectedEntity.ptsNarrativeId = null;
    incState.selectedEntity.ptsTagId = null;
  }
  incState.addressForm = initFormModel;
  incState.addressIdForm = initFormModel;
  incState.coordinatesForm = initFormModel;
  incState.partiesPersonForm = initFormModel;
  incState.partiesContactForm = initFormModel;
  incState.partiesEmploymentForm = initFormModel;
  incState.partiesOthersForm = initFormModel;
  incState.partiesPlaceForm = initFormModel;
  incState.propertyItemForm = initFormModel;
  incState.propertyFirearmForm = initFormModel;
  incState.propertyJewelryForm = initFormModel;
  incState.propertyStructureForm = initFormModel;
  incState.propertySubstanceForm = initFormModel;
  incState.propertyVehicleDetailsForm = initFormModel;
  incState.propertyCredentialsAnalysisForm = initFormModel;
  incState.offensesOffendersForm = initFormModel;
  incState.offensesVictimForm = initFormModel;
  incState.offensesDVVictimForm = initFormModel;
  incState.offensesDVSuspectForm = initFormModel;
  incState.offensesDVAdditionalForm = initFormModel;
  incState.offensesMPBasicForm = initFormModel;
  incState.offensesMPLocationForm = initFormModel;
  incState.offensesMPAdd1Form = initFormModel;
  incState.offensesMPAdd2Form = initFormModel;
  incState.offensesMPAdd3Form = initFormModel;
  incState.offensesRelationshipForm = initFormModel;
  incState.offensesPropertyForm = initFormModel;
  incState.offensesArresteesForm = initFormModel;
  incState.evidenceItemForm = initFormModel;
  incState.evidenceCustodyForm = initFormModel;
  incState.evidenceStorageForm = initFormModel;
  incState.evidenceDispositionForm = initFormModel;
  incState.narrativeForm = initFormModel;
  incState.zoneForm = initFormModel;
  incState.dispositionForm = initFormModel;
  incState.tagForm = initFormModel;
  incState.mpRelatedRecords = [];
};

export const resetOffenseLists = (entity) => {
  const incState = store.store.getState().incident;

  if (entity !== 'parties') {
    // incState.incVictims = [];
    // incState.incSubjects = [];
    incState.incArresees = [];
  }

  // if (entity !== 'properties') {
  //   incState.incProperties = [];
  // }

  incState.currentOffense = '';
  // incState.incRelationships = [];
  // incState.incArrestees = [];
  incState.listSubjects = [];
  incState.listVictims = [];
  incState.listProperties = [];
  incState.listArrestees = [];
  incState.listDialogSubjects = [];
  incState.listDialogVictims = [];
  incState.listDialogProperties = [];
  incState.listDialogArrestees = [];
  incState.dvAdditionals = [];
  incState.listSuspects = [];
  incState.listDialogSuspects = [];
  incState.selectedDuplicateData = null;
  incState.selectedSubject = '';
  incState.selectedVictim = '';
  incState.selectedProperty = '';
  incState.selectedArrestee = '';
  incState.selectedSuspect = '';
};
/*endregion*/
export default function reducer(
  state = {
    nibrsDataLoaded: false,
    formType: 'add',
    currentIncidentAction: '',
    isFormDirty: false,
    turnOfValidation: true,
    hasHiddenData: false,
    autoGeneratedCustody: {
      IsStatusCheckInButtonClicked: false,
      IsStorageUpdateButtonClicked: false,
    },
    activeIncidents: [],
    currentOffense: '',
    requiredError: false,
    ptsIncidentId: 0,
    actionReport: [],
    addresses: [],
    cadNotes: [],
    parties: [],
    properties: [],
    offenses: [],
    evidences: [],
    narratives: [],
    dispositions: [],
    alerts: [],
    notifications: [],
    tags: [],
    zones: [],
    incSubjects: [],
    incVictims: [],
    incRelationships: [],
    incRelations: [],
    incProperties: [],
    incPropertyOwners: [],
    incArrestees: [],
    incEvidenceCustody: [],
    incEvidenceStorage: [],
    dvVictims: [],
    dvSuspects: [],
    listSuspects: [],
    dvAdditionals: [],
    listDialogSuspects: [],
    listSubjects: [],
    listDialogSubjects: [],
    listVictims: [],
    listDialogArrestees: [],
    listDialogVictims: [],
    listProperties: [],
    listDialogProperties: [],
    listPropertyOwners: [],
    listDialogEvidences: [],
    listArrestees: [],
    mpRelatedRecords: {},
    selectedDuplicateData: null,
    selectedSubject: '',
    selectedVictim: '',
    selectedProperty: '',
    selectedPropertyOwner: '',
    selectedArrestee: '',
    selectedSuspect: '',
    selectedEvidenceCustody: '',
    selectedEvidenceStorage: '',
    selectedIncidentEvidence: '',
    selectedNarrative: '',
    selectedActionReport: {},
    incident: initFormModel,
    addressForm: initFormModel,
    addressIdForm: initFormModel,
    coordinatesForm: initFormModel,
    partiesPersonForm: initFormModel,
    partiesContactForm: initFormModel,
    partiesEmploymentForm: initFormModel,
    partiesOthersForm: initFormModel,
    partiesPlaceForm: initFormModel,
    propertyItemForm: initFormModel,
    propertyFirearmForm: initFormModel,
    propertyJewelryForm: initFormModel,
    propertyStructureForm: initFormModel,
    propertySubstanceForm: initFormModel,
    propertyVehicleDetailsForm: initFormModel,
    propertyCredentialsAnalysisForm: initFormModel,
    offensesOffenseForm: initFormModel,
    offensesOffendersForm: initFormModel,
    offensesVictimForm: initFormModel,
    offensesDVVictimForm: initFormModel,
    offensesDVSuspectForm: initFormModel,
    offensesDVAdditionalForm: initFormModel,
    offensesMPBasicForm: initFormModel,
    offensesMPLocationForm: initFormModel,
    offensesMPAdd1Form: initFormModel,
    offensesMPAdd2Form: initFormModel,
    offensesMPAdd3Form: initFormModel,
    offensesRelationshipForm: initFormModel,
    offensesPropertyForm: initFormModel,
    offensesArresteesForm: initFormModel,
    afterActionDetailsForm: initFormModel,
    afterActionReviewForm: initFormModel,
    afterActionPartyForm: initFormModel,
    evidenceItemForm: initFormModel,
    evidenceCustodyForm: initFormModel,
    evidenceCustodyFormNew: initFormModel,
    evidenceStorageForm: initFormModel,
    evidenceStorageFormNew: initFormModel,
    evidenceDispositionForm: initFormModel,
    narrativeForm: initFormModel,
    zoneForm: initFormModel,
    dispositionForm: initFormModel,
    tagForm: initFormModel,
    update: false,
    selectedEntity: null,
    searchKeyword: {
      partiesKeyword: '',
      propertiesKeyword: '',
      offensesKeyword: '',
      evidencesKeyword: '',
      evidencesKeyword2: '',
      evidencesKeyword3: '',
      tagsKeyword: '',
    },
    offenseValidationErrors: [],
    validationErrors: [],
    nibrsErrors: [],
    scrollPosition: 0,
    cardExpanded: false,
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_NIBRS_ERRORS:
      return { ...state, nibrsErrors: action.payload };
    case SET_VALIDATION_ERRORS:
      return { ...state, validationErrors: action.payload };
    case SET_OFFENSE_VALIDATION_ERRORS:
      return { ...state, offenseValidationErrors: action.payload };
    case SET_INCIDENT:
      return { ...state, incident: action.payload };
    case RESET_INCIDENT:
      return { ...state, incident: action.payload };
    case SET_INC_ADDRESS:
      return { ...state, addressForm: action.payload };
    case SET_INC_ADDRESS_ID:
      return { ...state, addressIdForm: action.payload };
    case SET_INC_COORDINATES:
      return { ...state, coordinatesForm: action.payload };
    case SET_INC_PARTIES_PERSON:
      return { ...state, partiesPersonForm: action.payload };
    case SET_INC_PARTIES_CONTACT:
      return { ...state, partiesContactForm: action.payload };
    case SET_INC_PARTIES_EMPLOYMENT:
      return { ...state, partiesEmploymentForm: action.payload };
    case SET_INC_PARTIES_OTHERS:
      return { ...state, partiesOthersForm: action.payload };
    case SET_INC_PARTIES_PLACE:
      return { ...state, partiesPlaceForm: action.payload };
    case SET_INC_PROPERTY_ITEM:
      return { ...state, propertyItemForm: action.payload };
    case SET_INC_PROPERTY_FIREARM:
      return { ...state, propertyFirearmForm: action.payload };
    case SET_INC_PROPERTY_JEWELRY:
      return { ...state, propertyJewelryForm: action.payload };
    case SET_INC_PROPERTY_STRUCTURE:
      return { ...state, propertyStructureForm: action.payload };
    case SET_INC_PROPERTY_SUBSTANCE:
      return { ...state, propertySubstanceForm: action.payload };
    case SET_INC_PROPERTY_VEHICLE_DETAILS:
      return { ...state, propertyVehicleDetailsForm: action.payload };
    case SET_INC_PROPERTY_CREDENTIALS_ANALYSIS:
      return { ...state, propertyCredentialsAnalysisForm: action.payload };
    case SET_INC_OFFENSES_OFFENSE:
      return { ...state, offensesOffenseForm: action.payload };
    case SET_INC_OFFENSES_DV_VICTIM:
      return {
        ...state,
        offensesDVVictimForm: { ...state.offensesDVVictimForm, ...action.payload },
      };
    case SET_INC_DV_VICTIM_LIST:
      return { ...state, dvVictims: action.payload };
    case SET_INC_DV_SUSPECT_LIST:
      return { ...state, dvSuspects: action.payload };
    case SET_INC_DV_ADDITIONAL_LIST:
      return { ...state, dvAdditionals: action.payload };
    case SET_INC_OFFENSES_DV_VICTIM_SUBFORMS:
      return {
        ...state,
        offensesDVVictimForm: { ...state.offensesDVVictimForm, ...action.payload },
      };
    case SET_INC_OFFENSES_DV_SUSPECT:
      return {
        ...state,
        offensesDVSuspectForm: { ...state.offensesDVSuspectForm, ...action.payload },
      };
    case SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS:
      return {
        ...state,
        offensesDVSuspectForm: { ...state.offensesDVSuspectForm, ...action.payload },
      };
    case SET_INC_OFFENSES_DV_ADDITIONAL:
      return {
        ...state,
        offensesDVAdditionalForm: { ...state.offensesDVAdditionalForm, ...action.payload },
      };
    case SET_INC_OFFENSES_MP_BASIC:
      return { ...state, offensesMPBasicForm: action.payload };
    case SET_INC_MP_RELATED_RECORDS:
      return { ...state, mpRelatedRecords: action.payload };
    case SET_INC_OFFENSES_RELATIONSHIP:
      return { ...state, offensesRelationshipForm: action.payload };
    case SET_INC_OFFENSES_PROPERTY_OWNER:
      return {
        ...state,
        offensesPropertyForm: { ...state.offensesPropertyForm, ...action.payload },
      };
    case SET_INC_AFTER_ACTION_DETAILS_FORM:
      return { ...state, afterActionDetailsForm: action.payload };
    case SET_INC_AFTER_ACTION_REVIEW_FORM:
      return { ...state, afterActionReviewForm: action.payload };
    case SET_INC_AFTER_ACTION_PARTY_FORM:
      return { ...state, afterActionPartyForm: action.payload };
    case SET_INC_EVIDENCE_ITEM:
      return { ...state, evidenceItemForm: action.payload };
    case SET_INC_EVIDENCE_CUSTODY:
      return { ...state, evidenceCustodyForm: action.payload };
    case SET_INC_EVIDENCE_CUSTODY_NEW:
      return { ...state, evidenceCustodyFormNew: action.payload };
    case SET_INC_EVIDENCE_STORAGE:
      return { ...state, evidenceStorageForm: action.payload };
    case SET_INC_EVIDENCE_STORAGE_NEW:
      return { ...state, evidenceStorageFormNew: action.payload };
    case SET_INC_EVIDENCE_DISPOSITION:
      return { ...state, evidenceDispositionForm: action.payload };
    case SET_INC_NARRATIVE:
      return { ...state, narrativeForm: action.payload };
    case ADD_ADDRESSES:
      return { ...state, addresses: action.payload };
    case ADD_PARTIES:
      return { ...state, parties: action.payload };
    case ADD_OFFENSES:
      return { ...state, offenses: action.payload };
    case ADD_PROPERTIES:
      return { ...state, properties: action.payload };
    case SET_LIST_SUBJECTS:
      return { ...state, listSubjects: action.payload };
    case SET_DIALOG_LIST_SUBJECTS:
      return { ...state, listDialogSubjects: action.payload };
    case SET_LIST_VICTIMS:
      return { ...state, listVictims: action.payload };
    case SET_DIALOG_LIST_VICTIMS:
      return { ...state, listDialogVictims: action.payload };
    case SET_LIST_SUSPECTS:
      return { ...state, listSuspects: action.payload };
    case SET_DIALOG_LIST_SUSPECTS:
      return { ...state, listDialogSuspects: action.payload };
    case SET_LIST_PROPERTIES:
      return { ...state, listProperties: action.payload };
    case SET_DIALOG_LIST_PROPERTIES:
      return { ...state, listDialogProperties: action.payload };
    case SET_DIALOG_LIST_ARRESTEES:
      return { ...state, listDialogArrestees: action.payload };
    case SET_LIST_PROP_OWNERS:
      return { ...state, listPropertyOwners: action.payload };
    case SET_LIST_ARRESTEES:
      return { ...state, listArrestees: action.payload };
    case SET_DIALOG_LIST_EVIDENCES:
      return { ...state, listDialogEvidences: action.payload };
    case SET_LIST_EVIDENCE_STORAGE:
      return { ...state, incEvidenceStorage: action.payload };
    case SET_LIST_EVIDENCE_CUSTODY:
      return { ...state, incEvidenceCustody: action.payload };
    case SET_SELECTED_DUPLICATE_DATA:
      return { ...state, selectedDuplicateData: action.payload };
    case SET_SELECTED_SUBJECT:
      return { ...state, selectedSubject: action.payload };
    case SET_SELECTED_VICTIM:
      return { ...state, selectedVictim: action.payload };
    case SET_SELECTED_SUSPECT:
      return { ...state, selectedSuspect: action.payload };
    case SET_SELECTED_PROPERTY:
      return { ...state, selectedProperty: action.payload };
    case SET_SELECTED_EVIDENCE_CUSTODY:
      return { ...state, selectedEvidenceCustody: action.payload };
    case SET_SELECTED_EVIDENCE_STORAGE:
      return { ...state, selectedEvidenceStorage: action.payload };
    case SET_SELECTED_PROP_OWNER:
      return { ...state, selectedPropertyOwner: action.payload };
    case SET_SELECTED_ARRESTEE:
      return { ...state, selectedArrestee: action.payload };
    case SET_SELECTED_INCIDENT_EVIDENCE:
      return { ...state, selectedIncidentEvidence: action.payload };
    case SET_SELECTED_NARRATIVE:
      return { ...state, selectedNarrative: action.payload };
    case SET_SELECTED_ACTION_REPORT:
      return { ...state, selectedActionReport: action.payload };
    case GET_ACTIVE_INCIDENTS:
      return { ...state, activeIncidents: action.payload };
    case GET_PARTIES:
      return { ...state, parties: action.payload };
    case GET_AFTER_ACTION_REPORT:
      return { ...state, actionReport: action.payload };
    case GET_ADDRESSESS:
      return { ...state, addresses: action.payload };
    case GET_CAD_NOTES:
      return { ...state, cadNotes: action.payload };
    case GET_OFFENSES:
      return { ...state, offenses: action.payload };
    case GET_PROPERTIES:
      return { ...state, properties: action.payload };
    case GET_EVIDENCES:
      return { ...state, evidences: action.payload };
    case GET_NARRATIVES:
      return { ...state, narratives: action.payload };
    case SET_SELECTED_INCIDENT:
      return { ...state, ptsIncidentId: action.payload };
    case SET_SELECTED_ENTITY:
      return { ...state, selectedEntity: action.payload };
    case SET_INC_SUBJECTS:
      return { ...state, incSubjects: action.payload };
    case SET_INC_VICTIMS:
      return { ...state, incVictims: action.payload };
    case SET_INC_SUSPECTS:
      return { ...state, dvSuspects: action.payload };
    case SET_INC_PROPERTIES:
      return { ...state, incProperties: action.payload };
    case SET_INC_ARRESTEES:
      return { ...state, incArrestees: action.payload };
    case SET_INC_RELATIONSHIPS:
      return { ...state, incRelationships: action.payload };
    case SET_INC_RELATIONS_DATA:
      return { ...state, incRelations: action.payload };
    case SET_CURRENT_OFFENSE:
      return { ...state, currentOffense: action.payload };
    case SET_SEARCH_KEYWORDS:
      return { ...state, searchKeyword: action.payload };
    case GET_DISPOSITIONS:
      return { ...state, dispositions: action.payload };
    case GET_ZONES:
      return { ...state, zones: action.payload };
    case GET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case GET_ALERTS:
      return { ...state, alerts: action.payload };
    case SET_INC_ZONE_FORM:
      return { ...state, zoneForm: action.payload };
    case SET_INC_DISPOSITION_FORM:
      return { ...state, dispositionForm: action.payload };
    case SET_INC_TAG_FORM:
      return { ...state, tagForm: action.payload };
    case SET_EVENT:
      return { ...state, event: action.payload };
    case GET_TAGS:
      return { ...state, tags: action.payload };
    case SET_IS_FORM_DIRTY:
      return { ...state, isFormDirty: action.payload };
    case SET_TURN_OFF_VALIDATION:
      return { ...state, turnOfValidation: action.payload };
    case SET_HIDDEN_DATA:
      return { ...state, hasHiddenData: action.payload };
    case SET_CUSTODY_AUTO_GENERATED_DATA:
      return { ...state, autoGeneratedCustody: action.payload };
    case SET_INCIDENT_CURRENT_ACTION:
      return { ...state, currentIncidentAction: action.payload };
    case SET_FORM_TYPE:
      return { ...state, formType: action.payload };
    case SET_INC_SCROLL_POSITION:
      return { ...state, scrollPosition: action.payload };
    case SET_CARD_EXPANDED:
      return { ...state, cardExpanded: action.payload };
    case SET_NIBRS_DATA_LOADED:
      return { ...state, nibrsDataLoaded: action.payload };
  }
  return state;
}
