import { makeStyles } from '@material-ui/core';

import { Checkbox, Fab, FormControlLabel, IconButton, Divider, Tooltip, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import { useSelector } from 'react-redux';
import CitationReportFilter from './CitationReportFilter';
import { processParameters } from './reportDataProcessingHelper';
import WarrantReportFilter from './WarrantReportFilter';
import IncidentByLocationFilters from './IncidentByLocationReportFilters';
import IncidentSeverityByZoneReport from './IncidentSeverityByZoneReportFilter';
import OffenceChargesBystateReportFilters from './OffenceChargesBystateReportFilters';
import OfficerActivityReportFilter from './OfficerActivityReport';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import ReportsDialog from 'components/RMSModals/ReportsDialog';
const useStyles = makeStyles((theme) => ({
  container: {
    height: '65vh',
    overflow: 'auto',
  },
  reportContainer: {
    display: 'flex',
  },
  reportSelection: {
    flex: 0.2,
  },
  reportFilter: {
    flex: 0.7,
  },
  report: {
    paddingBottom: '4px',
  },
  printSection: {
    flex: 0.1,
    textAlign: 'center',
    width: '100%',
  },
  fieldsetWrapper: {
    padding: '25px 10px 10px',
    width: '92%',
    height: '50vh',
  },

  fieldsetWrapperFilter: {
    padding: '15px 13px 5px',
    marginTop: '6px',
    height: '50vh',
    width: '100%',
  },
  fieldsetWrapperBorder: {
    border: '2px solid',
    borderColor: theme.palette.colors.borderColor,
    borderRadius: '10px',
  },
  fieldsetTitleFilter: {
    position: 'absolute',
    marginTop: '-25px',
    padding: '2px 10px',
  },
  fieldsetTitleBackground: {
    color: 'white',
    background: theme.palette.colors.chipBg,
    borderRadius: '10px',
  },
}));

function TopbarReportSection(props) {
  const classes = useStyles();
  const [checkedReportList, setCheckedReportList] = useState({});
  const [reportForm, setReportForm] = useState({});
  const [filteredList, setFileredList] = useState([]);
  const reportList = useSelector((state) => state.reports.allReportList);
  const userAgency = useSelector((state) => state.user.userAgency);
  const reportingOfficer = useSelector((state) => state.user?.userData?.user?.FullName);
  const [showPrint, setShowPrint] = useState(false);
  const [currentReportData, setCurrentReportData] = useState(null);
  useEffect(() => {
    const newList = reportList.filter((item) => checkedReportList[item.ReportID]);
    setFileredList(newList);
  }, [reportList, checkedReportList]);
  const handleReportSelection = (item) => {
    const newFormData = { ...reportForm };
    if (checkedReportList[item.ReportID]) {
      const newList = { ...checkedReportList };
      delete newList[item.ReportID];
      setCheckedReportList(newList);

      delete newFormData[item.ReportID];
      setReportForm({ ...newFormData });
    } else {
      setCheckedReportList({
        ...checkedReportList,
        [item.ReportID]: true,
      });

      newFormData[item.ReportID] = {};
      setReportForm({ ...newFormData });
    }
  };

  const handleReportFormChange = (ReportID, filedName, value) => {
    if (
      filedName === 'citationDateFrom' ||
      filedName === 'issuedDateFrom' ||
      filedName === 'receivedFromBeginning'
    ) {
      value.setHours(0, 0, 0, 0);
    }
    if (
      filedName === 'citationDateTo' ||
      filedName === 'issuedDateTo' ||
      filedName === 'receivedFromEnding'
    ) {
      value.setHours(23, 59, 59, 0);
    }
    let newForm = { ...reportForm };
    if (newForm[ReportID]) {
      newForm = {
        ...newForm,
        [ReportID]: {
          ...newForm[ReportID],
          [filedName]: value,
        },
      };
    } else {
      newForm[ReportID] = {
        [filedName]: value,
      };
    }
    setReportForm({ ...newForm });
  };

  const hanldePrintButtonClick = (arr = []) => {
    if (arr.length > 0) {
      const id = arr[0];

      const processedData = processParameters(id, reportForm[id]);
      const data = { ...processedData, ReportingOfficer: reportingOfficer, AgencyId: userAgency };
      if (data) {
        setCurrentReportData({ id, data, reportList: arr });
        setShowPrint(true);
      }
    }
  };

  const onPrintDialogClose = (id, arr) => {
    const newArr = arr.filter((item) => item !== id);
    setShowPrint(false);
    if (newArr.length > 0) hanldePrintButtonClick(newArr);
    else {
      props.setOpenReportSearchMenu(false);
    }
  };
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={4} md={3}>
        <div
          className={clsx(
            classes.fieldsetWrapperBorder,
            classes.fieldsetWrapperFilter,
            classes.container
          )}>
          <div className={clsx(classes.fieldsetTitleFilter, classes.fieldsetTitleBackground)}>
            Select Reports
          </div>
          {reportList.map((item) => (
            <FormControlLabel
              style={{ display: 'block', paddingBottom: '4px' }}
              control={
                <Checkbox
                  key={item.ReportID}
                  checked={checkedReportList[item.ReportID] || false}
                  onChange={() => handleReportSelection(item)}
                  name={item.ReportID}
                />
              }
              label={item.ReportTitle}
            />
          ))}
        </div>
      </Grid>
      <Grid item xs={7} md={8}>
        <div
          className={clsx(
            classes.fieldsetWrapperBorder,
            classes.fieldsetWrapperFilter,
            classes.container
          )}>
          <div className={clsx(classes.fieldsetTitleFilter, classes.fieldsetTitleBackground)}>
            Filters
          </div>
          {filteredList.map((item) => {
            switch (item.ReportID) {
              case '274':
                return (
                  <CitationReportFilter
                    reportForm={reportForm}
                    handleReportFormChange={handleReportFormChange}
                    ReportID={item.ReportID}
                    title={item.ReportTitle}
                  />
                );
              case '275':
                return (
                  <WarrantReportFilter
                    reportForm={reportForm}
                    handleReportFormChange={handleReportFormChange}
                    ReportID={item.ReportID}
                    title={item.ReportTitle}
                  />
                );
              //grouped multiple case together
              case '276':
              case '277':
                return (
                  <IncidentByLocationFilters
                    reportForm={reportForm}
                    handleReportFormChange={handleReportFormChange}
                    ReportID={item.ReportID}
                    title={item.ReportTitle}
                  />
                );

              case '278':
                return (
                  <IncidentSeverityByZoneReport
                    reportForm={reportForm}
                    handleReportFormChange={handleReportFormChange}
                    ReportID={item.ReportID}
                    title={item.ReportTitle}
                  />
                );
              case '279':
              case '280':
                return (
                  <OffenceChargesBystateReportFilters
                    reportForm={reportForm}
                    handleReportFormChange={handleReportFormChange}
                    ReportID={item.ReportID}
                    title={item.ReportTitle}
                  />
                );

              case '281':
              case '282':
                return (
                  <OfficerActivityReportFilter
                    reportForm={reportForm}
                    handleReportFormChange={handleReportFormChange}
                    ReportID={item.ReportID}
                    title={item.ReportTitle}
                  />
                );

              default:
                return <></>;
            }
          })}
        </div>
      </Grid>

      <Grid item xs={1}>
        <div style={{ position: 'absolute', right: 0, display: 'flex', gap: '10px' }}>
          <Tooltip title="print">
            <Fab
              disabled={Object.keys(reportForm).length < 1}
              size="small"
              color="primary"
              onClick={() => {
                const ids = Object.keys(reportForm);
                console.log(reportForm);
                console.log(ids);
                hanldePrintButtonClick(ids);
              }}>
              <PrintIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="close">
            <Fab
              size="small"
              title="Close"
              color="primary"
              onClick={() => props.setOpenReportSearchMenu(false)}>
              <CloseIcon />
            </Fab>
          </Tooltip>
        </div>
      </Grid>
      {showPrint && (
        <ReportsDialog open={showPrint} onClose={onPrintDialogClose} report={currentReportData} />
      )}
    </Grid>
  );
}

export default TopbarReportSection;
