import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';

import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';
import { useMemo } from 'react';

function RMSAutoComplete({ id, ...props }) {
  let {
    label,
    options,
    variant,
    setField,
    title,
    stateHolder,
    stateHolderAttribute,
    serviceName,
    setStateHolder,
    error,
    value,
    helperText,
    showComboBox,
    showComboBoxListItem,
    className,
    formType,
    hasDifferentPrimaryKey = false,
    parentCode,
    disabled,
    freeSolo = false,
    changedFrom = '',
    setIsChanged,
  } = props;

  if (!setStateHolder) {
    setStateHolder = handleFormChangeNew;
  }

  const dictionaryPermission = useSelector((state) => state.permissions.dictionary);
  const codesArray = useSelector((state) => state.dictionary[serviceName]);
  const codeField = useMemo(() => {
    if (
      [
        'FBICode',
        'ZoneCode',
        'ModelCode',
        'CityDescription',
        'Level',
        'Court_Name',
        'SQLSysID',
      ].includes(title)
    )
      return title;
    return 'Code';
  }, [title]);

  const descriptionField = useMemo(() => {
    if (title === 'ModelCode') return 'ModelDescription';
    if (title === 'CityDescription') return 'CityDescription';
    if (title === 'Court_Name') return 'Court_Name';
    if (title === 'Code') return 'Code';
    return 'Description';
  }, [title]);

  const [populateDefaultValue, setPopulateDefaultValue] = useState(false);

  const [permission, setPermission] = useState({
    Create: false,
    Read: false,
    Edit: false,
    Delete: false,
    ViewDeleted: false,
  });

  const getPermissionForThisCode = () => {
    const value =
      serviceName === 'offenseArray' || serviceName === 'propertyOwnerArray'
        ? { ...permission, Read: true }
        : dictionaryPermission[serviceName];

    if (value) setPermission(value);
  };

  const renderOption = (option) => {
    return serviceName === 'codeCities' ? (
      <span> {option.CityDescription}</span>
    ) : serviceName === 'ptsFBICode' ? (
      <span>
        <strong>{option.FBICode}</strong>
        {!!option.Description && <> - {option.Description}</>}
      </span>
    ) : serviceName === 'codeZones' ? (
      <span>
        <strong>{option.ZoneCode}</strong>
        {!!option.Description && <> - {option.Description}</>}
      </span>
    ) : serviceName === 'codeVehicleMakesModels' ? (
      <span>
        <strong>{option.ModelCode}</strong>
        {!!option.ModelDescription && <> - {option.ModelDescription}</>}
      </span>
    ) : serviceName === 'Citation_Court' ? (
      <span>{option.Court_Name}</span>
    ) : serviceName === 'secClearanceLevel' ? (
      <span>
        <strong>{option.Level}</strong>
        {!!option.Description && <> - {option.Description}</>}
      </span>
    ) : serviceName === 'propertyOwnerArray' ? (
      <span> {option.FullName}</span>
    ) : (
      <span>
        <strong>{option.Code}</strong>
        {!!option.Description && <> - {option.Description}</>}
      </span>
    );
  };

  const getOptions = (options) => {
    if (options.length === 0) return options;
    if (serviceName === 'codeCities') return [{ CityDescription: '' }, ...options];
    else if (serviceName === 'ptsFBICode') return [{ FBICode: '', Description: '' }, ...options];
    else if (serviceName === 'codeZones') return [{ ZoneCode: '', Description: '' }, ...options];
    else if (serviceName === 'codeVehicleMakesModels')
      return [{ ModelCode: '', ModelDescription: '' }, ...options];
    else if (serviceName === 'Citation_Court') return [{ Court_Name: '' }, ...options];
    else if (serviceName === 'propertyOwnerArray') return [{ id: '', FullName: '' }, ...options];
    else if (serviceName === 'codePartyRelationship')
      return [{ Code: '', Description: '' }, ...options].sort((a, b) => (a.Code > b.Code ? 1 : -1));
    else return [{ Code: '', Description: '' }, ...options];
  };

  const getCodesArray = () => (serviceName === 'codeTagTypes' ? codesArray : options);

  const describe = (value) =>
    serviceName === 'offenseArray'
      ? getCodesArray().find((el) => el.Description === value)?.Description ?? ''
      : serviceName === 'propertyOwnerArray'
      ? getCodesArray().find((el) => el.id == value)?.FullName ?? ''
      : serviceName === 'secClearanceLevel'
      ? getCodesArray().find((el) => el[codeField] == value)?.[descriptionField] ?? ''
      : getCodesArray().find((el) => el[codeField] === value)?.[descriptionField] ?? '';

  const getValue = (value) => {
    const description = describe(value);
    if (!populateDefaultValue) {
      return !description && freeSolo ? value : description;
    }
    // when there is no value saved for that field in db,
    // we should select the option for which IsDefault = 1:
    const defaultEl = options.find((el) => el.IsDefault);

    // no IsDefault = 1 exist in options; so keep the current value as it is:
    if (!defaultEl) return description;
    serviceName === 'codeWarrantDispositions'
      ? setField(null, defaultEl, 'none', stateHolderAttribute, setStateHolder)
      : setField(
          null,
          defaultEl[codeField] ?? '',
          'setDefaultValue',
          stateHolderAttribute,
          setStateHolder
        );
    setPopulateDefaultValue(false);
    return defaultEl[descriptionField];
  };

  useEffect(() => {
    if (serviceName) {
      getPermissionForThisCode();
    }
  }, [serviceName]);

  useEffect(() => {
    setPopulateDefaultValue(formType === 'add' && serviceName !== 'codeCustodyOptions');
  }, [formType]);

  const filterOptions = createFilterOptions({
    trim: true,
    stringify: (option) => {
      if (serviceName === 'codeCities') return option.CityDescription;
      else if (serviceName === 'ptsFBICode')
        return option.Description ? option.FBICode + option.Description : option.FBICode;
      else if (serviceName === 'codeZones')
        return option.Description ? option.ZoneCode + option.Description : option.ZoneCode;
      else if (serviceName === 'codeVehicleMakesModels')
        return option.ModelDescription
          ? option.ModelCode + option.ModelDescription
          : option.ModelCode;
      else if (serviceName === 'Citation_Court') return option.Court_Name;
      else if (serviceName === 'propertyOwnerArray') return option.FullName;
      else return option.Description ? option.Code + option.Description : option.Code;
    },
  });

  return (
    <Autocomplete
      size="small"
      {...props}
      autoSelect={false}
      autoHighlight={true}
      options={permission.Read ? getOptions(options) : []}
      className={className || ''}
      key={stateHolder?.values?.category || ''}
      value={
        {
          [title]: getValue(value || stateHolder?.values[stateHolderAttribute]),
        } ?? ''
      }
      getOptionSelected={(option, value) => option[descriptionField] === value[title]}
      getOptionLabel={(option) => option.Description ?? option[title] ?? ''}
      onChange={(event, newValue) => {
        if (changedFrom === 'incident_narrative_form') {
          setIsChanged(true);
        }

        if (freeSolo && serviceName === 'codeCustodyOptions' && typeof newValue === 'string') {
          setField(event, newValue ?? '', 'none', stateHolderAttribute, setStateHolder);
          return;
        }
        serviceName === 'offenseArray'
          ? setField(
              event,
              newValue?.Description ?? '',
              'none',
              stateHolderAttribute,
              setStateHolder
            )
          : serviceName === 'propertyOwnerArray'
          ? setField(event, newValue?.id ?? '', 'none', stateHolderAttribute, setStateHolder)
          : serviceName === 'codeWarrantDispositions'
          ? setField(event, newValue, 'none', stateHolderAttribute, setStateHolder)
          : setField(
              event,
              newValue?.[codeField] ?? '',
              'none',
              stateHolderAttribute,
              setStateHolder
            );
      }}
      freeSolo={freeSolo}
      selectOnFocus
      filterOptions={filterOptions}
      renderOption={(option) =>
        showComboBoxListItem === false ? (
          renderOption(option)
        ) : (
          <RMSComboBoxListItem
            option={option}
            title={title}
            serviceName={serviceName}
            label={label}
            permission={permission}
            resetField={() => setField(null, null, 'none', stateHolderAttribute, setStateHolder)}
            hasDifferentPrimaryKey={hasDifferentPrimaryKey}
            renderOption={renderOption(option)}
          />
        )
      }
      renderInput={(params) =>
        showComboBox === false ? (
          <TextField
            autoComplete="off"
            size="small"
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            label={label}
            variant="outlined"
            fullWidth
          />
        ) : (
          <RMSComboBox
            error={error}
            helperText={helperText}
            {...params}
            serviceName={serviceName}
            label={label}
            parentCode={parentCode}
            disabled={disabled}
            FormHelperTextProps={{
              style: { color: 'red' },
            }}
            setField={(newVal) => {
              setField(null, newVal[title], 'none', stateHolderAttribute, setStateHolder);
            }}
            variant={variant || 'outlined'}
            fullWidth
            permission={permission}
            hasDifferentPrimaryKey={hasDifferentPrimaryKey}
          />
        )
      }
    />
  );
}

export default RMSAutoComplete;
